import PropTypes from 'prop-types';
import React from 'react';
import { DateTime, Interval } from 'luxon';
import { Typography } from '@mui/material';

const FormattedDuration = ({ startDateTime, endDateTime, typographyProps }) => {
    if (!startDateTime || !endDateTime) {
        return null;
    }

    const start = DateTime.fromISO(startDateTime);
    const end = DateTime.fromISO(endDateTime);
    const interval = Interval.fromDateTimes(start, end);
    const duration = interval.toDuration();

    const formattedInterval = duration.as('days') > 2
        ? duration.shiftTo('days').toHuman()
        : duration.shiftTo('hours', 'minutes').toHuman({ compactDisplay: 'short', unitDisplay: 'narrow' });

    return (
        <Typography {...typographyProps}>{ formattedInterval }</Typography>
    );
};

FormattedDuration.propTypes = {
    endDateTime: PropTypes.string,
    startDateTime: PropTypes.string,
    typographyProps: PropTypes.any
};

export default FormattedDuration;

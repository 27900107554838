import PropTypes from 'prop-types';
import React from 'react';
import { LoadingPlaceholderFullPage } from 'components/shared/LoadingPlaceholderFullPage';
import useParticipantsForActivity from 'api-new/useParticipantsForActivity';
import usePollAnswersForActivity from 'api-new/stim-based/usePollAnswersForActivity';
import { PollActivityEngagementIndicator as RCPollActivityEngagementIndicator } from 'vl-reporting-components';
import _ from 'lodash';
import ProjectParticipantRole from 'enums/ProjectParticipantRole';

const PollActivityEngagementIndicator = ({activityId, focusGroupId}) => {
    const { data: pollAnswers, isLoading: isLoadingPollAnswers } = usePollAnswersForActivity(activityId);
    const { data: participants, isLoading: isLoadingParticipants } = useParticipantsForActivity(focusGroupId);

    if (isLoadingPollAnswers || isLoadingParticipants) {
        return <LoadingPlaceholderFullPage />;
    }

    const respondents = participants?.filter(x => x.projectParticipantRole === ProjectParticipantRole.RESPONDENT);
    const respondentsAsObject = _.keyBy(respondents, 'id');

    return (
        <RCPollActivityEngagementIndicator
            answers={pollAnswers}
            participants={respondentsAsObject}
            sx={{
                height: 35
            }}
        />
    );
};

PollActivityEngagementIndicator.propTypes = {
    activityId: PropTypes.string,
    focusGroupId: PropTypes.string
};

export default PollActivityEngagementIndicator;

import PropTypes from 'prop-types';
import { Avatar, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

const ProjectSearchResult = ({ 
    projectId, 
    companyId, 
    companyName, 
    projectName
}) => {
    const theme = useTheme();

    return (
        <div className="VL-SearchResult" style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar style={{ ...theme.activityAvatar.neutral }}>
                <FolderOpenOutlinedIcon />
            </Avatar>
            <div style={{ marginLeft: 24 }}>
                <Link
                    to={`/company/${companyId}/project/${projectId}`}
                    component={RouterLink}
                    variant="subtitle1"
                    underline="hover"
                    style={{ ...theme.typography.primaryItemLink }}
                >
                    { projectName }
                </Link>

                <MuiBreadcrumbs separator="›">
                    <Link
                        to={`/company/${companyId}`}
                        component={RouterLink}
                        variant="subtitle2"
                        color="textSecondary"
                        underline="hover">
                        { companyName }
                    </Link>
                    <Link
                        to={`/company/${companyId}/project/${projectId}`}
                        component={RouterLink}
                        variant="subtitle2"
                        color="textSecondary"
                        underline="hover">
                        { projectName }
                    </Link>
                </MuiBreadcrumbs>
            </div>
        </div>
    );
};

ProjectSearchResult.propTypes = {
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    projectId: PropTypes.string,
    projectName: PropTypes.string
};

export default ProjectSearchResult;

import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import useCurrentUserDetails from '../../api/useCurrentUserDetails';
import BasicSnackbar from '../shared/BasicSnackbar';
import { apiUrl } from '../../Config';

const UpdateEmailAddress = (props) => {
    const { t } = useTranslation();
    const { refetch } = useCurrentUserDetails();
    const [notification, setNotification] = useState(null);

    const handleFormSubmission = (formData, form) => {
        fetch(`${apiUrl}/api/currentuser/email`, {
            credentials: 'include',
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: props.initialValues.email,
                password: formData.currentPassword,
                newEmail: formData.email
            })
        }).then(response => {
            if (response.ok) {
                setNotification({
                    key: 'emailUpdated',
                    message: t('text.emailAddressUpdatedSucessfully')
                });

                refetch();
            } else {
                throw response;
            }
        }).catch(response => {
            if (response.status === 403) {
                setNotification({
                    key: 'emailUpdateError',
                    message: t('errors.unableToUpdateEmailAddress', { context: 'incorrectPassword' })
                });
            } else {
                setNotification({
                    key: 'emailUpdateError',
                    message: t('errors.unableToUpdateEmailAddress', { context: 'unknownError' })
                });
            }
        }).finally(() => {
            form.setSubmitting(false);
        });
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email(),
        currentPassword: Yup.string().required()
    });

    const renderForm = () => {
        return (
            <Formik
                enableReinitialize
                initialValues={props.initialValues}
                onSubmit={handleFormSubmission}
                validationSchema={validationSchema} >

                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                        handleReset
                    } = props;

                    return (
                        <form autoComplete="off">
                            <TextField
                                fullWidth
                                variant="standard"
                                margin="normal"
                                required
                                error={errors.email && touched.email}
                                helperText={errors.email}
                                id="email"
                                label={t('userAccountSettingsPage.labels.emailAddress')}
                                value={values.email}
                                onChange={handleChange} />

                            <br />

                            <TextField
                                fullWidth
                                variant="standard"
                                margin="normal"
                                type="password"
                                id="currentPassword"
                                label={t('userAccountSettingsPage.labels.confirmPassword')}
                                value={values.currentPassword}
                                onChange={handleChange}
                                error={errors.currentPassword && touched.currentPassword}
                                helperText={errors.currentPassword} />

                            <br /><br />

                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={isSubmitting || !dirty}>{ t('userAccountSettingsPage.buttons.changeEmailAddress') }</Button>

              &nbsp;

                            <Fade in={dirty}>
                                <Button onClick={handleReset}>{ t('common.buttons.resetForm') }</Button>
                            </Fade>
                        </form>
                    );
                }}
            </Formik>
        );
    };

    return (
        <div>
            <BasicSnackbar
                notification={notification}
                onClose={() => setNotification(null)}
            />

            {renderForm()}
        </div>
    );
};

export default UpdateEmailAddress;

import React, { useState, useEffect } from 'react';
import useCurrentUserDetails from '../../api/useCurrentUserDetails';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import CardHeader from '@mui/material/CardHeader';

import LoadingPlaceholder from '../shared/LoadingPlaceholder';
import BasicAccountSettings from './BasicAccountSettings';
import UpdateEmailAddress from './UpdateEmailAddress';
import UpdatePassword from './UpdatePassword';
import portalApi, { handleError } from '../../utils/portalApi';
import TimezoneSettings from '../shared/LocalisableDate/TimezoneSettings';
import Breadcrumbs from '../shared/Breadcrumbs';
import { DateTime } from 'luxon';
import PageHeader from '../shared/PageHeader';
import TopBar from 'components/TopBar/TopBar';

const AccountSettings = () => {
    const { t } = useTranslation();
    const { userDetails } = useCurrentUserDetails();
    const [countries, setCountries] = useState(null);
    const [timeZones, setTimeZones] = useState(null);

    useEffect(() => {
        portalApi
            .get('/api/culture/countries')
            .then(response => response.data.sort((a, b) => {
                if (a.englishName === b.englishName) {
                    return 0;
                }

                return (a.englishName > b.englishName) ? 1 : -1;
            }))
            .then(json => setCountries(json))
            .catch(handleError);
    }, []);

    useEffect(() => {
        portalApi
            .get('/api/culture/timezones')
            .then(response => setTimeZones(response.data))
            .catch(handleError);
    }, []);

    const loading = () => {
        if (!countries || !timeZones) {
            return (
                <LoadingPlaceholder />
            );
        }
    };

    const renderBasicDetailsForm = () => {
        const initialValues = {
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            phoneNumber: userDetails.phoneNumber,
            country: userDetails.country,
            timeZone: userDetails.timeZone,
            currentPassword: ''
        };

        return (
            <BasicAccountSettings
                initialValues={initialValues}
                countries={countries}
                timeZones={timeZones}
            />
        );
    };

    const renderUpdateEmailAddressForm = () => {
        const initialValues = {
            email: userDetails.email,
            currentPassword: ''
        };

        return (
            <UpdateEmailAddress
                initialValues={initialValues}
            />
        );
    };

    const renderContent = () => {
        return (
            <div>
                <TopBar />
                <PageHeader>
                    <Container>
                        <Breadcrumbs
                            breadcrumbs={[
                                {
                                    title: t('userAccountSettingsPage.text.pageTitle')
                                }
                            ]}
                        />
                    </Container>
                </PageHeader>

                <Container style={{ marginTop: 32 }}>
                    <Card>
                        <CardHeader title={t('userAccountSettingsPage.text.basicInfoHeading')} />
                        <CardContent>
                            {renderBasicDetailsForm()}
                        </CardContent>
                    </Card>

                    <br /><br />

                    <Card>
                        <CardHeader title={t('userAccountSettingsPage.text.changeEmailHeading')} />
                        <CardContent>
                            {renderUpdateEmailAddressForm()}
                        </CardContent>
                    </Card>

                    <br /><br />

                    <Card>
                        <CardHeader title={t('userAccountSettingsPage.text.changePasswordHeading')} />
                        <CardContent>
                            <UpdatePassword />
                        </CardContent>
                    </Card>

                    <br /><br />

                    <Card>
                        <CardHeader title={t('timezoneSettings.text.changeYourTimezoneHeading')} />
                        <CardContent>
                            <TimezoneSettings
                                date={DateTime.local().toISO()}
                                titleIsHidden />
                        </CardContent>
                    </Card>
                </Container>
            </div>
        );
    };

    return (
        loading() || renderContent()
    );
};

export default AccountSettings;

import AiSummaryReportStatus from 'enums/AiSummaryReportStatus';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const whereReportIsProcessing = (report) => {
    return report.reportStatus === AiSummaryReportStatus.PROCESSING;
};

const getAiSummaryReports = async (focusGroupId) => {
    const { data } = await vlqrApi.get(`focusgroups/${focusGroupId}/aiSummaryReports`)
        .catch((error) => {
            handleError(error);
        });

    return data;
};

const useAiSummaryReportsForFocusGroup = (focusGroupId, shouldPollReports = false) => {
    // used to poll service when unprocessed reports are detected
    const [refetchInterval, setRefetchInterval] = useState(false);

    const { data, refetch, isLoading, isError } = useQuery(
        ['aiSummaryReportsForFocusGroup', focusGroupId], () => getAiSummaryReports(focusGroupId),
        {
            refetchInterval
        }
    );

    // Checks for unprocessed reports and updates the refetchInterval accordingly.
    useEffect(() => {
        if (data?.find(whereReportIsProcessing) || shouldPollReports) {
            setRefetchInterval(3000);
        } else {
            setRefetchInterval(false);
        }

        return () => {
            setRefetchInterval(false);
        };
    }, [data, shouldPollReports]);

    return { data, refetch, isLoading, isError };
};

export default useAiSummaryReportsForFocusGroup;

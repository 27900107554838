import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import VideocamIcon from '@mui/icons-material/Videocam';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label={t('textAnalysisDashboard.labels.firstPage')}
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label={t('textAnalysisDashboard.labels.previousPage')}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t('textAnalysisDashboard.labels.nextPage')}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t('textAnalysisDashboard.labels.lastPage')}
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
};

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const rowsToDisplay = (data, page, rowsPerPage) => {
    if (rowsPerPage > 0) {
        return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    } else {
        return data;
    }
};

export const ParticipantMessageList = ({ data, openRecordingAtTime }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    useEffect(() => {
        setPage(0);
    }, [data]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper} sx={{ minWidth: 600, maxWidth: 1200 }}>
            <Table aria-label={t('textAnalysisDashboard.labels.participantMessageTable')}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('textAnalysisDashboard.tableHeaders.timestamp')}</TableCell>
                        <TableCell>{t('textAnalysisDashboard.tableHeaders.participant')}</TableCell>
                        <TableCell>{t('textAnalysisDashboard.tableHeaders.message')}</TableCell>
                        {
                            openRecordingAtTime && <TableCell></TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rowsToDisplay(data, page, rowsPerPage)
                            .map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.timestamp}
                                    </TableCell>
                                    <TableCell align="left">{row.participant}</TableCell>
                                    <TableCell align="left">{row.transcript}</TableCell>
                                    {
                                        openRecordingAtTime &&
                                        <TableCell>
                                            {
                                                row.recordingTime > 0 && 
                                                <IconButton
                                                    onClick={() => openRecordingAtTime(row.recordingTime)}
                                                    aria-label={t('textAnalysisDashboard.labels.recordingLink')}
                                                >
                                                    <VideocamIcon />
                                                </IconButton>
                                            }
                                        </TableCell>
                                    }
                                </TableRow>
                            ))
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': t('textAnalysisDashboard.labels.rowsPerPage'),
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

ParticipantMessageList.propTypes = {
    data: PropTypes.array,
    participants: PropTypes.array,
    openRecordingAtTime: PropTypes.func
};

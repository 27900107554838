import { useQuery } from 'react-query';
import vlqrApi from 'utils/vlqrApi';

const useParticipantsForActivity = (activityId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['participants', activityId],
        () => getParticipants(activityId)
    );

    return { data, refetch, isLoading, isError };
};

const getParticipants = async (activityId) => {
    if (!activityId) return null;

    const { data } = await vlqrApi.get(`FocusGroups/${activityId}/Participants`);
    return data;
};

export default useParticipantsForActivity;
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const columns = [
    { field: 'timestamp', headerName: 'Timestamp', type: 'dateTime', width: 100, editable: false, 
        valueGetter: (params) => DateTime.fromISO(params.value).toJSDate(),
        valueFormatter: (params) => DateTime.fromJSDate(params.value).toLocaleString(DateTime.TIME_24_WITH_SECONDS) },
    { field: 'participantDisplayName', headerName: 'Participant', type: 'text', width: 200, editable: false },
    { field: 'text', headerName: 'Transcript', type: 'text', editable: true, minWidth: 400, flex: 1 }
];

const saveVoiceTranscription = (data) => {
    const config = { headers: {'Content-Type': 'application/json'} };

    return new Promise((resolve, reject) =>
        vlqrApi.put(`VoiceTranscriptions/${data.activityId}/VoiceTranscriptions/${data.id}`, data.text, config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                handleError(error);
                reject(new Error(error));
            })
    );
};

export const VoiceTranscriptionEditor = ({ data }) => {
    const { t } = useTranslation();

    const [snackbar, setSnackbar] = useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    const processRowUpdate = useCallback(
        async (newRow, oldRow) => {
            if (newRow.text !== oldRow.text)
            {
                const response = await saveVoiceTranscription(newRow);
                if (response.status === 200) {
                    setSnackbar({ children: t('voiceTranscriptionEditor.text.saveSuccess'), severity: 'success' });
                    return newRow;
                }
            }

            return oldRow;
        },
        [t],
    );

    const handleProcessRowUpdateError = useCallback((error) => {
        setSnackbar({ children: t('voiceTranscriptionEditor.text.saveError'), severity: 'error' });
    }, [t]);

    const [filterPanelEl, setFilterPanelEl] = React.useState(null);

    return (
        <div style={{ width: '80%', maxWidth: 1200 }}>
            <Alert ref={setFilterPanelEl} sx={{ mt: 2 }} severity="info">{ t('voiceTranscriptionEditor.text.instructions') }</Alert>
            <DataGrid
                autoHeight
                rows={data}
                columns={columns}
                experimentalFeatures={{ newEditingApi: true }}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                getRowHeight={() => 'auto'}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 1,
                    },
                    [`& .${gridClasses.columnHeader}`]: {
                        outline: 'none !important'
                    },
                }}
                componentsProps={{
                    panel: {
                        anchorEl: filterPanelEl,
                    }
                }}
            />
            {snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={5000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </div>
    );
};

VoiceTranscriptionEditor.propTypes = {
    data: PropTypes.array,
    participants: PropTypes.array
};
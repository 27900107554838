import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { ParticipantMessageList } from 'pages/TextAnalysisDashboard/ParticipantMessageList';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const getParticipantNameFromId = (participants, id) => {
    if (!id) {
        return 'VL Admin';
    }

    return participants.find(p => p.projectParticipantId === id).name;
};

const prepareDataForTable = (data, participants) => {
    if (!data) {
        return [];
    }

    const tableData = data.map(r => ({ 
        id: r.id,
        timestamp: DateTime.fromISO(r.timestamp).toLocaleString(DateTime.TIME_24_WITH_SECONDS),
        participant: getParticipantNameFromId(participants, r.participantId),
        transcript: r.text ?? r.message,
        sentiment: r.sentiment,
        recordingTime: r.recordingTimeInSeconds
    }));

    return tableData;
};

const formatTime = (totalSeconds) => {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    let seconds = totalSeconds % 60;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return hours + ':' + minutes + ':' + seconds;
};

const getTitle = (baseTitle, filters, t) => {
    const titleParams = {
        baseTitle: baseTitle
    };

    let key = 'Title';

    if (filters?.sentiment) {
        titleParams.sentiment = filters.sentiment;
        key = 'Sentiment' + key;
    }

    if (filters?.text) {
        titleParams.containingText = filters.text;
        key += 'Containing';
    }

    if (filters?.timeStart && filters?.timeEnd) {
        titleParams.betweenStart = formatTime(Math.trunc(filters.timeStart));
        titleParams.betweenEnd = formatTime(Math.trunc(filters.timeEnd));
        key += 'Between';
    }

    key = 'participantMessageViewer.title.' + key;

    return t(key, { titleParams });
};

export const ParticipantMessageViewer = ({data, participants, filters, resetFilters, openRecordingAtTime, title}) => {
    const { t } = useTranslation();
    const tableData = prepareDataForTable(data, participants);

    return (
        <div style={{ textAlign: 'center' }}>
            <h2 style={{ padding: '0px 20px 0px 20px' }}>
                <span style={{ marginLeft: '62px' }}>
                    {getTitle(title, filters, t)}
                </span>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ float: 'right', margin: '0px 0px 0px 20px' }}
                    onClick={resetFilters}
                >
                    Reset Filters
                </Button>
            </h2>
            <ParticipantMessageList data={tableData} openRecordingAtTime={openRecordingAtTime} />
        </div>
    );
};

ParticipantMessageViewer.propTypes = {
    data: PropTypes.array,
    participants: PropTypes.array,
    filters: PropTypes.object,
    resetFilters: PropTypes.func,
    openRecordingAtTime: PropTypes.func,
    title: PropTypes.string
};
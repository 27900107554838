import { styled } from '@mui/material';
import usePollResult from 'api-new/usePollResult';
import React from 'react';
import { LoadingPlaceholderFullPage } from 'components/shared/LoadingPlaceholderFullPage';
import { RankingResultView } from 'vl-reporting-components';
import useParticipantsForActivity from 'api-new/useParticipantsForActivity';
import { useParams } from 'react-router-dom';

const RankingPollReportContainer = styled('div')({
    height: '100%',
    width: '100%',
    backgroundColor: 'white'
});

const RankingPollReport = () => {
    const {
        pollId
    } = useParams();
    const { data: pollResultData, isLoading: isLoadingPoll } = usePollResult(pollId);
    const { data: participants, isLoading: isLoadingParticipants } = useParticipantsForActivity(pollResultData?.focusGroupId);

    if (isLoadingPoll || isLoadingParticipants) {
        return <LoadingPlaceholderFullPage />;
    }

    // Format poll answers and participant names so that they are ready for RankingResultView component
    const pollAnswers = {};
    pollResultData.options.forEach(option => {
        pollAnswers[option.id] = option.answers.map(answer => ({
            ...answer,
            // Remapping user IDs so the external component knows what to look for
            userIdentifier: answer.participantId
        }));

    });

    const participantNameMap = {};
    participants.forEach(participant => {
        participantNameMap[participant.projectParticipantId] = participant.name;
    });

    return (
        <RankingPollReportContainer>
            <RankingResultView
                options={pollResultData.options}
                answers={pollAnswers}
                participantNameMap={participantNameMap}
                showRespondentInformation
            />
        </RankingPollReportContainer>
    );
};

RankingPollReport.propTypes = {};

export default RankingPollReport;

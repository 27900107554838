import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// Mui icons
import PersonIcon from '@mui/icons-material/Person';
import HelpIcon from '@mui/icons-material/HelpRounded';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import LaunchIcon from '@mui/icons-material/Launch';
import ChangeLogIcon from '@mui/icons-material/Campaign';


import { apiUrl } from '../../../Config';
import UserAvatar from '../UserAvatar/UserAvatar';
import useCurrentUserDetails from 'api/useCurrentUserDetails';
import { releaseNotesUri } from 'Config';

const UserMenu = ({ anchorEl, onClose, forceInline }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userDetails } = useCurrentUserDetails();
    const openReleaseNote = () => window.open(releaseNotesUri, '_blank').focus();
    let inlineProps = {};
    if (forceInline) {
        inlineProps.disablePortal = true;
        inlineProps.anchorOrigin = 'top';
    }

    if (!userDetails) {
        return null;
    }

    return (
        <Menu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
            {...inlineProps}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1em', width: '20em' }}>
                <UserAvatar style={{ height: '2.4em', width: '2.4em', fontSize: '2rem', display: 'inline-flex', marginBottom: '0.4em' }} />
                <Typography variant="h6">{ userDetails.firstName } { userDetails.lastName}</Typography>
                <Typography variant="body2">{ userDetails.email }</Typography>
            </div>

            <Divider style={{ margin: '1em', marginTop: 0 }} />

            <MenuItem
                onClick={() => {
                    window.open(`${apiUrl}/Company/Choose?from=app&page=${encodeURIComponent(window.location.pathname)}`, '_blank');
                }}
            >
                <ListItemIcon>
                    <LaunchIcon />
                </ListItemIcon>
                { t('userMenu.buttons.openLegacyPortalApplication') }
            </MenuItem>

            <Divider style={{ margin: '1em' }} />

            <MenuItem
                onClick={() => {
                    navigate('/currentUser/accountSettings');
                    onClose();
                }}
            >
                <ListItemIcon>
                    <PersonIcon fontSize="small" />
                </ListItemIcon>
                { t('userMenu.buttons.openUserAccountSettings') }
            </MenuItem>

            <MenuItem
                onClick={() => {
                    window.open('https://visionslive.zendesk.com/hc/en-gb', '_blank');
                }}
            >
                <ListItemIcon>
                    <HelpIcon fontSize="small" />
                </ListItemIcon>
                { t('common.buttons.openHelp') }
            </MenuItem>

            <MenuItem onClick={openReleaseNote}>
                <ListItemIcon>
                    <ChangeLogIcon fontSize="small" />
                </ListItemIcon>
                { t('userMenu.buttons.openReleaseNotes') }
            </MenuItem>

            <MenuItem
                onClick={() => {
                    window.open('/third-party-licenses.txt', '_blank');
                }}
            >
                <ListItemIcon />
                { t('userMenu.buttons.openThirdPartyNotices') }
            </MenuItem>
            
            <MenuItem
                onClick={() => {
                    window.open(`${apiUrl}/Content/third-party-notices.txt`, '_blank');
                }}
            >
                <ListItemIcon />
                { t('userMenu.buttons.openLegacyThirdPartyNotices') }
            </MenuItem>

            <MenuItem
                onClick={() => {
                    window.location.href = `${apiUrl}/Membership/LogOff`;
                }}
            >
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                { t('common.buttons.logout') }
            </MenuItem>
        </Menu>
    );
};

UserMenu.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    forceInline: PropTypes.bool
};

export default UserMenu;

import { createTheme } from '@mui/material';
import { Blue, Grey, Green, Cyan, Red, VLRoyal } from './colorPalette.js';

const defaultTheme = {
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontSize: '15px',
                    lineHeight: 'normal'
                },
                outlined: {
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    color: Grey[2]
                },
                text: {
                    color: Grey[2]
                },
                label: {
                    lineHeight: 'normal'
                }
            }
        }
    },
    typography: {
        h2: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '23px',
            letterSpacing: '0.24px',
            color: Grey[2]
        },
        h6: {
            fontWeight: 400,
            fontSize: '1.1rem'
        },
        subtitle2: {
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '18px',
            letterSpacing: '0.24px',
            color: Grey[4]
        },
        pageSubtitle: {
            fontWeight: 'bold',
            fontSize: '16px',
            color: Blue[1],
            lineHeight: '22px'
        },
        pageSubtitle2: {
            fontWeight: 'bold',
            fontSize: '16px',
            color: Blue[4],
            lineHeight: '22px'
        },
        pageDescription: {
            fontSize: '14px',
            color: Grey[3],
            letterSpacing: '0.24px'
        },
        primaryItemLink: { // The main link which appears per company/project/activity row.
            color: Blue[3],
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 500,
            maxWidth: '50em'
        }
    },
    palette: {
        action: {
            hoverOpacity: 0.1
        },
        newPrimary: {
            dark: VLRoyal[3],
            main: VLRoyal[4],
            light: VLRoyal[5],
            contrastText: '#fff'
        },
        primary: {
            main: Blue[4],
            contrastText: '#fff'
        },
        secondary: {
            main: Grey[4],
            contrastText: '#fff'
        },
        grey: {
            1: Grey[0],
            2: Grey[1],
            3: Grey[2],
            4: Grey[3],
            5: Grey[4],
            6: Grey[5],
            7: Grey[6],
            8: Grey[7],
            9: Grey[8],
            10: Grey[9]
        },
        background: {
            default: Grey[9]
        },
        text: {
            primary: Grey[2],
            secondary: Grey[4]
        },
        divider: Grey[8],
        success: {
            main: '#2e7d32', // from MUI theme default
            pastel: '#88ff88'
        }
    },
    shape: {
        borderRadius: 4
    },
    // Custom Components
    activityAvatar: {
        cannotStart: {
            background: Red[9],
            border: `thin solid ${Red[6]}`,
            color: Red[6]
        },
        completed: {
            background: Blue[9],
            border: `thin solid ${Blue[6]}`,
            color: Blue[6]
        },
        didNotStart: {
            background: Red[9],
            border: `thin solid ${Red[6]}`,
            color: Red[6]
        },
        draft: {
            background: Grey[9],
            border: `thin solid ${Grey[6]}`,
            color: Grey[6]
        },
        readyToStart: {
            background: Cyan[9],
            border: `thin solid ${Cyan[4]}`,
            color: Cyan[4]
        },
        running: {
            background: Green[9],
            border: `thin solid ${Green[4]}`,
            color: Green[4]
        },
        upcoming: {
            background: Cyan[9],
            border: `thin solid ${Cyan[4]}`,
            color: Cyan[4]
        },
        neutral: {
            background: Grey[9],
            color: Grey[4]
        }
    },
    activityStatusChip: {
        default: {
            display: 'inline-block',
            fontSize: '14px',
            padding: '4px 16px',
            backgroundColor: 'black',
            borderRadius: 32
        },
        cannotStart: {
            backgroundColor: Red[5],
            color: 'white'
        },
        completed: {
            backgroundColor: Blue[6],
            color: 'white'
        },
        didNotStart: {
            backgroundColor: Red[5],
            color: 'white'
        },
        draft: {
            backgroundColor: Grey[5],
            color: 'white'
        },
        readyToStart: {
            color: 'white',
            backgroundColor: Cyan[4]
        },
        running: {
            color: 'white',
            backgroundColor: Green[4]
        },
        upcoming: {
            backgroundColor: Cyan[4],
            color: 'white'
        }
    },
    activitySummaryLabel: {
        default: {
            fontSize: '14px',
            color: Grey[4]
        },
        cannotStart: {
            color: Red[4]
        },
        completed: {
            color: Blue[5]
        },
        didNotStart: {
            color: Red[6]
        },
        draft: {
            color: Grey[5]
        },
        readyToStart: {
            color: Cyan[3]
        },
        running: {
            color: Green[3]
        },
        upcoming: {
            color: Cyan[3]
        }
    },
    appBar: {
        root: {
            boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.05)',
            backgroundColor: 'white',
            '& .VL-IconTab': {
                height: 54,
                paddingLeft: 12,
                paddingRight: 12,
                borderLeft: `1px solid ${Grey[8]}`,
                display: 'flex',
                alignItems: 'center',
                color: Grey[5],
                '&:hover': {
                    backgroundColor: Grey[9],
                    borderColor: Grey[8]
                },
                '&.VL-IconTabActive': {
                    backgroundColor: Grey[9],
                    color: Blue[1],
                    pointerEvents: 'none'
                }
            },
            '& .VL-UserCompanyHeading': {
                paddingRight: 16,
                textAlign: 'right',
                '& .MuiTypography-subtitle1': {
                    fontSize: 14,
                    color: Grey[4],
                    lineHeight: 'normal',
                    fontWeight: 400
                },
                '& .MuiTypography-subtitle2': {
                    fontSize: 14,
                    color: Blue[2],
                    lineHeight: 'normal',
                    fontWeight: 500
                }
            }
        },
        projectHeading: {
            root: {
                padding: '8px 12px',
                display: 'flex',
                alignItems: 'center',
                '& .MuiTypography-h2': {
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: Grey[2],
                    lineHeight: 'normal'
                },
                '& .MuiTypography-subtitle1': {
                    fontSize: 13,
                    color: Grey[4],
                    lineHeight: 'normal'
                }
            }
        },
        userAvatar: {
            root: {
                backgroundColor: Blue[9],
                color: Blue[1],
                fontSize: '14px',
                fontWeight: '500',
                cursor: (props) => (props.onClick ? 'pointer' : 'auto'),
                width: '38px',
                height: '38px'
            }
        }
    },
    checkboxGroup: {
        root: {
            '& .MuiFormLabel-root': {
                fontSize: '13px',
                color: Grey[3]
            },
            '& .MuiFormGroup-root': {
                padding: 8,
                paddingBottom: 0,
                '& .MuiFormControlLabel-label': {
                    fontSize: '15px',
                    fontWeight: '500',
                    color: Blue[1],
                    letterSpacing: '0.24px',
                    paddingLeft: 8
                },
                '& .MuiButtonBase-root': {
                    padding: 3
                },
                '& .MuiSvgIcon-root': {
                    fill: Blue[1],
                    height: 24,
                    width: 24
                }
            }
        }
    },
    dividers: {
        backgroundDivider: {
            backgroundColor: Grey[8]
        },
        contentDivider: {
            backgroundColor: Grey[9]
        }
    },
    footer: {
        marginTop: 32,
        marginBottom: 32,
        padding: 32,
        textAlign: 'center',
        color: Grey[4],
        borderRadius: 12
    },
    globalSearch: {
        width: '38em',
        backgroundColor: Grey[9],
        color: Grey[2],
        borderRadius: 4,
        border: `thin solid ${Grey[8]}`,
        display: 'flex',
        alignItems: 'center',
        '& .MuiTextField-root': {
            padding: 8,
            paddingLeft: 12,
            paddingRight: 12,
            '& .MuiInputBase-input': {
                padding: 0,
                fontSize: '15px',
                lineHeight: 'normal',
                '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: Grey[4],
                    opacity: 1
                }
            },
            display: 'inline-flex',
            flexGrow: 1
        },
        '& .MuiButton-root': {
            marginRight: 2,
            borderRadius: 32
        }
    },
    pageButtonsContainer: {
        backgroundColor: 'white',
        padding: '0px 8px',
        boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.05)',
        '& .MuiIconButton-root': {
            padding: 8,
            margin: 2,
            '& .MuiSvgIcon-root': {
                fill: Blue[2],
                '&:hover': {
                    fill: Blue[3]
                }
            },
            '&:hover': {
                backgroundColor: Grey[9]
            },
            '&.Mui-disabled': {
                '& .MuiSvgIcon-root': {
                    fill: Grey[8]
                }
            }
        },
        '& .MuiDivider-root': {
            marginLeft: 4,
            marginRight: 4,
            backgroundColor: Grey[9]
        }
    },
    pageHeader: {
        root: {
            background: 'linear-gradient(160deg, #2680C2 60%, rgba(64,152,215,1) 100%)',
            paddingTop: 4,
            '& .MuiTypography-h1': {
                padding: 16,
                paddingLeft: 0,
                color: 'white',
                fontSize: '20px',
                lineHeight: '23px',
                display: 'inline-block'
            },
            '& .MuiTypography-subtitle1': {
                padding: 16,
                paddingLeft: 0,
                color: Blue[8],
                fontSize: '15px',
                lineHeight: '23px',
                fontWeight: 400,
                display: 'inline-block'
            }
        }
    },
    projectInfoIcon: {
        fill: Grey[7],
        '&:hover': {
            fill: Grey[5]
        }
    },
    projectStatusChip: {
        default: {
            display: 'inline-flex',
            alignItems: 'center',
            padding: '6px 12px',
            backgroundColor: Grey[9],
            color: Grey[4],
            borderRadius: 32,
            marginRight: '8px',
            border: `thin solid ${Grey[8]}`,
            '& .MuiSvgIcon-root': {
                fill: Grey[4],
                marginRight: '4px',
                height: '20px'
            },
            '&:last-child': {
                marginRight: 0
            }
        },
        runningActivities: {
            backgroundColor: Green[4],
            border: `thin solid ${Green[4]}`,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white',
                marginRight: '4px',
                height: '20px'
            }
        }
    },
    tabs: {
        root: {
            minHeight: 0,
            '& .MuiTabs-indicator': {
                display: 'none'
            },
            '& .MuiTab-root': {
                minHeight: 0,
                borderRadius: '4px 4px 0 0',
                background: Blue[5],
                color: 'white',
                opacity: 1,
                marginRight: 4,
                '& .MuiTab-wrapper': {
                    textTransform: 'none',
                    padding: 4
                },
                '& .MuiSvgIcon-root': {
                    fill: 'white',
                    marginRight: 8,
                    height: 16,
                    width: 16
                },
                '&:hover': {
                    backgroundColor: Blue[6],
                    '& .MuiSvgIcon-root': {
                        marginRight: 8
                    }
                },
                '&.Mui-selected': {
                    background: 'white',
                    color: Grey[1],
                    '& .MuiSvgIcon-root': {
                        fill: Blue[1]
                    }
                }
            }
        }
    },
    toolbar: {
        backgroundColor: 'rgba(255,255,255,0.6)'
    },
    rescheduleActivityButton: {
        color: Red[4],
        backgroundColor: Grey[9],
        '&:hover': {
            borderColor: Red[5],
            backgroundColor: Red[9]
        },
        textTransform: 'none',
        fontSize: '14px',
        padding: '8px 16px',
        border: `thin solid ${Grey[8]}`
    },
    subtleButton: {
        root: {
            backgroundColor: Grey[9],
            boxShadow: 'none',
            border: `thin solid ${Grey[8]}`,
            color: Grey[2],
            '&:hover': {
                backgroundColor: Grey[8],
                boxShadow: 'none',
                borderColor: Grey[7]
            }
        }
    },
    viewReportButton: {
        color: Blue[4],
        backgroundColor: Grey[9],
        '&:hover': {
            borderColor: Blue[5],
            backgroundColor: Blue[9]
        },
        textTransform: 'none',
        fontSize: '14px',
        padding: '8px 16px',
        border: `thin solid ${Grey[8]}`
    },
    videoPlayer: {
        squareIconButton: {
            default: {
                backgroundColor: Grey[8],
                color: Grey[2],
                borderRadius: 4,
                marginRight: 16,
                padding: 12,
            },
            selected: {
                backgroundColor: Grey[1],
                color: Grey[9]
            }
        }
    }
};

const theme = createTheme(defaultTheme);

export default theme;

import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getPollActvityAnswers = async (activityId) => {
    const { data } = await vlqrApi.get(`reports/activities/${activityId}/pollAnswers`)
        .catch((error) => {
            handleError(error);
        });
    return data?.filter(x => !x.deletedOn);
};

const usePollAnswersForActivity = (activityId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['pollAnswersForActivity', activityId],
        () => getPollActvityAnswers(activityId)
    );

    return { data, refetch, isLoading, isError };
};

export default usePollAnswersForActivity;
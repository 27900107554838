import { makeStyles } from '@mui/styles';
import color from 'color';

export const useCardStyles = makeStyles((theme) => {
    return {
        card: {
            height: '100%',
            minHeight: '18em',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'stretch',
            '& .MuiCardContent-root': {
                width: '100%'
            }
        },
        cardActionArea: {
            flexGrow: 1,
            height: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'stretch'
        },
        media: {
            width: '100%',
            height: 70,
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            backgroundColor: props => color(props.color).lighten(0.2).hex(),
            backgroundBlendMode: 'overlay',
            marginBottom: theme.spacing(1)
        },
        iconAvatar: {
            position: 'absolute',
            top: 80,
            width: 70,
            height: 70,
            left: '50%',
            transform: 'translate(-50%, -75%)',
            border: 'thick solid white',
            backgroundColor: props => props.color
        }
    };
});

import { styled, Button, Dialog, DialogActions, DialogContent, Typography, Divider } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { LoadingPlaceholderFullPage } from 'components/shared/LoadingPlaceholderFullPage';
import useParticipantsForActivity from 'api-new/useParticipantsForActivity';
import useHeatmapResponsesForActivity from 'api-new/stim-based/useHeatmapResponsesForActivity';
import useHeatmapSentimentOptionsForActivity from 'api-new/stim-based/useHeatmapSentimentOptionsForActivity';
import useStimulus from 'api-new/stim-based/useStimulus';
import useActivity from 'api/useActivity';
import { HeatmapActivityResultsImage, HeatmapActivityCommentsTable, HeatmapActivityResultsHeader } from 'vl-reporting-components';
import { HeatmapActivityResultViewPrintable, HeatmapActivityResultsFilter, HeatmapActivityResultsPrompt } from 'vl-reporting-components';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { PrintOutlined } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { toPng } from 'html-to-image';

const ActivityResultsContainer = styled('div')(({ theme }) => ({
    minWidth: 0,
    padding: '24px',
    backgroundColor: '#FFF',
    '& .MuiTypography-subtitle2': {
        color: '#102A43',
        fontWeight: 500
    },
    '& .MuiTypography-h6': {
        fontWeight: 500
    },
    '& .MuiPaper-root': {
        borderColor: '#D9E2EC'
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
}));

const ReportButton = withStyles(theme => ({
    root: {
        ...theme.viewReportButton
    }
}))(Button);

const HeatmapActivityResults = ({activity, focusGroupId, isOpen, onClose, disablePortal}) => {
    const { data: heatmapResponses, isLoading: isLoadingHeatmapResponses } = useHeatmapResponsesForActivity(activity.id);
    const { data: heatmapSentimentOptions, isLoading: isLoadingHeatmapSentimentOptions } = useHeatmapSentimentOptionsForActivity(activity.id);
    const { data: participants, isLoading: isLoadingParticipants } = useParticipantsForActivity(focusGroupId);
    const { data: stimulus, isLoading: isLoadingStimulus } = useStimulus(focusGroupId, activity.stimulusId);
    const { data: focusGroup, isLoading: isLoadingFocusGroup } = useActivity(focusGroupId);

    const { t } = useTranslation();

    const printRef = useRef();
    const imageRef = useRef();
    const [combinedImage, setCombinedImage] = useState(null);
    const [isPrinting, setIsPrinting] = useState(null);
    const [onScreenSortBy, setOnScreenSortBy] = useState('sentimentScore');
    const [onScreenSortOrder, setOnScreenSortOrder] = useState('desc');
    const annotationType = activity?.annotationType;
    const [filter, setFilter] = useState({ sentimentFilter: [], respondentFilter: [] });
    const filterIsActive = filter.sentimentFilter.length > 0 || filter.respondentFilter.length > 0;

    // We store the resolve Promise being used in `onBeforeGetContent` here
    const promiseResolveRef = useRef(null);

    // We watch for the state to change here, and for the Promise resolve to be available
    useEffect(() => {
        if (isPrinting && combinedImage && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting, combinedImage]);

    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {
            return new Promise((resolve, reject) => {
                setCombinedImage(null);
                setIsPrinting(true);

                toPng(imageRef.current, { cacheBust: true })
                    .then((dataUrl) => {
                        setCombinedImage(dataUrl);
                        promiseResolveRef.current = resolve;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        content: () => {
            return printRef.current;
        },
        onAfterPrint: () => {
            promiseResolveRef.current = null;
            setIsPrinting(false);
            setCombinedImage(null);
        },
        onPrintError: (src, error) => {
            setIsPrinting(false);
            setCombinedImage(null);
        }
    });

    if (isLoadingHeatmapResponses || isLoadingHeatmapSentimentOptions || isLoadingParticipants || isLoadingStimulus || isLoadingFocusGroup) {
        return <LoadingPlaceholderFullPage />;
    }

    const participantNameMap = _.keyBy(participants, 'focusGroupUserId');

    const filteredResponses = heatmapResponses?.filter(response => (
        (filter.sentimentFilter.length === 0 || filter.sentimentFilter.includes(response.sentiment)) &&
        (filter.respondentFilter.length === 0 || filter.respondentFilter.includes(response.createdByUserId))
    )) ?? [];

    const customSentimentConfig = _.keyBy(heatmapSentimentOptions, 'sentiment');

    return (
        <Dialog
            disablePortal={disablePortal}
            open={isOpen}
            onClose={onClose}
            maxWidth='xl'
            fullWidth
        >
            <DialogContent>
                <ActivityResultsContainer>
                    <HeatmapActivityResultsHeader
                        activity={activity}
                        responses={heatmapResponses}
                        customSentimentConfig={customSentimentConfig}
                        focusGroupTitle={focusGroup.details.title}
                        rightSlotActive={!filterIsActive}
                    />

                    <Divider />

                    <HeatmapActivityResultsFilter
                        responses={heatmapResponses}
                        customSentimentConfig={customSentimentConfig}
                        participants={participants}
                        onFilterChange={(newFilter) => setFilter(newFilter)}
                    />

                    <Divider />

                    <HeatmapActivityResultsPrompt activity={activity}/>

                    <Box sx={{ display: 'flex', height: 600, overflow: 'hidden' }} ref={imageRef}>
                        <HeatmapActivityResultsImage
                            responses={filteredResponses}
                            customSentimentConfig={customSentimentConfig}
                            participants={participantNameMap}
                            reportView
                            stimulus={stimulus}
                            annotationType={annotationType}
                        />
                    </Box>

                    { filteredResponses.length === 0 &&
                        <Typography sx={{display: 'flex', justifyContent: 'center'}}>{t('heatmap.results.noResultsFromFilter')}</Typography>
                    }

                    <Divider/>

                    <HeatmapActivityCommentsTable
                        responses={filteredResponses}
                        customSentimentConfig={customSentimentConfig}
                        participants={participantNameMap}
                        setOnScreenSortBy={setOnScreenSortBy}
                        setOnScreenSortOrder={setOnScreenSortOrder}
                    />
                </ActivityResultsContainer>
            </DialogContent>

            <ActivityResultsContainer
                ref={printRef}
                sx={{display: 'none', displayPrint: 'block'}}
            >
                <HeatmapActivityResultViewPrintable
                    responses={filteredResponses}
                    customSentimentConfig={customSentimentConfig}
                    participants={participantNameMap}
                    activity={activity}
                    focusGroupTitle={focusGroup.details.title}
                    image={combinedImage}
                    onScreenSortBy={onScreenSortBy}
                    onScreenSortOrder={onScreenSortOrder}
                    filterActive={filteredResponses.length > 0}
                    filter={filter}
                />
            </ActivityResultsContainer>

            <DialogActions>
                <Button onClick={onClose}>{ t('common.buttons.close') }</Button>

                <ReportButton
                    disabled={isPrinting}
                    startIcon={<PrintOutlined />}
                    onClick={handlePrint}
                >
                    {t('focusGroups.activities.print')}
                </ReportButton>
            </DialogActions>
        </Dialog>
    );
};

HeatmapActivityResults.propTypes = {
    activity: PropTypes.object,
    focusGroupId: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    disablePortal: PropTypes.bool
};

export default HeatmapActivityResults;

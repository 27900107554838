import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Stack, Toolbar } from '@mui/material';
import PortalIFrame from 'components/shared/PortalIFrame';
import VoiceTranscriptionsImporter from './VoiceTranscriptionsImporter';
import { ArrowBack, UploadFile } from '@mui/icons-material';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const VoiceTranscriptionsReport = ({ focusGroupId, voiceTranscriptionsTabUri }) => {
    const [isImportingTranscripts, setIsImportingTranscripts] = useState(false);
    const importVoiceTranscriptsFeatureEnabled = useFeatureIsOn('voice-transcriptions-import');

    if (isImportingTranscripts) {
        return (
            <React.Fragment>
                <Stack gap={3}>
                    <Toolbar variant='dense' sx={{ backgroundColor: 'grey.10' }}>
                        <Button startIcon={<ArrowBack />} onClick={() => setIsImportingTranscripts(false)}>
                            Back
                        </Button>
                    </Toolbar>

                    <VoiceTranscriptionsImporter focusGroupId={focusGroupId} />
                </Stack>
            </React.Fragment>
        );
    }

    return (
        <Stack gap={2}>
            { importVoiceTranscriptsFeatureEnabled &&
                <Toolbar variant='dense' sx={{ backgroundColor: 'grey.10', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" startIcon={<UploadFile />} onClick={() => setIsImportingTranscripts(true)}>
                        Import Transcripts
                    </Button>
                </Toolbar>
            }

            <PortalIFrame
                containerProps={{ disableGutters: true }}
                id={'voiceTranscriptionsIframe'}
                frameInfo={{hideReportTabs: true}}
                relativeUrl={voiceTranscriptionsTabUri} />
        </Stack>
    );
};

VoiceTranscriptionsReport.propTypes = {
    focusGroupId: PropTypes.string,
    voiceTranscriptionsTabUri: PropTypes.string
};

export default VoiceTranscriptionsReport;

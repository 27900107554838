import {
    SET_IS_OFFLINE,
    SET_THEME,
    ADD_PREVIEW_TIMEZONE,
    REMOVE_PREVIEW_TIMEZONE,
    SET_COMPANY_COUNT,
    SET_API_UNAVAILABLE
} from './actions';

import blueTheme from '../themes/blue/theme';

const UserDetailsReducer = (state = null, action) => {
    switch (action.type) {
        case SET_COMPANY_COUNT:
            return { ...state, companyCount: action.companyCount };
        default:
            return state;
    }
};

const defaultUIState = {
    isOffline: false,
    theme: blueTheme,
    previewTimezones: []
};

const UserInterfaceStateReducer = (state = defaultUIState, action) => {
    switch (action.type) {
        case SET_IS_OFFLINE:
            return {
                ...state,
                isOffline: action.isOffline
            };
        case SET_THEME:
            return {
                ...state,
                theme: action.theme
            };
        case ADD_PREVIEW_TIMEZONE:
            return {
                ...state,
                previewTimezones: [...state.previewTimezones, action.timezone]
            };
        case REMOVE_PREVIEW_TIMEZONE:
            return {
                ...state,
                previewTimezones: state.previewTimezones.filter((tz) => tz !== action.timezone)
            };
        case SET_API_UNAVAILABLE:
            return {
                ...state,
                apiUnavailable: action.apiUnavailable
            };
        default:
            return state;
    }
};

const GlobalReducer = {
    UserDetailsReducer,
    UserInterfaceStateReducer
};

export default GlobalReducer;

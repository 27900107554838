import React from 'react';
import useResizeObserver from 'use-resize-observer';

// See https://www.npmjs.com/package/@react-hook/resize-observer
export const useSize = (target) => {
    const [size, setSize] = React.useState();

    React.useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect());
    }, [target]);

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect));
    return size;
};

import { Button, Divider, List, ListSubheader, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import useActivityRecordings from 'api/useActivityRecordings';
import { PlayArrow } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import RecordingType from 'enums/RecordingType';
import RecordingListItem from './RecordingListItem';

const RecordingsTabPanel = ({ focusGroupId }) => {
    const { data: recordings, isLoading } = useActivityRecordings(focusGroupId);
    const { t } = useTranslation();

    if (isLoading) {
        return null;
    }

    const processedRecordings = [];
    recordings.forEach(recording => {
        processedRecordings.push({
            id: recording.id,
            type: RecordingType.Video,
            uri: recording.outputUrl,
            translatedAudio: recording.translatedAudio
        });
        processedRecordings.push({
            id: `${recording.id}-audio`,
            type: RecordingType.Audio,
            uri: recording.outputUrl.replace(/\.mp4$/i, '.mp3'),
            translatedAudio: recording.translatedAudio
        });
    });

    const originalRecordings = processedRecordings.filter(e => !e.translatedAudio);
    const translatedRecordings = processedRecordings.filter(e => e.translatedAudio);

    return <Stack sx={{margin: 3}} spacing={6} useFlexGap>
        <Stack spacing={2} useFlexGap alignItems='flex-start'>
            <div>
                <Typography variant='h6' gutterBottom>
                    { t('reporting.recordings.heading.viewRecording') }
                </Typography>
                <Typography variant='body1'>{ t('reporting.recordings.enhancedPlayerDescription') }</Typography>
            </div>

            <Button
                variant='contained'
                size='large'
                startIcon={<PlayArrow />}
                href={`/recordingViewer/${focusGroupId}`}
                target='_blank'
            >
                { t('reporting.recordings.buttons.enhancedPlayer') }
            </Button>
        </Stack>

        <Stack alignItems='flex-start'>
            <Typography variant='h6' gutterBottom>
                { t('reporting.recordings.heading.downloads') }
            </Typography>

            <Paper>
                <List sx={{width: 600}}>
                    <ListSubheader>
                        { t('reporting.recordings.type.originalAudio') }
                    </ListSubheader>
                    {originalRecordings.map(recording => <RecordingListItem recording={recording} key={recording.id} />)}
                    <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                    <ListSubheader>
                        { t('reporting.recordings.type.translatedAudio') }
                    </ListSubheader>
                    {translatedRecordings.map(recording => <RecordingListItem recording={recording} key={recording.id} />)}
                </List>
            </Paper>
        </Stack>
    </Stack>;
};

RecordingsTabPanel.propTypes = {
    focusGroupId: PropTypes.string.isRequired
};

export default RecordingsTabPanel;

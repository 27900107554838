import React, { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';import { useTranslation } from 'react-i18next';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import portalApi from 'utils/portalApi';
import PortalIFrame from 'components/shared/PortalIFrame';
import Delete from './Delete';
import {
    createLinksRelativeURL,
    createSetupRelativeURL,
    createStatusRelativeURL
} from 'utils/activityUrlHelper';
import ProductType from 'enums/ProductType';
import ActivityStatus from 'enums/ActivityStatus';
import CompanyRole from 'enums/CompanyRole';
import OverviewTab from './OverviewTab';
import { Box, Container, IconButton, Link, Typography, useTheme } from '@mui/material';
import LocalisableDate from 'components/shared/LocalisableDate/LocalisableDate';
import ActivityStatusChip from 'components/shared/ActivityStatusChip';
import FormattedDuration from 'components/shared/FormattedDuration';
import MainActivityLinks from './MainActivityLinks';
import ResponsiveDialog from 'components/shared/ResponsiveDialog';
import TranslatorAccessCodeWarning from './TranslatorAccessCodeWarning';
import useCompany from 'api/useCompany';
import useActivity from 'api/useActivity';
import TopBar from 'components/TopBar/TopBar';
import PageHeader from 'components/shared/PageHeader';
import PageButtonsContainer from 'components/shared/PageButtonsContainer';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PageMenu from './PageMenu';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';

const VLAllActivitiesLink = styled(Link)(({ theme }) => ({
    color: '#DCEEFB',
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: 4,
    padding: 4,
    paddingRight: 8,
    border: 'thin solid #DCEEFB',
    borderRadius: 4,
    '&:hover': {
        color: 'white',
        borderColor: 'white',
        backgroundColor: '#4098D7'
    },
    transition: 'all .3s'
}));

const ActivityOverview = () => {
    const {
        company_guid: companyGuid,
        project_guid: projectGuid,
        activity_guid: activityGuid
    } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    const { data: selectedCompany, isLoading: companyIsLoading } = useCompany(companyGuid);
    const { data: selectedActivity, isLoading: activityIsLoading, refetch } = useActivity(activityGuid);
    const companyRole = CompanyRole[selectedCompany?.role];

    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    const [linksDialogIsOpen, setLinksDialogIsOpen] = useState(false);
    const [setupDialogIsOpen, setSetupDialogIsOpen] = useState(false);
    const [participantStatusDialogIsOpen, setParticipantStatusDialogIsOpen] = useState(false);
    const [isBilledRequestPending, setIsBilledRequestPending] = useState(false);
    const [pageMenuAnchorEl, setPageMenuAnchorEl] = useState(null);

    const toggleIsBilled = useCallback(() => {
        setIsBilledRequestPending(true);
        portalApi.put(`/api/activity/${activityGuid}/billed`, { isBilled: !selectedActivity?.details?.isBilled })
            .then(async () => await refetch())
            .then(() => setIsBilledRequestPending(false));
    }, [activityGuid, selectedActivity, refetch]);

    const loading = () => (
        companyIsLoading || activityIsLoading ? <LoadingPlaceholder /> : null
    );

    const shouldShowSetupView = () => {
        const activityStatus = ActivityStatus[selectedActivity?.details?.detailedStatus];

        if (!companyRole.canSetupActivity) {
            return false;
        }

        if (activityStatus !== ActivityStatus.draft && activityStatus !== ActivityStatus.didNotStart) {
            return false;
        }

        return true;
    };

    const routes = () => {
        const productType = selectedActivity?.details?.activityType;

        if (shouldShowSetupView()) {
            return (
                <PortalIFrame
                    containerProps={{ disableGutters: true }}
                    id={'activitySetupIframe'}
                    onDashboardRedirect={() => {
                        refetch();
                    }}
                    onSummaryRedirect={() => {
                        refetch();
                    }}
                    relativeUrl={createSetupRelativeURL(ProductType[productType], projectGuid, activityGuid)} />
            );
        }

        return (
            <OverviewTab
                companyId={companyGuid}
                projectId={projectGuid}
                activityId={activityGuid}
            />
        );
    };

    const main = () => (
        <div style={{ backgroundColor: 'white', paddingBottom: 24 }}>
            <TopBar
                companyId={companyGuid}
                projectId={projectGuid}
                activityId={activityGuid}
            />
            <PageHeader>
                <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 12 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <VLAllActivitiesLink
                            to={`/company/${companyGuid}/project/${projectGuid}/activities`}
                            component={RouterLink}
                            underline="none"
                        >
                            <ArrowBackIcon />
                            <span>{ t('activityPage.text.allActivities') }</span>
                        </VLAllActivitiesLink>

                        <Typography variant="h1">{selectedActivity?.details.description ?? selectedActivity?.details.title}</Typography>
                    </div>

                    <PageButtonsContainer>
                        <IconButton onClick={(e) => setPageMenuAnchorEl(e.currentTarget)} size="large">
                            <MoreHorizIcon />
                        </IconButton>
                    </PageButtonsContainer>
                </Container>
            </PageHeader>

            <Container>
                <Box sx={{ display: 'flex', paddingTop: 2, gap: 3 }}>
                    <div style={{ width: 240, minWidth: 240 }}>
                        <div
                            style={{
                                padding: 16,
                                backgroundColor: theme.palette.background.default,
                                borderRadius: 8,
                                marginBottom: 24
                            }}
                        >
                            <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                                <LocalisableDate
                                    typographyProps={{
                                        variant: 'subtitle1',
                                        style: {
                                            ...theme.typography.pageSubtitle
                                        }
                                    }}
                                    date={selectedActivity.schedule.startTime}
                                    placeholderText={t('activityPage.text.unscheduled')} />

                                <FormattedDuration
                                    typographyProps={{ style: { ...theme.typography.pageSubtitle2 } }}
                                    startDateTime={selectedActivity.schedule.scheduledStartDateTime}
                                    endDateTime={selectedActivity.schedule.scheduledEndDateTime}
                                />
                            </div>

                            <Typography variant="subtitle2" style={{ marginBottom: 8 }}>
                                { t(`productTypes.text.${selectedActivity.details.activityType}`) }
                            </Typography>
                            <ActivityStatusChip status={selectedActivity.details.detailedStatus} />
                        </div>

                        { selectedActivity &&
                            <MainActivityLinks
                                activity={selectedActivity}
                                companyRole={selectedCompany.role}
                                isBilledRequestPending={isBilledRequestPending}
                                onLinksBtnClick={() => setLinksDialogIsOpen(true)}
                                onSetupBtnClick={() => setSetupDialogIsOpen(true)}
                                onViewStatusBtnClick={() => setParticipantStatusDialogIsOpen(true)}
                                onSetIsBilledButtonClick={() => toggleIsBilled()}
                            />
                        }
                    </div>

                    <div style={{ flexGrow: 1 }}>
                        <TranslatorAccessCodeWarning activity={selectedActivity} companyRole={companyRole} />
                        { routes() }
                    </div>
                </Box>
            </Container>

            <Delete
                open={deleteDialogIsOpen}
                activityId={activityGuid}
                projectId={projectGuid}
                onCancel={() => setDeleteDialogIsOpen(false)}
                onSuccess={() => {
                    navigate(`/company/${companyGuid}/project/${projectGuid}/activities`);
                }} />

            <ResponsiveDialog
                open={linksDialogIsOpen}
                onCancel={() => setLinksDialogIsOpen(false)}
                onClose={() => setLinksDialogIsOpen(false)}
            >
                <PortalIFrame
                    containerProps={{ disableGutters: true }}
                    id={'activityLinksIframe'}
                    relativeUrl={createLinksRelativeURL(ProductType[selectedActivity.details.activityType], projectGuid, activityGuid)} />
            </ResponsiveDialog>

            <ResponsiveDialog
                open={setupDialogIsOpen}
                onCancel={() => setSetupDialogIsOpen(false)}
            >
                <PortalIFrame
                    containerProps={{ disableGutters: true }}
                    id={'activitySetupIframe'}
                    onUrlChange={(href, event) => {
                        if (
                            href.indexOf('/FocusGroup/Summary/') >= 0 || // If we're returning to the summary, or...
                            href.indexOf('/FocusGroupReschedule/Notifications/') >= 0 // We've just rescheduled...
                        ) {
                            refetch(); // ...update the app state for this activity.
                        }
                    }}
                    relativeUrl={createSetupRelativeURL(ProductType[selectedActivity.details.activityType], projectGuid, activityGuid)} />
            </ResponsiveDialog>

            <ResponsiveDialog
                heading={t('activityPage.text.participantStatus')}
                open={participantStatusDialogIsOpen}
                onCancel={() => setParticipantStatusDialogIsOpen(false)}
                onClose={() => setParticipantStatusDialogIsOpen(false)}
            >
                <PortalIFrame
                    containerProps={{ disableGutters: true }}
                    id={'activityStatusIframe'}
                    relativeUrl={createStatusRelativeURL(ProductType[selectedActivity.details.activityType], projectGuid, activityGuid)} />
            </ResponsiveDialog>

            <PageMenu
                companyId={companyGuid}
                projectId={projectGuid}
                activityId={activityGuid}
                anchorEl={pageMenuAnchorEl}
                onClose={() => setPageMenuAnchorEl(null)}
                companyRole={selectedCompany?.role}
                status={selectedActivity?.details.detailedStatus}
                onDeleteButtonClick={() => {
                    setDeleteDialogIsOpen(true);
                    setPageMenuAnchorEl(null);
                }}
            />
        </div>
    );

    return (
        loading() || main()
    );
};

export default ActivityOverview;

import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import React from 'react';

/**
 * Used in combination with Mui sliders to make it easier to select a specific point in time on
 * a video.
 *
 * @param {*} props
 * @returns
 */
const TooltipValueLabelComponent = (props) => {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
};

TooltipValueLabelComponent.propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
    value: PropTypes.string
};

export default TooltipValueLabelComponent;

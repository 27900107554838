import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import useCurrentUserDetails from 'api/useCurrentUserDetails';

const useStyles = makeStyles(theme => ({
    ...theme.appBar.userAvatar
}));

/** Displays the initials of the currently logged in user. These are retrieved from the global redux state,
 *  and so this component must be contained within a suitable provider. */
const UserAvatar = (props) => {
    const classes = useStyles(props);
    const { userDetails } = useCurrentUserDetails();

    return (
        <Avatar className={classes.root} style={props.style} onClick={props.onClick}>
            <span style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                lineHeight: 'normal'
            }}>
                { userDetails?.firstName?.charAt(0) ?? '?' }{ userDetails?.lastName?.charAt(0) }
            </span>
        </Avatar>
    );
};

UserAvatar.propTypes = {
    /**
     * Handle click events.
     *
     * _Note: The cursor will switch to 'pointer' when this callback is provided._
     * */
    onClick: PropTypes.func,
    /** Override component styles */
    style: PropTypes.object
};

export default UserAvatar;

import { Tooltip } from '@mui/material';
import { Grade } from '@mui/icons-material';
import React, { useContext } from 'react';
import { ActivityRecordingContext } from './ActivityRecordingProvider';
import SquareIconButton from './SquareIconButton';

const ToggleHighlightsButton = () => {
    const {
        setHighlightsPaneIsOpen
    } = useContext(ActivityRecordingContext);

    return (
        <Tooltip title="Highlights" arrow>
            <SquareIconButton onClick={() => setHighlightsPaneIsOpen(true)}>
                <Grade />
            </SquareIconButton>
        </Tooltip>
    );
};

export default ToggleHighlightsButton;

import { Divider } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

export const BackgroundDivider = withStyles(theme => ({
    root: {
        backgroundColor: theme.dividers.backgroundDivider.backgroundColor
    }
}))(Divider);

export const ContentDivider = withStyles(theme => ({
    root: {
        backgroundColor: theme.dividers.contentDivider.backgroundColor
    }
}))(Divider);

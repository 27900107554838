import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api, { handleError } from '../../../utils/portalApi';
import { Box, FormControl, InputLabel, Select, MenuItem, Collapse } from '@mui/material';

const TimezonePicker = (props) => {
    const { i18n, t } = useTranslation();
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedTimezone, setSelectedTimezone] = useState('');

    const [countries, setCountries] = useState(null);
    const [timezones, setTimezones] = useState(null);

    const { onSelect } = props;
    const currentLanguage = i18n.language;

    useEffect(() => {
        api
            .get(`api/culture/countryNames?languageCode=${currentLanguage}`)
            .then((response) => setCountries(response.data))
            .catch(handleError);
    }, [currentLanguage]);

    useEffect(() => {
        let source = api.CancelToken.source();

        api
            .get(`api/culture/iana/timeZoneNames?countryCode=${selectedRegion}&languageCode=${currentLanguage}`)
            .then((response) => {
                setTimezones(response.data);

                if (Object.keys(response.data).length === 1) {
                    // Automatically choose the timezone if there is only
                    // one available for the selected country.
                    onSelect(Object.keys(response.data)[0]);
                }
            })
            .catch(handleError);

        return () => {
            source.cancel('Cancelled in useEffect cleanup');
        };
    }, [selectedRegion, currentLanguage, onSelect]);

    return (
        <Box style={{ padding: '0.5em' }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{ t('timezonePicker.labels.chooseCountry') }</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedRegion}
                    onChange={(e) => {
                        setTimezones(null);
                        setSelectedRegion(e.target.value);
                    }}
                >
                    { Object.entries(countries ?? {}).map(([key, value]) => {
                        return (<MenuItem key={key} value={key}>{value}</MenuItem>);
                    })}
                </Select>
            </FormControl>

            <Collapse in={Boolean(Object.keys(timezones ?? {}).length >= 2)}>
                <FormControl fullWidth style={{ marginTop: '1em' }}>
                    <InputLabel id="demo-simple-select-label">{ t('timezonePicker.labels.chooseTimezone') }</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedTimezone}
                        onChange={(e) => {
                            setSelectedTimezone(e.target.value);
                            props.onSelect(e.target.value);
                        }}
                    >
                        { Object.entries(timezones ?? {}).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                { value }
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Collapse>
        </Box>
    );
};

export const MemoisedTimezonePicker = React.memo(TimezonePicker);

export default TimezonePicker;

export * from './app.config';

export const apiUrl = 'https://portal.visionslive.com';
export const vlqrApiUrl = 'https://api.visionslive.com/VL';
export const clientUrl = 'https://client.visionslive.com';

export const releaseNotesUri = 'https://visionslive.canny.io/changelog';

export const growthbookApiKey = 'sdk-lB1qMJBZHZO1vYY';
export const growthbookSecretKey = 'zAnjJL3IiRw9nLqyYxR2yg==';

import { Button, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { releaseNotesUri } from 'Config';
import ReleaseNotesEntryList from './ReleaseNotesEntryList';
import { useTranslation } from 'react-i18next';


const ReleaseNotesViewPanel = () => {
    const { t } = useTranslation();
    const openReleaseNotes = () => window.open(releaseNotesUri, '_blank').focus();

    return <Stack maxWidth={400} spacing={1}>
        <Typography variant='h6' fontWeight='bold' align='center'>{t('userMenu.buttons.openReleaseNotes')}</Typography>
        <Divider />
        <ReleaseNotesEntryList />
        <Divider />
        <Button onClick={openReleaseNotes}>{t('topBar.releaseNotes.showAll')}</Button>
    </Stack>;
};

export default ReleaseNotesViewPanel;

import { Interval, DateTime, Duration } from 'luxon';

/**
 * Formats a Luxon duration object into a readable string. Includes 'hours'
 * only when necessary.
 *
 * @param {*} duration A Luxon Duration object
 * @returns a formatted duration string (e.g. 1:04:31, 03:21)
 */
const formatDuration = (duration) => {
    if (duration.as('hours') >= 1) {
        return duration.toFormat('h:mm:ss');
    } else {
        return duration.toFormat('mm:ss');
    }
};

export const getEventStartSeconds = (event, videoRecordingStartDate) => {
    const eventDateTime = DateTime.fromISO(event.sendDate);
    const startSeconds = Interval.fromDateTimes(videoRecordingStartDate, eventDateTime).toDuration('seconds');

    return startSeconds.as('seconds');
};

export const formatSeconds = (seconds) => {
    const duration = Duration.fromMillis(seconds * 1000);
    return formatDuration(duration);
};

export const parseSeconds = (timeString) => {
    const seconds = Duration.fromISOTime(timeString).as('seconds');

    if (isNaN(seconds)) {
        throw new Error('Invalid time format specified');
    }

    return seconds;
};

export const formatSecondsDuration = (durationSeconds) => {
    const duration = Duration.fromMillis(durationSeconds * 1000);
    return formatDuration(duration);
};

export const calcPlayedSeconds = (playedSeconds, newRecording, prevRecording, startSeconds) => {
    if (!prevRecording) {
        return startSeconds ?? 0;
    }

    const prevOffset = prevRecording.videoStartToSessionStartMillis / 1000;
    const newOffset = newRecording.videoStartToSessionStartMillis / 1000;

    return (playedSeconds - prevOffset) + newOffset;
};

export const getRecordingDisplayName = (recording, t) => {
    if (recording.translatedAudio) {
        return t('activityRecordingViewer.text.translatedRecording');
    }

    return t('activityRecordingViewer.text.originalRecording');
};

import { styled, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { LoadingPlaceholderFullPage } from 'components/shared/LoadingPlaceholderFullPage';
import useParticipantsForActivity from 'api-new/useParticipantsForActivity';
import useTextPromptAnswersForActivity from 'api-new/stim-based/useTextPromptAnswersForActivity';
import useStimulusActivity from 'api-new/stim-based/useStimulusActivity';
import { TextPromptActivityResultView } from 'vl-reporting-components';
import _ from 'lodash';
import { PrintOutlined, FileDownloadOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import { TextPromptActivityAnswersPrintable } from 'vl-reporting-components';
import { CSVLink } from 'react-csv';

const ActivityResultsContainer = styled('div')({
    minWidth: 0,
    '& .MuiTypography-subtitle2': {
        color: '#102A43',
        fontWeight: 500
    },
    '& .MuiTypography-h6': {
        fontWeight: 500
    },
    '& .MuiPaper-root': {
        borderColor: '#D9E2EC'
    }
});

const PrintButton = styled(Button)(({ theme }) => ({
    ...theme.viewReportButton
}));

const ExportButton = styled(Button)(({ theme }) => ({
    ...theme.viewReportButton,
    textDecoration: 'none !important'
}));

const TextPromptActivityResults = ({activityId, focusGroupId, isOpen, onClose}) => {
    const { data: activity, isLoading: isLoadingActivity } = useStimulusActivity(activityId);
    const { data: textPromptAnswers, isLoading: isLoadingTextPromptAnswers } = useTextPromptAnswersForActivity(activityId);
    const { data: participants, isLoading: isLoadingParticipants } = useParticipantsForActivity(focusGroupId);

    const { t } = useTranslation();

    let componentRef = useRef();

    if (isLoadingActivity || isLoadingTextPromptAnswers || isLoadingParticipants) {
        return <LoadingPlaceholderFullPage />;
    }

    // Format participant names so that they are ready for TextPromptActivityResultView component
    const participantNameMap = _.keyBy(participants, 'focusGroupUserId');

    // Reshape data for CSV export
    //  Note the CSVLink component does not escape double quotes as it should, so we need to do it manually.
    textPromptAnswers.sort((a, b) => {
        return new Date(a.createdDate) - new Date(b.createdDate);
    });

    const textPromptAnswersForCsv = textPromptAnswers.map((answer) => {
        const participant = participants.find((participant) => participant.focusGroupUserId === answer.createdByUserId);
        return [
            activity.title.replace(/"/g, '""'),
            activity.prompt.replace(/"/g, '""'),
            answer.createdDate,
            answer.textValue.replace(/"/g, '""'),
            participant.name.replace(/"/g, '""')
        ];
    });

    const csvHeaders = [t('projectPage.labels.activityName'), t('activityType.textPrompt.title'), t('common.timeSubmitted'), t('common.response'), t('common.participant')];

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth='xl'
            fullWidth
        >
            <DialogContent>
                <ActivityResultsContainer>
                    <TextPromptActivityResultView style={{marginBottom: 0}}
                        answers={textPromptAnswers}
                        participants={participantNameMap}
                        activity={activity}
                    />
                </ActivityResultsContainer>
            </DialogContent>

            <ActivityResultsContainer
                ref={componentRef}
                sx={{display: 'none', displayPrint: 'block', marginRight: '24px'}}>
                <TextPromptActivityAnswersPrintable
                    answers={textPromptAnswers}
                    participants={participantNameMap}
                    activity={activity}
                />
            </ActivityResultsContainer>

            <DialogActions>
                <Button onClick={onClose}>{ t('common.buttons.close') }</Button>

                <ReactToPrint
                    trigger={() =>
                        <PrintButton startIcon={<PrintOutlined />}>
                            {t('focusGroups.activities.print')}
                        </PrintButton>
                    }
                    content={() => componentRef.current}
                />

                <CSVLink
                    data={textPromptAnswersForCsv}
                    headers={csvHeaders}
                    filename={activity.title + '.csv'}
                    style={{ textDecoration: 'none' }}
                >
                    <ExportButton startIcon={<FileDownloadOutlined />}>
                        { t('focusGroups.activities.exportToCsv') }
                    </ExportButton>
                </CSVLink>
            </DialogActions>
        </Dialog>
    );
};

TextPromptActivityResults.propTypes = {
    activityId: PropTypes.string,
    focusGroupId: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};

export default TextPromptActivityResults;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FolderOpen } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import useProject from 'api/useProject';
import React from 'react';
import { useMatch } from 'react-router-dom';
import IconTab from './IconTab';

const ProjectIconTab = ({ companyId, projectId }) => {
    const { t } = useTranslation();
    const { data: project, isLoading: loading } = useProject(projectId);

    const routeIsActive = useMatch({
        path: '/company/:company_guid/project/:project_guid/:tab',
        end: true,
        exact: true
    });

    return (
        <Tooltip title={loading ? t('common.text.loading') : project?.name} arrow>
            <span>
                <IconTab
                    icon={<FolderOpen />}
                    link={`/company/${companyId}/project/${projectId}`}
                    isActive={Boolean(routeIsActive)}
                />
            </span>
        </Tooltip>
    );
};

ProjectIconTab.propTypes = {
    companyId: PropTypes.string,
    projectId: PropTypes.string
};

export default ProjectIconTab;

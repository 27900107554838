import { Box, styled } from '@mui/system';
import React, { useState } from 'react';
import ActivityType from 'enums/ActivityType';
import { Chip, Stack, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ActivitiesListItemSentimentOverview from './ActivitiesListItemSentimentOverview';
import ActivityResultsView from './ActivityResultsView';
import PollActivityVotesIndicator from './PollActivityVotesIndicator';
import PollActivityEngagementIndicator from './PollActivityEngagementIndicator';
import TextPromptActivityResponsesIndicator from './TextPromptActivityResponsesIndicator';
import ACTIVITY_TYPE from './Util';

const ActivitiesListItemBlock = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    marginBottom: -1,
    '&:first-child': {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    '&:last-child': {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4
    }
}));

const ReportButton = styled(Button)(({ theme }) => ({
    ...theme.viewReportButton
}));

const ActivitiesListItem = ({ activity, focusGroupId }) => {
    const { t } = useTranslation();
    const ActivityIcon = ActivityType[activity.activityType].icon;
    const hasRun = activity.focusGroupActivityRuns?.length > 0;
    const [reportOpen, setReportOpen] = useState(false);

    return (
        <React.Fragment>
            <ActivitiesListItemBlock>
                <Stack direction='row' gap={2} alignItems='center'>
                    <ActivityIcon />
                    <Stack direction='column' sx={{flexGrow: 1}}>
                        <Typography variant='subtitle1'>
                            {activity.title}
                            { !hasRun && <Chip label={t('focusGroups.activities.didNotRun')} size='small' variant='outlined' sx={{marginLeft: 1}} /> }
                        </Typography>
                        <Typography variant='subtitle2'>
                            {t(`focusGroups.activityTypes.${activity.activityType}`)}
                        </Typography>
                    </Stack>

                    { activity.activityType === ACTIVITY_TYPE.TEXT_PROMPT && hasRun &&
                        <TextPromptActivityResponsesIndicator activityId={activity.id} />
                    }

                    { activity.activityType === ACTIVITY_TYPE.POLL && hasRun &&
                        <>
                            <PollActivityVotesIndicator activityId={activity.id} />
                            <PollActivityEngagementIndicator activityId={activity.id} focusGroupId={focusGroupId} />
                        </>
                    }

                    { activity.activityType === ACTIVITY_TYPE.HEATMAP &&
                        <ActivitiesListItemSentimentOverview activityId={activity.id} />
                    }

                    <ReportButton
                        sx={{ flexShrink: 0 }}
                        variant="outlined"
                        onClick={() => setReportOpen(true)}
                    >
                        {t('focusGroups.activities.exploreResults')}
                    </ReportButton>
                </Stack>
            </ActivitiesListItemBlock>
            { reportOpen &&
                <ActivityResultsView
                    activity={activity}
                    focusGroupId={focusGroupId}
                    isOpen={reportOpen}
                    onClose={() => setReportOpen(false)}
                />
            }
        </React.Fragment>
    );
};

ActivitiesListItem.propTypes = {
    activity: PropTypes.object,
    focusGroupId: PropTypes.string
};

export default ActivitiesListItem;

import Axios from 'axios';
import { vlqrApiUrl } from '../Config';

import Store from '../redux/store';
import { setIsOffline, setApiUnavailable } from '../redux/actions';

// We need to implement history properly into the routing so that we can use it here for proper error handling
// For now we just log the issue to the browser and let the components display generic error messages

export const handleAuthenticationError = (error) => {
    fetch(`${vlqrApiUrl}/auth/isLoggedIn`, {        
        credentials: 'include'
    }).then((response) => {
        if (!response.ok) {
            console.log('There was an error authenticating with the API: ' + response);           

            // Return to current page after re-authenticating with VLQR API
            const url = encodeURIComponent(window.location.href);
            window.location.replace(`${vlqrApiUrl}/auth/login?returnUrl=${url}`);
        }
    });
};

export const handleUnavailableError = () => {
    Store.dispatch(setApiUnavailable(true));
};

const handleAuthorizationError = (error) => {
    console.log('There was an authorization error with the API: ' + error);
};

const handleCommsError = (error) => {
    Store.dispatch(setIsOffline(true));
};

const handleServerError = (error) => {
    if (error.response.status === 401) {
        handleAuthenticationError(error);
    } else if (error.response.status === 403) {
        handleAuthorizationError(error);
    } else {
        console.error(error);
    }
};

export const handleError = (error) => {
    if (error.response) {
        // Server responded with status code outside of 2xx range
        handleServerError(error);
    } else if (error.request) {
        // Request was made but no response was received

        // Check to see if we're offline
        if (window.navigator.onLine === false) {
            handleCommsError();
        } else {
            console.error(error);
        }
    } else {
        // Something happened in setting up the request that triggered an Error.
        console.error(error);
    }
};

const api = Axios.create({
    baseURL: vlqrApiUrl,
    withCredentials: true
});

api.CancelToken = Axios.CancelToken;
api.isCancel = Axios.isCancel;

export default api;
import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getRankingActvityOptions = async (activityId) => {
    const { data } = await vlqrApi.get(`focusGroupActivities/${activityId}/rankingOptions`)
        .catch((error) => {
            handleError(error);
        });
    return data;
};

const useRankingOptionsForActivity = (activityId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['rankingOptionsForActivity', activityId],
        () => getRankingActvityOptions(activityId)
    );

    return { data, refetch, isLoading, isError };
};

export default useRankingOptionsForActivity;
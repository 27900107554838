import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Container, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import ToolbarSpacer from 'components/shared/ToolbarSpacer';
import TiledHighlightsView from './TiledHighlightsView';
import Add from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import NoHighlightsPlaceholder from './NoHighlightsPlaceholder';
import CreateSessionHighlightModal from './CreateSessionHighlight/CreateSessionHighlightModal';
import HighlightModal from './HighlightModal';
import useSessionHighlights from '../useSessionHighlights';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 500,
        backgroundColor: '#102A43',
        visibility: props => props.isOpen ? 'visible' : 'hidden',
        opacity: props => props.isOpen ? 1 : 0,
        transition: 'opacity 400ms',
        color: 'white',
        overflow: 'auto',
        '& .MuiContainer-root': {
            paddingTop: 16,
            paddingBottom: 16
        }
    },
    topBar: {
        padding: 16,
        paddingLeft: 32,
        paddingRight: 32,
        display: 'flex',
        alignItems: 'center',
        '& .MuiButton-root': {
            backgroundColor: 'white',
            color: '#102A43'
        }
    }
}));

const HighlightsPane = ({ activityId, recordingId, videoRecordingUrl, isOpen, onCloseBtnClick }) => {
    const { t } = useTranslation();
    const { data: sessionHighlights, refetch: refetchSessionHighlights } = useSessionHighlights(activityId);

    const [isCreatingHighlight, setIsCreatingHighlight] = useState(false);
    const [selectedHighlight, setSelectedHighlight] = useState(null);

    const classes = useStyles({ isOpen });

    // Reload session highlights when the highlights pane is 'opened'
    useEffect(() => {
        if (isOpen === true) {
            refetchSessionHighlights();
        }
    }, [isOpen]);

    const renderHighlights = () => {
        if (sessionHighlights?.length > 0) {
            return (
                <TiledHighlightsView
                    highlights={sessionHighlights}
                    onSelectHighlight={(highlight) => setSelectedHighlight(highlight)}
                />
            );
        } else {
            return (
                <NoHighlightsPlaceholder onCreateHighlightBtnClick={() => setIsCreatingHighlight(true)} />
            );
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.topBar}>
                <Typography variant="h6">
                    { t('sessionHighlights.text.sessionHighlightsTitle') }
                </Typography>

                <ToolbarSpacer grow />

                { sessionHighlights?.length > 0 &&
                    <Button
                        variant="contained"
                        startIcon={<Add />}
                        onClick={() => setIsCreatingHighlight(true)}
                    >
                        { t('sessionHighlights.buttons.createHighlight') }
                    </Button>
                }

                <ToolbarSpacer large />

                <IconButton color="inherit" onClick={onCloseBtnClick} size="large">
                    <Close />
                </IconButton>
            </div>

            <Container maxWidth={'xl'}>
                { renderHighlights() }
            </Container>

            <CreateSessionHighlightModal
                recordingId={recordingId}
                isOpen={isCreatingHighlight}
                onClose={() => setIsCreatingHighlight(false)}
                videoRecordingUrl={videoRecordingUrl}
                onHighlightCreated={() => {
                    setIsCreatingHighlight(false);
                    refetchSessionHighlights();
                }}
            />

            { selectedHighlight &&
                <HighlightModal
                    activityId={activityId}
                    highlightId={selectedHighlight.id}
                    title={selectedHighlight.title}
                    description={selectedHighlight.description}
                    isOpen={Boolean(selectedHighlight)}
                    onClose={() => setSelectedHighlight(null)}
                    videoUri={selectedHighlight.videoUri}
                    onHighlightDeleted={() => {
                        setSelectedHighlight(null);
                        refetchSessionHighlights();
                    }}
                />
            }
        </div>
    );
};

HighlightsPane.propTypes = {
    activityId: PropTypes.string,
    isOpen: PropTypes.bool,
    onCloseBtnClick: PropTypes.func,
    onHighlightCreated: PropTypes.func,
    recordingId: PropTypes.string,
    videoRecordingUrl: PropTypes.string
};

export default HighlightsPane;

import { useQuery } from 'react-query';
import portalApi from 'utils/portalApi';

const getCompany = async (companyId) => {
    const { data } = await portalApi.get(`api/companies/${companyId}`);
    return data;
};

const useCompany = (companyId) => {
    const { data, refetch, isLoading } = useQuery(
        ['company', companyId],
        () => getCompany(companyId)
    );

    return { data, refetch, isLoading };
};

export default useCompany;

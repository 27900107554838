import React from 'react';
import ActivityAvatar from 'components/shared/ActivityAvatar';
import LocalisableDate from 'components/shared/LocalisableDate/LocalisableDate';
import { Typography, Link, useTheme, Button, Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Link as RouterLink } from 'react-router-dom';
import theme from 'themes/blue/theme';
import { useTranslation } from 'react-i18next';
import { ContentDivider } from 'components/shared/CustomDividers';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import UpdateIcon from '@mui/icons-material/Update';
import FormattedDuration from 'components/shared/FormattedDuration';
import { OpenInNew } from '@mui/icons-material';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { styled } from '@mui/system';

const ViewReportButton = withStyles(theme => ({
    root: {
        ...theme.viewReportButton
    }
}))(Button);

const RescheduleActivityButton = withStyles(theme => ({
    root: {
        ...theme.rescheduleActivityButton
    }
}))(Button);

const VLBilledIndicator = styled(Chip)({
    marginLeft: 12,
    marginBottom: 4,
    height: '28px',
    '& .MuiSvgIcon-root': {
        width: '0.8em',
        height: '0.8em'
    }
});

const ActivitySummary = ({ activity }) => {
    const { t } = useTranslation();
    const style = {
        ...theme.activitySummaryLabel.default,
        ...theme.activitySummaryLabel[activity.status]
    };

    return (
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <div>
                <Typography style={style}>{ t(`activityStatuses.text.${activity.status}`) }</Typography>
            </div>

            <ContentDivider orientation="vertical" flexItem style={{ marginLeft: 8, marginRight: 8 }} />

            <LocalisableDate
                date={activity.startDate}
                typographyProps={{ style: theme.activitySummaryLabel.default }}
                placeholderText={t('activityPage.text.unscheduled')}
            />

            <ContentDivider orientation="vertical" flexItem style={{ marginLeft: 8, marginRight: 8 }} />

            <FormattedDuration
                typographyProps={{ style: theme.activitySummaryLabel.default }}
                startDateTime={activity.startDate}
                endDateTime={activity.endDate}
            />
        </div>
    );
};

const ActivityTableRow = ({
    activity,
    companyId,
    projectId,
    companyRole,
    onViewReportClick,
    onRescheduleBtnClick
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <div className="VL-ActivityRow" style={{ display: 'flex', alignItems: 'center', padding: 16 }}>
            <ActivityAvatar
                productType={activity.productType}
                status={activity.status} />

            <div style={{ paddingLeft: 24, flexGrow: 1 }}>
                { companyRole.canViewActivityLinks ?
                    <Link
                        to={`/company/${companyId}/project/${projectId}/activity/${activity.activityId}`}
                        color="primary"
                        component={RouterLink}
                        variant="subtitle1"
                        underline="hover"
                        style={{ ...theme.typography.primaryItemLink }}
                        onClick={() => {
                            // Before opening activity, scroll to top of root so that title and other content is
                            // not scrolled out of view on the Activity View.
                            document.getElementById('VL-RootContainer').scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }}
                    >
                        { activity.name }
                    </Link> :
                    <span style={{ ...theme.typography.primaryItemLink }}>
                        { activity.name }
                    </span>
                }

                { (companyRole.canSetActivityBilled && activity.isBilled) &&
                    <VLBilledIndicator icon={<CreditScoreIcon />} label="Billed" color="primary" />
                }

                <ActivitySummary activity={activity} />
            </div>

            { activity.status === 'completed' &&
                <ViewReportButton
                    variant="outlined"
                    startIcon={<InsertChartOutlinedRoundedIcon />}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        // Optional function syntax
                        // eslint-disable-next-line no-unused-expressions
                        onViewReportClick?.();
                    }}>
                    <Typography variant="inherit" noWrap>{ t('activityPage.buttons.viewReport') }</Typography>
                </ViewReportButton>
            }

            { activity.status === 'didNotStart' && companyRole.canSetupActivity &&
                <RescheduleActivityButton
                    variant="outlined"
                    startIcon={<UpdateIcon />}
                    onClick={(e) => {
                        onRescheduleBtnClick();
                    }}>
                    <Typography variant="inherit" noWrap>{ t('activityPage.buttons.rescheduleActivity') }</Typography>
                </RescheduleActivityButton>
            }

            { activity.status !== 'completed' && !companyRole.canViewActivityLinks &&
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<OpenInNew />}
                    style={{ justifyContent: 'flex-start' }}
                    onClick={() => {
                        window.open(activity.moderatorLink, '_blank');
                    }}
                >
                    { activity.status === 'running' ? 'Join Session' : 'Edit Session' }
                </Button>
            }
        </div>
    );
};

export default React.memo(ActivityTableRow);

import PropTypes from 'prop-types';
import { useWindowSize } from '@react-hook/window-size';
import React, { useContext, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { ActivityRecordingContext } from './ActivityRecordingProvider';
import { TOOLBAR_HEIGHT, REACTIONS_PANE_WIDTH } from './ActivityRecordingViewer';

const useVideoDimensions = (maxWidth) => {
    useWindowSize();
    const { reactionsPaneIsOpen } = useContext(ActivityRecordingContext);

    const height = window.innerHeight - TOOLBAR_HEIGHT;
    let width = reactionsPaneIsOpen ?
        window.innerWidth - REACTIONS_PANE_WIDTH :
        window.innerWidth;
    
    if (maxWidth && maxWidth < width) {
        width = maxWidth;
    }

    // Dimensions that assume video will fill maximum available height
    var heightBasedDimensions = {
        height,
        width: (height / 9) * 16
    };

    // Dimensions that assume video will fill maximum available width
    var widthBasedDimensions = {
        height: (width / 16) * 9,
        width
    };

    return heightBasedDimensions.width < widthBasedDimensions.width ?
        heightBasedDimensions :
        widthBasedDimensions;
};

const VideoPlayer = ({ skipTo, maxWidth }) => {
    const {
        videoRecordingUrl,
        videoIsPlaying,
        videoPlayerRef,
        setVideoDuration,
        setVideoPlaybackProgress
    } = useContext(ActivityRecordingContext);

    const videoDimensions = useVideoDimensions(maxWidth);

    useEffect(() => {
        videoPlayerRef.current.seekTo(skipTo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skipTo]);

    return (
        <div style={{ height: videoDimensions.height, width: videoDimensions.width }}>
            <ReactPlayer
                ref={videoPlayerRef}
                url={videoRecordingUrl}
                width="100%"
                height="100%"
                playing={videoIsPlaying}
                volume={1}
                onDuration={(duration) => { setVideoDuration(duration); }}
                onProgress={(progress) => setVideoPlaybackProgress(progress)}
                progressInterval={500}
                style={{
                    maxHeight: '100%',
                    overflow: 'hidden'
                }}
            />
        </div>
    );
};

export default VideoPlayer;

VideoPlayer.propTypes = {
    skipTo: PropTypes.number,
    maxWidth: PropTypes.number
};

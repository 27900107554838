// Elapsed time view
// Indicates the current video time and the overall duration (e.g. "00:23 / 1:32:00")

import makeStyles from '@mui/styles/makeStyles';
import { Duration } from 'luxon';
import React, { useContext } from 'react';
import { ActivityRecordingContext } from './ActivityRecordingProvider';


const useStyles = makeStyles(theme => ({
    root: {
        color: 'white',
        fontSize: 12,
        '& .duration': {
            color: '#829AB1'
        }
    }
}));

const ElapsedTimeView = (props) => {
    const classes = useStyles();

    const {
        videoPlaybackProgress,
        videoDuration
    } = useContext(ActivityRecordingContext);

    const elapsedTime = Duration.fromMillis(videoPlaybackProgress.playedSeconds * 1000);
    const formattedElapsedTime = elapsedTime.as('hours') > 1 ?
        elapsedTime.toFormat('hh:mm:ss') :
        elapsedTime.toFormat('mm:ss');

    const duration = Duration.fromMillis(videoDuration * 1000);
    const formattedDuration = duration.as('hours') > 1 ?
        duration.toFormat('hh:mm:ss') :
        duration.toFormat('mm:ss');

    return (
        <div className={classes.root} {...props}>
            <span className="elapsedTime">{ formattedElapsedTime } </span>
            <span className="duration">/ { formattedDuration }</span>
        </div>
    );
};

export default ElapsedTimeView;

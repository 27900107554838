import { useQuery } from 'react-query';
import portalApi from 'utils/portalApi';

const getProject = async (projectId) => {
    const { data } = await portalApi.get(`api/projects/${projectId}`);
    return data;
};

const useProject = (projectId) => {
    const { data, refetch, isLoading } = useQuery(
        ['project', projectId],
        () => getProject(projectId)
    );

    return { data, refetch, isLoading };
};

export default useProject;

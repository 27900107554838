import { useQuery } from 'react-query';
import portalApi from 'utils/portalApi';

const getActivityActualStartTime = async (activityId) => {
    const { data } = await portalApi.get(`api/activity/${activityId}/actualStartTime`);
    return data;
};

const useActivityActualStartTime = (activityId) => {
    const { data, refetch, isLoading } = useQuery(
        ['activityStartTime', activityId],
        () => getActivityActualStartTime(activityId),
        {
            staleTime: 2000
        }
    );

    return { data, refetch, isLoading };
};

export default useActivityActualStartTime;

import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, Dialog, Slide, useTheme, DialogTitle, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});
Transition.displayName = 'Transition';

const ResponsiveDialog = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog fullScreen={isMobile} TransitionComponent={Transition} maxWidth={'xl'} {...props}>
            <Toolbar
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '1em'
                }}
                disableGutters
            >
                <DialogTitle><Typography variant="subtitle1">{ props.heading }</Typography></DialogTitle>
                <IconButton color="inherit" onClick={props.onCancel} size="large">
                    <CloseIcon />
                </IconButton>
            </Toolbar>

            { props.children }
        </Dialog>
    );
};

ResponsiveDialog.propTypes = {
    heading: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    children: PropTypes.node
};

export default ResponsiveDialog;

export const ERROR_CODES = {
    NULL_CLIP_START: 'NULL_CLIP_START',
    NULL_CLIP_END: 'NULL_CLIP_END',
    NEGATIVE_CLIP_TIME: 'NEGATIVE_CLIP_TIME',
    TITLE_TOO_LONG: 'TITLE_TOO_LONG',
    DESCRIPTION_TOO_LONG: 'DESCRIPTION_TOO_LONG'
};

export const validate = (clipStartTime, clipEndTime, highlightTitle, highlightDescription) => {
    const errors = [];

    if (clipStartTime === null) {
        errors.push(ERROR_CODES.NULL_CLIP_START);
    }

    if (clipEndTime === null) {
        errors.push(ERROR_CODES.NULL_CLIP_END);
    }

    if (clipStartTime >= clipEndTime) {
        errors.push(ERROR_CODES.NEGATIVE_CLIP_TIME);
    }

    console.log(highlightTitle, highlightDescription);
    if (highlightTitle !== null && highlightTitle.length > 64) {
        errors.push(ERROR_CODES.TITLE_TOO_LONG);
    }

    if (highlightDescription !== null && highlightDescription.length > 512) {
        errors.push(ERROR_CODES.DESCRIPTION_TOO_LONG);
    }

    return errors;
};

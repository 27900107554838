import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@mui/material';
import useActivity from 'api/useActivity';
import PortalIFrame from 'components/shared/PortalIFrame';
import ProductType, { AVSessionUmbrella } from 'enums/ProductType';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FOCUS_GROUPS_REPORTS_AREAS, createFocusGroupsReportsAreaRelativeURL } from 'utils/activityUrlHelper';
import ActivitiesTabPanel from './activities/ActivitiesTabPanel';
import { Navigate } from 'react-router-dom';
import useActivityRecordings from 'api/useActivityRecordings';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import RecordingsTabPanel from './recordings/RecordingsTabPanel';
import VoiceTranscriptionsReport from './VoiceTranscriptionsReport/VoiceTranscriptionsReport';
import AIReportsPanel from './AIReportsPanel/AIReportsPanel';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const StimulusBasedFocusGroupReportsPanel = ({ projectId, activityId: focusGroupId }) => {
    const { t } = useTranslation();
    const { data: activity, isLoadingActivity } = useActivity(focusGroupId);
    const { data: recordings, isLoadingRecordings } = useActivityRecordings(focusGroupId);
    const isLoading = isLoadingActivity || isLoadingRecordings;
    const aiReportsFeatureEnabled = useFeatureIsOn('ai-session-summaries');
    const [selectedTab, setSelectedTab] = useState(aiReportsFeatureEnabled ? 0 : 1);

    const voiceTranscriptionsTabUri = createFocusGroupsReportsAreaRelativeURL(ProductType[activity?.details?.activityType], projectId, focusGroupId, FOCUS_GROUPS_REPORTS_AREAS.VoiceTranscriptions);

    if (!focusGroupId){
        return null;
    } else if (isLoading) {
        return <LoadingPlaceholder height={'auto'} />;
    }

    const activityType = ProductType[activity?.details?.activityType];
    const isAVSession = AVSessionUmbrella.includes(activityType);
    const chatTabUri = createFocusGroupsReportsAreaRelativeURL(activityType, projectId, focusGroupId, FOCUS_GROUPS_REPORTS_AREAS.Details);

    return <Box>
        <Tabs
            onChange={(e, value) => setSelectedTab(value)}
            value={selectedTab}
        >
            { aiReportsFeatureEnabled && <Tab value={0} label={'AI Reports'} /> }
            <Tab value={1} label={t('reporting.tabs.participationAndChat')}  />
            <Tab value={2} label={t('reporting.tabs.activityResults')} />
            {isAVSession && <Tab value={3} label={t('reporting.tabs.textAnalysis')} /> }
            { recordings?.length > 0 && <Tab value={4} label={t('reporting.tabs.recordings')} /> }
            <Tab value={5} label={t('reporting.tabs.voiceTranscriptions')} />
        </Tabs>
        {selectedTab === 0 && <AIReportsPanel focusGroupId={focusGroupId} />}
        {selectedTab === 1 && <Box value={1} sx={{ padding: 0 }}>
            <PortalIFrame
                containerProps={{ disableGutters: true }}
                id={'activityReportsIframe'}
                frameInfo={{hideReportTabs: true}}
                relativeUrl={chatTabUri} />
        </Box> }
        {selectedTab === 2 && <ActivitiesTabPanel value={2} focusGroupId={focusGroupId} /> }
        {selectedTab === 3 && <Navigate to={`/activity/${focusGroupId}/textAnalysis`} /> }
        {selectedTab === 4 && <RecordingsTabPanel focusGroupId={focusGroupId} /> }
        {selectedTab === 5 && <Box value={5} sx={{ padding: 0 }}>
            <VoiceTranscriptionsReport
                focusGroupId={focusGroupId}
                voiceTranscriptionsTabUri={voiceTranscriptionsTabUri}
            />
        </Box> }
    </Box>;
};

StimulusBasedFocusGroupReportsPanel.propTypes = {
    projectId: PropTypes.string,
    activityId: PropTypes.string
};

export default StimulusBasedFocusGroupReportsPanel;

import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import useAiSummaryReport from 'api-new/stim-based/useAiSummaryReport';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import ValChip from './ValChip';
import { useTranslation } from 'react-i18next';

const AISummaryReport = ({ aiSummaryReportId, reportType }) => {
    const { t } = useTranslation();
    const { data: aiSummaryReport, isLoading } = useAiSummaryReport(aiSummaryReportId);

    if (isLoading) {
        return (
            <Stack gap={1}>
                <Typography variant="h6">
                    { t(`aiSystemInstructionType.${reportType}.reportTitle`) }
                </Typography>

                <Box sx={{ px: 2, py: 2, border: 'thin solid black', borderColor: 'grey.9', borderRadius: 2 }}>
                    <LoadingPlaceholder />
                </Box>
            </Stack>
        );
    }

    const { content } = aiSummaryReport;

    return (
        <Stack gap={1}>
            <Stack direction='row' alignItems='center' sx={{ backgroundColor: 'grey.10', padding: 1.5, borderRadius: 1 }}>
                <Stack direction='row' alignItems='center' gap={1} sx={{ flexGrow: 1 }}>
                    <Typography variant="overline" sx={{ fontWeight: '500', lineHeight: 'normal' }}>
                        { t(`aiSystemInstructionType.${reportType}.reportTitle`) }
                    </Typography>

                    <ValChip />
                </Stack>
            </Stack>

            <Box>
                <Box
                    sx={{
                        '& ul': {
                            paddingInlineEnd: '40px',
                            '& li': { marginBottom: 1 }
                        }
                    }}
                >
                    <Markdown>
                        { content }
                    </Markdown>
                </Box>
            </Box>
        </Stack>
    );
};

AISummaryReport.propTypes = {
    aiSummaryReportId: PropTypes.string,
    reportType: PropTypes.string
};

export default AISummaryReport;

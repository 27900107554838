import PropTypes from 'prop-types';
import React from 'react';
import { LoadingPlaceholderFullPage } from 'components/shared/LoadingPlaceholderFullPage';
import usePollAnswersForActivity from 'api-new/stim-based/usePollAnswersForActivity';
import { PollActivityVotesIndicator as RCPollActivityVotesIndicator } from 'vl-reporting-components';

const PollActivityVotesIndicator = ({activityId}) => {
    const { data: pollAnswers, isLoading: isLoadingPollAnswers } = usePollAnswersForActivity(activityId);

    if (isLoadingPollAnswers) {
        return <LoadingPlaceholderFullPage />;
    }

    return (
        <RCPollActivityVotesIndicator
            answers={pollAnswers}
        />
    );
};

PollActivityVotesIndicator.propTypes = {
    activityId: PropTypes.string,
    focusGroupId: PropTypes.string
};

export default PollActivityVotesIndicator;

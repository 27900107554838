import React from 'react';
import { AudioFileOutlined, VideoFileOutlined } from '@mui/icons-material';
import RecordingType from 'enums/RecordingType';
import PropTypes from 'prop-types';

const RecordingListIcon = ({ type }) => {
    if (type === RecordingType.Audio) {
        return <AudioFileOutlined />;
    } else {
        return <VideoFileOutlined />;
    }
};

RecordingListIcon.propTypes = {
    type: PropTypes.instanceOf(RecordingType)
};

export default RecordingListIcon;

import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';
import { defaultWordFilter } from 'pages/TextAnalysisDashboard/WordFilter';

const useWordCloudFilterForActivity = (activityId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['wordCloudFilter', activityId],
        () => getWordCloudFilter(activityId)
    );

    return { data, refetch, isLoading, isError };
};

const getWordCloudFilter = async (activityId) => {
    const { data } = await vlqrApi.get(`TextAnalysis/${activityId}/WordCloudFilter`)
        .catch((error) => {
            handleError(error);
        });

    const filter = { id: 'default', filter: defaultWordFilter };
    if (data && data !== '')
    {
        filter.id = data.id;
        filter.filter = data.filter;
    }

    return filter;
};

export default useWordCloudFilterForActivity;
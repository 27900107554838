import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Typography, Popover, Link, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';
import TimezonePreviewer from './TimezonePreviewer';
import useCurrentUserDetails from 'api/useCurrentUserDetails';

const dateFormat = { month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric', hourCycle: 'h23' };
const dateFormatWithYear = { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric', hourCycle: 'h23' };

const LocalisableDate = (props) => {
    const theme = useTheme();
    const { i18n } = useTranslation();
    const { userDetails } = useCurrentUserDetails();
    const selectedTimezone = userDetails?.ianaTimezoneId;
    const [anchorEl, setAnchorEl] = useState(null);

    const typographyProps = {
        style: {
            ...theme.activitySummaryLabel.default,
            cursor: 'pointer'
        },
        ...props.typographyProps
    };

    const localTimezone = DateTime.local().zoneName;
    const theDate = DateTime
        .fromISO(props.date, { locale: i18n.language })
        .setZone(selectedTimezone ?? 'local');

    const timezonesDiffer = () => {
        return theDate.zoneName !== localTimezone;
    };

    const renderWithDate = () => (
        <div>
            <Link {...typographyProps} onClick={(e) => setAnchorEl(e.currentTarget.parentElement)} underline="hover">
                { timezonesDiffer() ?
                    theDate.toLocaleString(DateTime.DATETIME_FULL) :
                    theDate.toLocaleString(
                        theDate.year === DateTime.local().year ?
                            dateFormat :
                            dateFormatWithYear
                    ) }
            </Link>

            <Popover
                modifiers={{ arrow: { enabled: true } }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => { setAnchorEl(null); }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                onClick={(e) => {
                    // Needed to handle click events properly when being shown in
                    // the table views.
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <TimezonePreviewer referenceDate={theDate} />
            </Popover>
        </div>
    );

    return (
        props.date ? renderWithDate() : <Typography {...typographyProps}>{ props.placeholderText ?? '...' }</Typography>
    );
};

LocalisableDate.propTypes = {
    date: PropTypes.string,
    typographyProps: PropTypes.object
};

export default LocalisableDate;

import PropTypes from 'prop-types';
import { Slider, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityRecordingContext } from './ActivityRecordingProvider';
import getSentimentIcon from './SentimentIcons/getSentimentIcon';
import CreateSessionHighlightModal from './SessionHighlights/CreateSessionHighlight/CreateSessionHighlightModal';
import TooltipValueLabelComponent from './TooltipValueLabelComponent';
import { formatSeconds, getEventStartSeconds } from './util';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: 24,
        paddingRight: 24,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 6,
        '& .MuiSlider-root': {
            marginTop: -4,
            '& .MuiSlider-rail': {
                height: 5,
                borderRadius: 4,
                backgroundColor: '#BCCCDC'
            },
            '& .MuiSlider-track': {
                height: 5,
                borderRadius: 4,
                backgroundColor: '#486581'
            },
            '& .MuiSlider-thumb': {
                height: 15,
                width: 15,
                borderRadius: 12,
                marginLeft: -7,
                backgroundColor: '#243B53'
            }
        },
        // Only show the AddHighlightMarker when the timeline area is hovered.
        '&:hover': {
            '& .VL-AddHighlightMarker': {
                visibility: 'visible',
                opacity: 1
            }
        }
    }
}));

const useMarkerStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 0,
        backgroundColor: '#243B53',
        padding: '6px 4px',
        marginLeft: -15,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& svg': {
            height: 20
        }
    },
    addHighlightMarker: {
        visibility: 'hidden',
        opacity: 0,
        transition: 'opacity 300ms',
        position: 'absolute',
        top: 0,
        marginLeft: -15 + -7, // Offsets: 16px for centering, 7px for container padding
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& svg': {
            height: 20
        },
        padding: '6px 4px',
        backgroundColor: '#6BB75A',
        borderRadius: 32,
        color: 'white',
        zIndex: 999,
        '& .AddHighlightMarker-Arrow': {
            position: 'absolute',
            bottom: -3,
            left: '50%',
            marginLeft: -4,
            width: 0,
            height: 0,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            borderTop: '4px solid #6BB75A'
        }
    }
}));

const AddHighlightMarker = (props) => {
    const { t } = useTranslation();
    const classes = useMarkerStyles();
    const { videoPlaybackProgress, videoDuration } = useContext(ActivityRecordingContext);
    const start = (1 / videoDuration) * videoPlaybackProgress.playedSeconds;

    return (
        <Tooltip title={t('sessionHighlights.buttons.createHighlight')}>
            <div
                className={`${classes.addHighlightMarker} VL-AddHighlightMarker`}
                onClick={props.onClick}
                key={'VL-EventsTimeline-Add-Highlight-Marker'}
                style={{
                    left: `${start * 100}%`
                }}
            >
                <AddIcon />

                <div className="AddHighlightMarker-Arrow" />
            </div>
        </Tooltip>
    );
};

AddHighlightMarker.propTypes = {
    onClick: PropTypes.func
};

const EventMarker = (props) => {
    const classes = useMarkerStyles();
    const { event, visicamStartDate, videoDuration } = props;
    const eventData = JSON.parse(event.data);
    const startSeconds = getEventStartSeconds(event, visicamStartDate);

    // Protect against negative durations (this would include comments added before the session started).
    if (isNaN(startSeconds)) {
        return null;
    }

    const start = (1 / videoDuration) * startSeconds;

    return (
        <div
            className={classes.root}
            onClick={props.onClick}
            key={event.id}
            style={{
                left: `${start * 100}%`
            }}
        >
            { getSentimentIcon(eventData.Sentiment) }

            <div
                style={{
                    position: 'absolute',
                    bottom: -4,
                    left: '50%',
                    marginLeft: -4,
                    width: 0,
                    height: 0,
                    borderLeft: '4px solid transparent',
                    borderRight: '4px solid transparent',
                    borderTop: '4px solid #243B53'
                }}
            />
        </div>
    );
};

EventMarker.propTypes = {
    event: PropTypes.object,
    onClick: PropTypes.func,
    videoDuration: PropTypes.number,
    visicamStartDate: PropTypes.instanceOf(DateTime)
};

const EventMarkers = () => {
    const { t } = useTranslation();
    const { noteEvents, videoRecordingStartDate, videoDuration, skipToEvent } = useContext(ActivityRecordingContext);

    if (!videoRecordingStartDate || !videoDuration) {
        return null;
    }

    const renderEventMarkers = () => (
        noteEvents.map(e => (
            <EventMarker
                key={e.id}
                event={e}
                visicamStartDate={videoRecordingStartDate}
                videoDuration={videoDuration}
                onClick={() => skipToEvent(e.id)}
            />
        ))
    );

    const noEventsPlaceholder = () => {
        if (noteEvents.length === 0) {
            return <Typography variant="overline">{ t('activityRecordingViewer.text.noEventsPlaceholder')}</Typography>;
        } else {
            return null;
        }
    };

    return (
        <React.Fragment>
            { noEventsPlaceholder() || renderEventMarkers() }
        </React.Fragment>
    );
};

const EventsTimeline = ({ showReactionFeatures }) => {
    const classes = useStyles();
    const {
        recordingId,
        videoRecordingUrl,
        videoPlayerRef,
        videoPlaybackProgress,
        videoDuration,
        setVideoIsPlaying,
        clipStartTime,
        clipDuration
    } = useContext(ActivityRecordingContext);

    const [isCreatingSessionHighlight, setIsCreatingSessionHighlight] = useState(false);

    // Temp slider value to use when seeking using the timeline. Ensures that videoPlaybackProgress
    // doesn't interfere when video is playing.
    const [tempSeek, setTempSeek] = useState(null);

    const clipStart = clipStartTime ?? videoPlaybackProgress.playedSeconds;
    const clipEnd = clipDuration ? clipStart + clipDuration : null;

    return (
        <div className={classes.root}>
            <div style={{ position: 'relative', height: 36 }}>
                { showReactionFeatures && <EventMarkers /> }

                <AddHighlightMarker
                    onClick={() => {
                        setVideoIsPlaying(false);
                        setIsCreatingSessionHighlight(true);
                    }}
                />
            </div>

            <Slider
                value={tempSeek ?? videoPlaybackProgress.playedSeconds}
                min={0}
                max={videoDuration}
                step={1}
                onChange={(event, value) => {
                    setTempSeek(value);
                }}
                onChangeCommitted={(event, value) => {
                    videoPlayerRef.current.seekTo(value);
                    setTimeout(() => setTempSeek(null), 300);
                }}
                valueLabelDisplay={'auto'}
                valueLabelFormat={(x) => {
                    return formatSeconds(x);
                }}
                ValueLabelComponent={TooltipValueLabelComponent}
            />

            <CreateSessionHighlightModal
                recordingId={recordingId}
                isOpen={isCreatingSessionHighlight}
                onClose={() => setIsCreatingSessionHighlight(false)}
                videoRecordingUrl={videoRecordingUrl}
                clipStartTime={clipStart}
                clipEndTime={clipEnd}
                onHighlightCreated={() => setIsCreatingSessionHighlight(false)}
            />
        </div>
    );
};

EventsTimeline.propTypes = {
    showReactionFeatures: PropTypes.bool
};

export default EventsTimeline;

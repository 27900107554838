import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell } from 'recharts';
import makeStyles from '@mui/styles/makeStyles';
import ResizeChartContainer from './ResizeChartContainer';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    customTooltip: {
        width: '140px',
        margin: 0,
        lineHeight: '24px',
        border: '1px solid #f5f5f5',
        backgroundColor: '#ffffff',
        padding: '5px'
    }
}));

const colors = [
    '#288C45',
    '#2680C2',
    '#D34D51'
];

const CustomTooltip = ({ active, payload, label }) => {
    const classes = useStyles();

    if (active && payload && payload.length) {
        return (
            <div className={classes.customTooltip}>
                <p className="label">{`${label} : ${payload[0].value.toFixed()}%`}</p>
            </div>
        );
    }
  
    return null;
};

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.string
};

const BarChartMargins = {
    top: 20,
    right: 30,
    left: 20,
    bottom: 5,
};

const defaultSize = { width: 700, height: 550 };

export const OverallSentimentChart = ({data, onBarClick}) => {
    const { t } = useTranslation();
    const [chartSize, setChartSize] = useState(defaultSize);

    const YAxisLabel = {
        value: t('textAnalysisDashboard.labels.overallSentimentYAxis'), 
        angle: -90, 
        position: 'insideLeft'
    };

    return (
        <ResizeChartContainer title={t('textAnalysisDashboard.labels.overallSentimentTitle')} chartSize={defaultSize} scalar={1.5} setChartSize={setChartSize}>
            <BarChart
                width={chartSize.width}
                height={chartSize.height}
                data={data}
                margin={BarChartMargins}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis label={YAxisLabel} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="score" fill="#8884d8" onClick={onBarClick}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                    ))}
                </Bar>
            </BarChart>
        </ResizeChartContainer>
    );
};

OverallSentimentChart.propTypes = {
    data: PropTypes.array,
    onBarClick: PropTypes.func
};

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.string
};

export default OverallSentimentChart;

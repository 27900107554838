import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getFocusGroupSchedule = async (focusGroupId) => {
    const { data } = await vlqrApi.get(`focusgroups/${focusGroupId}/schedule`)
        .catch((error) => {
            handleError(error);
        });
    return data;
};

const useFocusGroupSchedule = (activityId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['focusGroupSchedule', activityId],
        () => getFocusGroupSchedule(activityId)
    );

    return { data, refetch, isLoading, isError };
};

export default useFocusGroupSchedule;

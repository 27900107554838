import { useQuery } from 'react-query';
import portalApi from 'utils/portalApi';

const getActivity = async (activityId) => {
    const { data } = await portalApi.get(`api/activity/${activityId}`);
    return data;
};

const useActivity = (activityId) => {
    const { data, refetch, isLoading } = useQuery(
        ['activity', activityId],
        () => getActivity(activityId)
    );

    return { data, refetch, isLoading };
};

export default useActivity;

export const SET_WAITING_SERVICE_WORKER = 'SET_WAITING_SERVICE_WORKER';
export const SET_IS_OFFLINE = 'SET_IS_OFFLINE';
export const SET_THEME = 'SET_THEME';
export const ADD_PREVIEW_TIMEZONE = 'ADD_PREVIEW_TIMEZONE';
export const REMOVE_PREVIEW_TIMEZONE = 'REMOVE_PREVIEW_TIMEZONE';
export const SET_COMPANY_COUNT = 'SET_COMPANY_COUNT';
export const SET_API_UNAVAILABLE = 'SET_API_UNAVAILABLE';

export function setCompanyCount (companyCount) {
    return { type: SET_COMPANY_COUNT, companyCount };
}

export function setWaitingServiceWorker (serviceWorker) {
    return { type: SET_WAITING_SERVICE_WORKER, serviceWorker };
}

export function setIsOffline (isOffline) {
    return { type: SET_IS_OFFLINE, isOffline };
}

export function setTheme (theme) {
    return { type: SET_THEME, theme };
}

export function addPreviewTimezone (timezone) {
    return { type: ADD_PREVIEW_TIMEZONE, timezone };
}

export function removePreviewTimezone (timezone) {
    return { type: REMOVE_PREVIEW_TIMEZONE, timezone };
}

export function setApiUnavailable (apiUnavailable) {
    return { type: SET_API_UNAVAILABLE, apiUnavailable };
}
import { Box } from '@mui/material';

import { styled } from '@mui/system';

const PageButtonsContainer = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 32,
    padding: '0px 8px',
    '& .MuiIconButton-root': {
        padding: 8
    },
    '& .MuiDivider-root': {
        marginLeft: 4,
        marginRight: 4
    },
    ...theme.pageButtonsContainer
}));

export default PageButtonsContainer;

import React, { useEffect } from 'react';
import {
    Link as RouterLink,
    useParams
} from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import LoadingPlaceholder from './LoadingPlaceholder';
import CurrentTimezoneIndicator from './CurrentTimezoneIndicator';
import { Container, Link } from '@mui/material';

import useCurrentUserDetails from '../../api/useCurrentUserDetails';
import { useGrowthBook } from '@growthbook/growthbook-react';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        minHeight: '100vh',
        overflowY: 'scroll'
    },
    versionNumber: {
        fontSize: '0.875rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
}));


/** Lays out the "full" page - ie: including menu bars etc. */
const MainPageLayout = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { isLoading: isLoadingUserDetails, userDetails } = useCurrentUserDetails();
    const { company_guid: companyId } = useParams();
    const growthbook = useGrowthBook();

    useEffect(() => {
        growthbook?.setAttributes({
            UserId: !isLoadingUserDetails && userDetails?.userId?.toUpperCase(),
            CompanyId: companyId?.toUpperCase()
        });
    }, [growthbook, companyId, isLoadingUserDetails, userDetails?.userId]);

    const loading = () => {
        return isLoadingUserDetails ? <LoadingPlaceholder /> : null;
    };

    return <div id="VL-RootContainer" className={classes.root}>
        <main>
            { loading() || <Outlet /> }
        </main>

        <Container>
            <div style={{ ...theme.footer }}>
                <CurrentTimezoneIndicator />

                <div style={{ padding: 4 }}>
                    <Link component={RouterLink} to={'/about'} underline="hover" className={classes.versionNumber}>
                        VisionsLive Portal v{ process.env.REACT_APP_VERSION }
                    </Link>
                </div>
            </div>
        </Container>
    </div>;
};

export default MainPageLayout;
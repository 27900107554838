import { useQuery } from 'react-query';
import vlqrApi from 'utils/vlqrApi';

const getActivityRecordings = async (activityId) => {
    const { data } = await vlqrApi.get(`Reports/${activityId}/Recordings`);
    return data;
};

const useActivityRecordings = (activityId) => {
    const { data, refetch, isLoading } = useQuery(
        ['activityRecordings', activityId],
        () => getActivityRecordings(activityId)
    );

    return { data, refetch, isLoading };
};

export default useActivityRecordings;

import { useQuery } from 'react-query';
import portalApi from 'utils/portalApi';

const getActivityEventLog = async (activityId) => {
    const { data } = await portalApi.get(`api/activity/${activityId}/eventLog`);
    return data;
};

const useActivityEventLog = (activityId) => {
    const { data, refetch, isLoading } = useQuery(
        ['activityEventLog', activityId],
        () => getActivityEventLog(activityId)
    );

    return { data, refetch, isLoading };
};

export default useActivityEventLog;

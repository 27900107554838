import { Enumify } from 'enumify';

class CompanyRole extends Enumify {
    static administrator = new CompanyRole({
        canCreateProject: true,
        canDeleteProject: true,
        canManageProjectUsers: true,
        canCreateActivity: true,
        canSetupActivity: true,
        canViewActivityLinks: true,
        canDeleteActivity: true,
        canViewActivityReports: true,
        canManageUsers: true,
        canManageUsageAndBilling: true,
        canManageSettings: true
    });

    static vlStaff = new CompanyRole({
        canCreateProject: true,
        canDeleteProject: true,
        canManageProjectUsers: true,
        canCreateActivity: true,
        canSetupActivity: true,
        canViewActivityLinks: true,
        canDeleteActivity: true,
        canViewActivityReports: true,
        canManageUsers: true,
        canManageUsageAndBilling: true,
        canManageSettings: true,
        canAlwaysViewActivityLinks: true,
        canSetActivityBilled: true
    });

    static moderator = new CompanyRole({
        canCreateProject: true,
        canDeleteProject: true,
        canManageProjectUsers: true,
        canCreateActivity: true,
        canSetupActivity: true,
        canViewActivityLinks: true,
        canDeleteActivity: true,
        canViewActivityReports: true
    });

    static externalModerator = new CompanyRole({
        canCreateProject: false,
        canDeleteProject: false,
        canManageProjectUsers: false,
        canCreateActivity: false,
        canSetupActivity: false,
        canViewActivityLinks: false,
        canDeleteActivity: false,
        canViewActivityReports: false
    });

    static _ = this.closeEnum();

    constructor (options) {
        super();

        this.canCreateProject = options?.canCreateProject;
        this.canDeleteProject = options?.canDeleteProject;
        this.canManageUsers = options?.canManageUsers;
        this.canManageUsageAndBilling = options?.canManageUsageAndBilling;
        this.canManageSettings = options?.canManageSettings;

        this.canManageProjectUsers = options?.canManageProjectUsers;
        this.canCreateActivity = options?.canCreateActivity;
        this.canSetupActivity = options?.canSetupActivity;
        this.canViewActivityLinks = options?.canViewActivityLinks;
        this.canDeleteActivity = options?.canDeleteActivity;
        this.canViewActivityReports = options?.canViewActivityReports;

        // Normally, activity links are shown/hidden depending on the Activity status.
        // This allows (staff) roles to always view the links regardless of Activity Status.
        this.canAlwaysViewActivityLinks = options?.canAlwaysViewActivityLinks;
        this.canSetActivityBilled = options?.canSetActivityBilled;
    }
}

export default CompanyRole;

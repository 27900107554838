import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

export const NoActivitiesFound = (props) => {
    const { t } = useTranslation();

    return (
        <EmptyActivitiesPlaceholder
            title={t('companyPage.text.noActivitiesFound')}
            message={t('companyPage.text.noActivitiesFoundMessage')}
            onCreateActivityButtonClick={props.onCreateActivityButtonClick}
        />
    );
};

export const NoActiveActivities = (props) => {
    const { t } = useTranslation();

    return (
        <EmptyActivitiesPlaceholder
            title={t('companyPage.text.noActiveActivities')}
            message={t('companyPage.text.noActiveActivitiesMessage')}
            onCreateActivityButtonClick={props.onCreateActivityButtonClick} />
    );
};

const EmptyActivitiesPlaceholder = (props) => {
    const { t } = useTranslation();

    return (
        <Paper style={{ padding: '4em' }} variant="outlined">
            <div style={{ paddingBottom: '1.5em' }}>
                <Typography variant="h5" gutterBottom>{ props.title }</Typography>
                <Typography variant="body1">{ props.message }</Typography>
            </div>

            <Button
                variant="contained"
                color="primary"
                onClick={props.onCreateActivityButtonClick}>
                { t('companyPage.buttons.createNewActivityVerbose') }
            </Button>
        </Paper>
    );
};

export default EmptyActivitiesPlaceholder;

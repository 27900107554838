import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ActivityStatus from 'enums/ActivityStatus';
import PortalIFrame from 'components/shared/PortalIFrame';
import ProductType from 'enums/ProductType';
import Delete from './Delete';
import { createStatusRelativeURL } from 'utils/activityUrlHelper';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import useActivity from 'api/useActivity';
import FocusGroupReportsPanel from 'components/reports/FocusGroupReportsPanel/FocusGroupReportsPanel';

const OverviewTab = ({
    companyId,
    projectId,
    activityId
}) => {
    const navigate = useNavigate();
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    const { data: activity } = useActivity(activityId);

    const loading = () => {
        return activity === null ? <LoadingPlaceholder height="auto" /> : null;
    };

    const main = () => {
        const status = activity?.details.detailedStatus;
        const productType = activity?.details.activityType;

        return (
            <React.Fragment>
                <div>
                    { ActivityStatus[status].shouldShowStatus && ProductType[productType].shouldShowStatus &&
                        <PortalIFrame
                            key='activityStatusIframe'
                            containerProps={{ disableGutters: true }}
                            id={'activityStatusIframe'}
                            relativeUrl={createStatusRelativeURL(ProductType[productType], projectId, activityId)} />
                    }

                    { ActivityStatus[status].shouldShowReports &&
                        <FocusGroupReportsPanel
                            projectId={projectId}
                            activityId={activityId}
                            key='activityReportsIframe' />
                    }
                </div>

                <Delete
                    open={deleteDialogIsOpen}
                    activityId={activityId}
                    projectId={projectId}
                    onCancel={() => setDeleteDialogIsOpen(false)}
                    onSuccess={() => {
                        navigate(`/company/${companyId}/project/${projectId}/activities`);
                    }} />
            </React.Fragment>
        );
    };

    return (
        loading() || main()
    );
};

export default OverviewTab;

import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ToolbarSpacer from 'components/shared/ToolbarSpacer';
import ClipControl from './ClipControl';
import SeekControls from './SeekControls';
import VideoPlayer from './VideoPlayer';
import HighlightDetails from '../HighlightDetails';
import { VideoTrimmerContext } from './VideoTrimmerProvider';
import { Collapse } from '@mui/material';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
        color: '#102A43',
        '& .MuiFormLabel-root': {
            color: '#102A43'
        },
        '& .MuiInput-root': {
            color: '#102A43'
        }
    },
    videoControls: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F0F4F8'
    }
}));

const DoubleCollapse = ({ in: inProp, children }) => {
    return <Collapse
        in={inProp}
        orientation="horizontal"
        collapsedSize={500}
        timeout={300}
    >
        <Collapse
            in={inProp}
            orientation="vertical"
            timeout={300}
        >
            { children }
        </Collapse>
    </Collapse>;
};

const VideoTrimmer = () => {
    const classes = useStyles();

    const {
        pageNumber
    } = useContext(VideoTrimmerContext);

    return (
        <div className={classes.root}>
            <div>
                <DoubleCollapse in={pageNumber === 1}>
                    <div>
                        <VideoPlayer />
                        <div className={classes.videoControls}>
                            <SeekControls />
                            <ToolbarSpacer grow />
                            <ClipControl />
                        </div>
                    </div>
                </DoubleCollapse>
            </div>
            <div>
                <Collapse in={pageNumber === 2}>
                    <HighlightDetails />
                </Collapse>
            </div>
        </div>
    );
};

export default VideoTrimmer;

import { IconButton } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const SquareIconButton = withStyles(theme => ({
    root: {
        ...theme.videoPlayer.squareIconButton.default,
        '&.VL-SquareIconButton-Selected': {
            ...theme.videoPlayer.squareIconButton.selected,
        }
    }
}))(IconButton);

export default SquareIconButton;

import PropTypes from 'prop-types';
import TextPromptActivityResults from './TextPromptActivityResults';
import PollActivityResults from './PollActivityResults';
import HeatmapActivityResults from './HeatmapActivityResults';
import RankingActivityResults from './RankingActivityResults';
import ACTIVITY_TYPE from './Util';
import React from 'react';
import UnsupportedActivityView from './UnsupportedActivityView';

const ActivityResultsView = ({ activity, focusGroupId, isOpen, onClose }) => {
    const activityType = activity.activityType;

    if (!activityType) {
        return null;
    }

    if (activityType === ACTIVITY_TYPE.TEXT_PROMPT) {
        return <TextPromptActivityResults activityId={activity.id} focusGroupId={focusGroupId} isOpen={isOpen} onClose={onClose} />;
    } 
    if (activityType === ACTIVITY_TYPE.POLL) {
        return <PollActivityResults activityId={activity.id} focusGroupId={focusGroupId} isOpen={isOpen} onClose={onClose}/>;
    } 
    if (activityType === ACTIVITY_TYPE.HEATMAP) {
        return <HeatmapActivityResults activity={activity} focusGroupId={focusGroupId} isOpen={isOpen} onClose={onClose}/>;
    } 
    if (activityType === ACTIVITY_TYPE.RANKING) {
        return <RankingActivityResults activity={activity} focusGroupId={focusGroupId} isOpen={isOpen} onClose={onClose}/>;
    } else {
        return <UnsupportedActivityView isOpen={isOpen} onClose={onClose} />;
    }

};

ActivityResultsView.propTypes = {
    activity: PropTypes.object,
    focusGroupId: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};

export default ActivityResultsView;
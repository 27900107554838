import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

const ResizeChartContainer = ({title, chartSize, scalar, setChartSize, children}) => {
    const [expanded, setExpanded] = useState(false);

    const expandChart = (expand) => {
        setChartSize(expand ? getExpandedChartSize() : chartSize);
        setExpanded(expand);
    };

    const getExpandedChartSize = () => {
        return { width: chartSize.width * scalar, height: chartSize.height * scalar };
    };

    let size = { width: chartSize.width, height: chartSize.height };

    if (expanded) {
        size = getExpandedChartSize();
    }

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ textAlign: 'center', width: size.width, height: size.height + 76 }}>
                {
                    expanded
                        ? <Button sx={{ position: 'absolute', right: '20px' }} variant="outlined" onClick={() => expandChart(false)}><CloseFullscreenIcon /></Button>
                        : <Button sx={{ position: 'absolute', right: '20px' }} variant="outlined" onClick={() => expandChart(true)}><OpenInFullIcon /></Button>
                }
                <h2>{title}</h2>
                <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', marginTop: size.height / -2, marginLeft: size.width / -2, transform: 'translate(50%, 50%)' }}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

ResizeChartContainer.propTypes = {
    title: PropTypes.string,
    chartSize: PropTypes.object,
    scalar: PropTypes.number,
    setChartSize: PropTypes.func,
    children: PropTypes.node
};

export default ResizeChartContainer;
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import PortalIFrame from '../shared/PortalIFrame';
import Breadcrumbs from '../shared/Breadcrumbs';
import PageHeader from '../shared/PageHeader';
import TopBar from 'components/TopBar/TopBar';

const AboutHome = () => {
    const { t } = useTranslation();

    const [privacyPolicyIsOpen, setPrivacyPolicyIsOpen] = useState(false);
    const [termsAndConditionsIsOpen, setTermsAndConditionsIsOpen] = useState(false);

    return (
        <div>
            <TopBar />
            <PageHeader>
                <Container>
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                title: t('aboutPage.text.pageTitle')
                            }
                        ]}
                    />
                </Container>
            </PageHeader>

            <Container style={{ marginTop: 32 }}>
                <Typography variant="h6" gutterBottom>App Information</Typography>
                <Paper style={{ padding: '1em', marginBottom: '1em' }} variant="outlined">
                    <Typography variant="overline" gutterBottom>VisionsLive Portal - Version { process.env.REACT_APP_VERSION }</Typography>

                    <ul>
                        <li><Typography variant="body2">{ t('aboutPage.text.copyright') }</Typography></li>
                        <li><Typography variant="body2">{ t('aboutPage.text.contactTelephone') }</Typography></li>
                        <li><Typography variant="body2">{ t('aboutPage.text.contactTelephoneUSA') }</Typography></li>
                    </ul>
                </Paper>

                <Typography variant="h6" gutterBottom>Further Reading</Typography>

                <Paper style={{ padding: '1em', marginBottom: '1em' }} variant="outlined">
                    <Button onClick={() => setPrivacyPolicyIsOpen(true)}>{ t('aboutPage.buttons.openPrivacyPolicy')}</Button>
                    <Button onClick={() => setTermsAndConditionsIsOpen(true)}>{ t('aboutPage.buttons.openTermsAndConditions')}</Button>
                </Paper>
            </Container>

            <Dialog
                maxWidth="lg"
                open={privacyPolicyIsOpen}
                onClose={() => setPrivacyPolicyIsOpen(false)}>
                <DialogContent>
                    <PortalIFrame
                        relativeUrl={'/Home/PrivacyStatement'}
                        containerProps={{ disableGutters: true }}
                        paperProps={{ variant: 'outlined' }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPrivacyPolicyIsOpen(false)}>{ t('common.buttons.close')}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="lg"
                open={termsAndConditionsIsOpen}
                onClose={() => setTermsAndConditionsIsOpen(false)}>
                <DialogContent>
                    <PortalIFrame
                        relativeUrl={'/Home/TermsAndConditions'}
                        containerProps={{ disableGutters: true }}
                        paperProps={{ variant: 'outlined' }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTermsAndConditionsIsOpen(false)}>{ t('common.buttons.close')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AboutHome;

import React from 'react';
import PropTypes from 'prop-types';
import ToggleReactionsButton from './ToggleReactionsButton';
import ToggleHighlightsButton from './ToggleHighlightsButton';
import BasicVideoControls from './BasicVideoControls';
import ElapsedTimeView from './ElapsedTimeView';
import EventsTimeline from './EventsTimeline';

const VideoControls = ({ showReactionFeatures }) => {
    return (
        <React.Fragment>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#102A43', height: 80, color: 'white' }}>
                    <BasicVideoControls showReactionFeatures={showReactionFeatures} />
                    <ElapsedTimeView style={{ marginTop: -8 }} />
                </div>

                <React.Fragment>
                    <EventsTimeline showReactionFeatures={showReactionFeatures} />
                    <ToggleHighlightsButton />
                    {
                        showReactionFeatures && <ToggleReactionsButton />
                    }
                </React.Fragment> 
            </div>
        </React.Fragment>
    );
};

export default VideoControls;

VideoControls.propTypes = {
    showReactionFeatures: PropTypes.bool
};

VideoControls.defaultProps = {
    showReactionFeatures: true
};

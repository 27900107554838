import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Stack, Dialog } from '@mui/material';

const UnsupportedActivityView = ({ isOpen, onClose }) => {
    return (
        <Dialog 
            open={isOpen} 
            onClose={onClose}
            maxWidth='xl'
            fullWidth
        >
            <Stack sx={{ padding: 2 }}>
                <Alert severity='warning'>
                    This activity type is still under development and some features are unavailable at this time.
                </Alert>
            </Stack>
        </Dialog>
    );
};

UnsupportedActivityView.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};

export default UnsupportedActivityView;

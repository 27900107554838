import { useQuery } from 'react-query';
import portalApi from 'utils/portalApi';

const getCurrentUserDetails = async () => {
    const { data } = await portalApi.get('api/currentuser/details');
    return data;
};

const useCurrentUserDetails = () => {
    const { data, refetch, isLoading } = useQuery('currentUserDetails', getCurrentUserDetails, {
        // Avoid refetching data until explicitly needed.
        // TODO: This should be reviewed, but essentially matches the behaviour that was
        // in place previously (i.e. redux-store).
        staleTime: Infinity
    });

    return { userDetails: data, refetch, isLoading };
};

export default useCurrentUserDetails;

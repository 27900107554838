export const Blue = [
    '#003E6B',
    '#0A558C',
    '#0F609B',
    '#186FAF',
    '#2680C2',
    '#4098D7',
    '#62B0E8',
    '#84C5F4',
    '#B6E0FE',
    '#DCEEFB'
];

export const Grey = [
    '#102A43',
    '#243B53',
    '#334E68',
    '#486581',
    '#627D98',
    '#829AB1',
    '#9FB3C8',
    '#BCCCDC',
    '#D9E2EC',
    '#F0F4F8'
];

export const Green = [
    '#115C00',
    '#226B11',
    '#30801F',
    '#519E3F',
    '#6BB75A',
    '#81D46E',
    '#9EE68E',
    '#C1F5B5',
    '#D9FAD2',
    '#F1FAEB'
];

export const Cyan = [
    '#005436',
    '#0B6E4A',
    '#198A61',
    '#28A678',
    '#35BD8C',
    '#43CE9C',
    '#67EBBB',
    '#ADF7DD',
    '#D1FFEE',
    '#EBFFF8'
];

export const Red = [
    '#8F0A00',
    '#A31107',
    '#B31A0E',
    '#CC2C21',
    '#F44336',
    '#F7564A',
    '#FF8880',
    '#FFBFBA',
    '#FFE7E6',
    '#FFF5F5'
];

export const VLRoyal = [
    '#000209',
    '#080F2C',
    '#151F4A',
    '#1E2E6C',
    '#283C8E', // (#4) Original VLRoyal colour
    '#3C57C8',
    '#798CD9',
    '#AFBCF1',
    '#F0F3FF'
];

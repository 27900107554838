import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import { defaultWordFilter } from './WordFilter';
import { TableBody, TableCell, TableContainer, TableHead, Table, TableRow, Box, Button, Stack } from '@mui/material';
import vlqrApi, { handleError } from 'utils/vlqrApi';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { EditTextAnalysisFilter } from './EditTextAnalysisFilter';
import useWordCloudFiltersForProject from 'api-new/useTextAnalysisFiltersForProject';

export const TextAnalysisFilters = ({activityId, projectId, currentFilterId, onApplyFilter}) => {
    const { t } = useTranslation();
    const { data: filters, isLoading: filtersAreLoading, refetch: refetchFilters } = useWordCloudFiltersForProject(projectId);

    const [editFilter, setEditFilter] = useState(null);
    const [snackbar, setSnackbar] = useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    const resetAndRefetch = () => {
        setEditFilter(null);
        refetchFilters();
    };

    const applyFilter = (filter) => {
        const config = { headers: {'Content-Type': 'application/json'} };

        return new Promise((resolve, reject) =>
            vlqrApi.put(`TextAnalysis/WordCloudFilter/ApplyFilter?activityId=${activityId}&filterId=${filter.id}`, config)
                .then(response => {
                    resolve(response);
                    refetchFilters();
                    setSnackbar({ children: t('textAnalysisDashboard.text.filterApplySuccess'), severity: 'success' });
                    onApplyFilter();
                })
                .catch(error => {
                    handleError(error);
                    reject(new Error(error));
                    setSnackbar({ children: t('textAnalysisDashboard.text.filterApplyError'), severity: 'error' });
                })
        );
    };

    const resetFilter = () => {
        const config = { headers: {'Content-Type': 'application/json'} };

        return new Promise((resolve, reject) =>
            vlqrApi.put(`TextAnalysis/WordCloudFilter/ResetFilter/${activityId}`, config)
                .then(response => {
                    resolve(response);
                    refetchFilters();
                    setSnackbar({ children: t('textAnalysisDashboard.text.filterApplySuccess'), severity: 'success' });
                    onApplyFilter();
                })
                .catch(error => {
                    handleError(error);
                    reject(new Error(error));
                    setSnackbar({ children: t('textAnalysisDashboard.text.filterApplyError'), severity: 'error' });
                })
        );
    };

    const deleteFilter = (filter) => {
        if (window.confirm(t('textAnalysisDashboard.text.confirmDeleteFilter'))) {
            const config = { headers: {'Content-Type': 'application/json'} };

            return new Promise((resolve, reject) =>
                vlqrApi.delete(`TextAnalysis/WordCloudFilter/${filter.id}`, config)
                    .then(response => {
                        resolve(response);
                        refetchFilters();
                        setSnackbar({ children: t('textAnalysisDashboard.text.filterDeleteSuccess'), severity: 'success' });
                    })
                    .catch(error => {
                        handleError(error);
                        reject(new Error(error));
                        setSnackbar({ children: t('textAnalysisDashboard.text.filterDeleteError'), severity: 'error' });
                    })
            );
        }
    };

    const newFilter = {
        name: t('textAnalysisDashboard.text.newFilter'),
        filter: defaultWordFilter,
        projectId: projectId
    };

    if (filtersAreLoading) {
        return (
            <LoadingPlaceholder />
        );
    }

    if (editFilter) {
        return (
            <EditTextAnalysisFilter item={editFilter} goBack={resetAndRefetch} />
        );
    }

    return (
        <Box>
            {
                filters.length === 0 &&
                <Box sx={{ padding: '15px' }}>{t('textAnalysisDashboard.text.noWordCloudFiltersAvailable')}</Box>
            }
            {
                filters.length > 0 &&
                <TableContainer component={Box} sx={{ minHeight: '340px', overflowX: 'hidden' }}>
                    <Table sx={{ minWidth: 400, margin: '10px', width: 'auto' }} size='small' aria-label='filters table'>
                        <TableHead>
                            <TableRow sx={{ borderBottom: 1 }}>
                                <TableCell>{t('textAnalysisDashboard.tableHeaders.filterName')}</TableCell>
                                <TableCell>{t('textAnalysisDashboard.tableHeaders.actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                filters.map((e) => 
                                    <TableRow 
                                        key={e.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component='th' scope='e'>{e.name} {e.id === currentFilterId ? ' *' : ''}</TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={2}>
                                                <Button size='small' color='success' variant='contained' onClick={() => applyFilter(e)}>{t('common.buttons.apply')}</Button>
                                                <Button size='small' variant='contained' onClick={() => setEditFilter(e)}>{t('common.buttons.edit')}</Button>
                                                <Button size='small' color='error' variant='contained' onClick={() => deleteFilter(e)}>{t('common.buttons.delete')}</Button>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <Box sx={{ height: '60px', position: 'relative', bottom: '0px', padding: '15px' }}>
                <Button sx={{ float: 'right' }} variant='contained' onClick={() => setEditFilter(newFilter)}>{t('textAnalysisDashboard.labels.createFilter')}</Button>
                <Button sx={{ float: 'left' }} variant='outlined' onClick={() => resetFilter()}>{t('textAnalysisDashboard.labels.applyDefault')}</Button>
            </Box>
            {snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={5000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Box>
    );
};

TextAnalysisFilters.propTypes = {
    activityId: PropTypes.string,
    projectId: PropTypes.string,
    currentFilterId: PropTypes.string,
    onApplyFilter: PropTypes.func
};
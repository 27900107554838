import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const TextAnalysisContext = createContext();

export const createDefaultState = (props) => {
    return {
        activityId: props.activityId,
        projectId: props.projectId,
        textAnalysisInfo: props.textAnalysisInfo,
        isAVSession: props.isAVSession
    };
};

const TextAnalysisProvider = ({ initialState, children }) => {
    const defaultState = createDefaultState(initialState);

    const {
        activityId,
        projectId,
        textAnalysisInfo,
        isAVSession
    } = defaultState;

    return (
        <TextAnalysisContext.Provider
            value={{
                activityId,
                projectId,
                textAnalysisInfo,
                isAVSession
            }}
        >
            { children }
        </TextAnalysisContext.Provider>
    );
};

TextAnalysisProvider.propTypes = {
    initialState: PropTypes.shape({
        /** Guid referencing the Activity that was recorded */
        activityId: PropTypes.string.isRequired,
        projectId: PropTypes.string.isRequired,
        textAnalysisInfo: PropTypes.object,
        isAVSession: PropTypes.bool
    }),
    children: PropTypes.node
};

export default TextAnalysisProvider;
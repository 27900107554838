import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Container, Link, Alert } from '@mui/material';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import TopBar from 'components/TopBar/TopBar';
import PageHeader from 'components/shared/PageHeader';
import useBasicActivity from 'api-new/useBasicActivity';
import { styled } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { VoiceTranscriptionEditor } from 'components/VoiceTranscriptions/VoiceTranscriptionEditor';
import useVoiceTranscriptionsForActivity from 'api-new/useVoiceTranscriptionsForActivity';

const VLActivityLink = styled(Link)(({ theme }) => ({
    color: '#DCEEFB',
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: 4,
    padding: 4,
    paddingRight: 8,
    border: 'thin solid #DCEEFB',
    borderRadius: 4,
    '&:hover': {
        color: 'white',
        borderColor: 'white',
        backgroundColor: '#4098D7'
    },
    transition: 'all .3s'
}));

export const VoiceTranscriptionEditorPage = () => {
    const { t } = useTranslation();

    const {
        activity_guid: activityId
    } = useParams();

    const { data: selectedActivity, isLoading: activityIsLoading, isError: errorLoadingActivity } = useBasicActivity(activityId);
    const { data: voiceTranscriptions, isLoading: voiceTranscriptionsAreLoading, isError: errorLoadingVoiceTranscriptions } = useVoiceTranscriptionsForActivity(activityId);

    if (activityIsLoading || voiceTranscriptionsAreLoading) {
        return <LoadingPlaceholder />;
    }

    if (errorLoadingActivity) {
        return (
            <div style={{ backgroundColor: 'white', paddingBottom: 24, display: 'flex', justifyContent: 'center' }}>
                <Alert sx={{ mt: 2, maxWidth: 1200 }} severity="error">{ t('vlqrApi.errors.loadingActivity') }</Alert>
            </div>
        );
    }

    return (
        <div style={{ backgroundColor: 'white', paddingBottom: 24 }}>
            <TopBar
                companyId={selectedActivity.companyId}
                projectId={selectedActivity.projectId}
                activityId={activityId}
            />
            <PageHeader>
                <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 12 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <VLActivityLink
                            to={`/company/${selectedActivity.companyId}/project/${selectedActivity.projectId}/activity/${activityId}`}
                            component={RouterLink}
                            underline="none"
                        >
                            <ArrowBackIcon />
                            <span>{ t('textAnalysisDashboard.text.backToActivity') }</span>
                        </VLActivityLink>
                        <Typography variant="h1">{selectedActivity?.description ?? selectedActivity?.title} - Voice Transcription Editor</Typography>
                    </div>
                </Container>
            </PageHeader>
            <div style={{display: 'flex', justifyContent: 'center' }}>
                {
                    errorLoadingVoiceTranscriptions ?
                        <Alert sx={{ mt: 2, maxWidth: 1200 }} severity="error">{ t('vlqrApi.errors.loadingVoiceTranscriptions') }</Alert> :
                        <VoiceTranscriptionEditor data={voiceTranscriptions} />
                }
            </div>
        </div>
    );
};

VoiceTranscriptionEditorPage.propTypes = {};

export default VoiceTranscriptionEditorPage;
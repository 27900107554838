import React, { createContext, createRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { byEventLoggedAt } from './sortFunc';
import { calcPlayedSeconds, getEventStartSeconds } from './util';
import { DateTime, Duration } from 'luxon';

export const ActivityRecordingContext = createContext();

export const DEFAULT_FILTER = {
    showModeratorNotes: true,
    showObserverNotes: true,
    showRespondentReactions: true
};

const EVENT_TYPES = {
    MODERATOR_NOTE: 'moderatorNote',
    RESPONDENT_REACTION: 'respondentReaction',
    OBSERVER_NOTE: 'observerNote'
};

export const createDefaultState = (props) => {
    const selectedRecording = props.selectedRecording ?? props.recordings?.[0];

    return {
        activityId: props.activityId,
        eventLog: props.eventLog,
        sessionStartDate: props.sessionStartDate,
        reactionsPaneIsOpen: props.reactionsPaneIsOpen ?? false,
        highlightsPaneIsOpen: props.highlightsPaneIsOpen ?? false,
        recordings: props.recordings ?? [],
        selectedRecording,
        startSeconds: props.startSeconds ?? 0,
        clipStartTime: props.startSeconds,
        clipDuration: props.clipDuration
    };
};

function usePrevious (value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const ActivityRecordingProvider = ({ initialState, children }) => {
    const defaultState = createDefaultState(initialState);

    const {
        recordings,
        activityId,
        eventLog,
        sessionStartDate,
        clipDuration,
        clipStartTime
    } = defaultState;

    const videoPlayerRef = createRef();
    const [reactionsPaneIsOpen, setReactionsPaneIsOpen] = useState(defaultState.reactionsPaneIsOpen);
    const [highlightsPaneIsOpen, setHighlightsPaneIsOpen] = useState(defaultState.highlightsPaneIsOpen);
    const [videoIsPlaying, setVideoIsPlaying] = useState(false);
    const [videoDuration, setVideoDuration] = useState(null);
    const [filterSettings, setFilterSettings] = useState(DEFAULT_FILTER);
    const [selectedRecording, setSelectedRecording] = useState(defaultState.selectedRecording);

    const [videoPlaybackProgress, setVideoPlaybackProgress] = useState({
        played: 0,
        loaded: 0,
        playedSeconds: 0,
        loadedSeconds: 0
    });

    const prevSelectedRecording = usePrevious(selectedRecording);
    const [switchRecordingState, setSwitchRecordingState] = useState(null);

    useEffect(() => {
        setSwitchRecordingState({
            newPlayedSeconds: calcPlayedSeconds(
                videoPlaybackProgress.playedSeconds,
                selectedRecording,
                prevSelectedRecording,
                defaultState.startSeconds
            )
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRecording]);

    useEffect(() => {
        if (switchRecordingState) {
            videoPlayerRef.current.seekTo(switchRecordingState.newPlayedSeconds);
            setTimeout(() => setSwitchRecordingState(null), 1000); // wait a second to allow time for seek to update slider etc.
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoPlaybackProgress]);

    const videoRecordingStartDate = DateTime.fromISO(sessionStartDate).minus(
        Duration.fromMillis(selectedRecording.videoStartToSessionStartMillis ?? 0)
    );

    const bySupportedEventsOnly = (event) => {
        return Object.values(EVENT_TYPES).includes(event.type);
    };

    const byFilterSettings = (event) => {
        if (filterSettings.showModeratorNotes === false) {
            if (event.type === EVENT_TYPES.MODERATOR_NOTE) {
                return false;
            }
        }

        if (filterSettings.showObserverNotes === false) {
            if (event.type === EVENT_TYPES.OBSERVER_NOTE) {
                return false;
            }
        }

        if (filterSettings.showRespondentReactions === false) {
            if (event.type === EVENT_TYPES.RESPONDENT_REACTION) {
                return false;
            }
        }

        return true;
    };

    const byEventIsActive = (event) => {
        const eventStartSeconds = getEventStartSeconds(event, videoRecordingStartDate);

        return eventStartSeconds >= videoPlaybackProgress.playedSeconds - 10 &&
                eventStartSeconds <= videoPlaybackProgress.playedSeconds + 10;
    };

    const supportedEvents = eventLog.filter(bySupportedEventsOnly);
    const noteEvents = supportedEvents.filter(byFilterSettings);
    const activeNoteEvents = noteEvents.filter(byEventIsActive);

    const skipToEvent = (eventId) => {
        const targetEvent = eventLog.find(e => e.id === eventId);

        if (targetEvent) {
            const eventStartSeconds = getEventStartSeconds(targetEvent, videoRecordingStartDate);
            videoPlayerRef.current.seekTo(eventStartSeconds);
        }
    };

    const skipToPreviousEvent = () => {
        const targetEvent = [...noteEvents].sort(byEventLoggedAt).reverse().find(e =>
            getEventStartSeconds(e, videoRecordingStartDate) < videoPlaybackProgress.playedSeconds - 5
        );

        if (targetEvent) {
            skipToEvent(targetEvent.id);
        }
    };

    const skipToNextEvent = () => {
        const targetEvent = [...noteEvents].sort(byEventLoggedAt).find(e =>
            getEventStartSeconds(e, videoRecordingStartDate) > videoPlaybackProgress.playedSeconds
        );

        if (targetEvent) {
            skipToEvent(targetEvent.id);
        }
    };

    return (
        <ActivityRecordingContext.Provider
            value={{
                recordings,
                activityId,
                recordingId: selectedRecording.id,
                videoRecordingUrl: selectedRecording.outputUrl,
                videoRecordingStartDate,
                eventLog,
                noteEvents,
                activeNoteEvents,
                videoPlayerRef,
                reactionsPaneIsOpen, setReactionsPaneIsOpen,
                highlightsPaneIsOpen, setHighlightsPaneIsOpen,
                videoIsPlaying, setVideoIsPlaying,
                videoDuration, setVideoDuration,
                videoPlaybackProgress, setVideoPlaybackProgress,
                filterSettings, setFilterSettings,
                skipToEvent,
                skipToPreviousEvent,
                skipToNextEvent,
                selectedRecording, setSelectedRecording,
                switchRecordingState, setSwitchRecordingState,
                clipStartTime,
                clipDuration
            }}
        >
            { children }
        </ActivityRecordingContext.Provider>
    );
};

ActivityRecordingProvider.propTypes = {
    initialState: PropTypes.shape({
        /** Guid referencing the Activity that was recorded */
        activityId: PropTypes.string.isRequired,
        /** Array of recordings */
        recordings: PropTypes.array,
        /** An array containing the EventLog data, i.e. events that were captured during a session */
        eventLog: PropTypes.array.isRequired,
        /** Session start date (ISO8601 format) */
        sessionStartDate: PropTypes.string
    }),
    children: PropTypes.node
};

export default ActivityRecordingProvider;

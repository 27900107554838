import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const useTextAnalysisInfo = (activityId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['textAnalysisInfo', activityId],
        () => getTextAnalysisInfo(activityId)
    );

    return { data, refetch, isLoading, isError };
};

const getTextAnalysisInfo = async (activityId) => {
    const keyPhrases = await vlqrApi
        .get(`TextAnalysis/KeyPhrases/${activityId}`)
        .catch(handleError);

    const overallSentiment = await vlqrApi
        .get(`TextAnalysis/OverallSentiment/${activityId}`)
        .catch(handleError);

    const recognizedEntities = await vlqrApi
        .get(`TextAnalysis/RecognizedEntities/${activityId}`)
        .catch(handleError);

    const sentimentOverTime = await vlqrApi
        .get(`TextAnalysis/SentimentOverTime/${activityId}`)
        .catch(handleError);
        
    var data = { keyPhrases, overallSentiment, recognizedEntities, sentimentOverTime };
    return data;
};

export default useTextAnalysisInfo;
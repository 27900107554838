import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import useActivitiesForFocusGroup from 'api-new/stim-based/useActivitiesForFocusGroup';
import useStimuliForFocusGroup from 'api-new/stim-based/useStimuliForFocusGroup';
import React from 'react';
import ActivitiesListItem from './ActivitiesListItem';
import PropTypes from 'prop-types';

const ActivitiesTabPanel = ({ focusGroupId }) => {
    const { data: stimuli, isLoading: stimuliLoading } = useStimuliForFocusGroup(focusGroupId);
    const { data: activities, isLoading: activitiesLoading } = useActivitiesForFocusGroup(focusGroupId);

    if (stimuliLoading || activitiesLoading) {
        return <Box>Loading...</Box>;
    }

    return <Box sx={{ padding: 1, paddingTop: 3 }}>
        <Stack direction='column' gap={5}>
            {stimuli?.map(stimulus => <Box key={stimulus.id}>
                <Typography variant='h6' sx={{marginBottom: 1}}>
                    { stimulus.title }
                </Typography>
                <Stack direction='column'>
                    { activities
                        .filter(e => e.stimulusId === stimulus.id)
                        .map(activity => <ActivitiesListItem key={activity.id} activity={activity} focusGroupId={focusGroupId} />) }
                </Stack>
            </Box>)}
        </Stack>
    </Box>;
};

ActivitiesTabPanel.propTypes = {
    focusGroupId: PropTypes.string.isRequired
};

export default ActivitiesTabPanel;

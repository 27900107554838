import { Button, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Bookmark, Close, Error } from '@mui/icons-material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatSeconds } from '../../util';
import { VideoTrimmerContext } from './VideoTrimmerProvider';
import { ERROR_CODES } from './VideoTrimmerValidation';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: "white",
        display: 'flex',
        alignItems: 'center'
    },
    clipValue: {
        marginLeft: 8,
        backgroundColor: 'white',
        width: 145,
        padding: 2,
        borderRadius: 4,
        border: '1px solid #BCCCDC',
        '& .MuiIconButton-root': {
            padding: 8
        },
        '& .MuiInputBase-input': {
            fontWeight: 500,
            paddingTop: 7,
            paddingBottom: 7
        }
    },
    setClipButton: {
        marginLeft: 8,
        padding: 8,
        backgroundColor: 'white',
        borderRadius: 4,
        border: '1px solid #BCCCDC',
        '& .MuiButton-startIcon': {
            marginRight: 4
        }
    }
}));

const ClipStartControl = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        clipStartTime, setClipStartTime,
        videoPlaybackProgress
    } = useContext(VideoTrimmerContext);

    if (clipStartTime !== null) {
        return (
            <Tooltip arrow title={t('sessionHighlights.tooltips.clipStart')}>
                <TextField
                    className={classes.clipValue}
                    value={formatSeconds(clipStartTime)}
                    variant="standard"
                    disabled
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Bookmark style={{ fill: 'green' }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setClipStartTime(null)} size="large">
                                    <Close />
                                </IconButton>
                            </InputAdornment>
                        ),
                        disableUnderline: true
                    }}
                />
            </Tooltip>
        );
    } else {
        return (
            <Button
                className={classes.setClipButton}
                startIcon={<Bookmark />}
                onClick={() => setClipStartTime(videoPlaybackProgress.playedSeconds)}
                disabled={clipStartTime === videoPlaybackProgress.playedSeconds}
                style={{ color: 'green' }}
            >
                { t('sessionHighlights.buttons.setClipStart') }
            </Button>
        );
    }
};

const ClipEndControl = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        clipEndTime, setClipEndTime,
        videoPlaybackProgress,
        errors
    } = useContext(VideoTrimmerContext);

    const getTooltipTitle = () => {
        if (errors.includes(ERROR_CODES.NEGATIVE_CLIP_TIME)) {
            return t('sessionHighlights.errors.negativeClipTime');
        } else {
            return t('sessionHighlights.tooltips.clipEnd');
        }
    };

    const getStartAdornment = () => {
        if (errors.includes(ERROR_CODES.NEGATIVE_CLIP_TIME)) {
            return (
                <InputAdornment position="start">
                    <Error style={{ fill: 'red' }} />
                </InputAdornment>
            );
        } else {
            return (
                <InputAdornment position="start">
                    <Bookmark style={{ fill: 'red' }} />
                </InputAdornment>
            );
        }
    };

    if (clipEndTime !== null) {
        return (
            <Tooltip arrow title={getTooltipTitle()}>
                <TextField
                    className={classes.clipValue}
                    value={formatSeconds(clipEndTime)}
                    variant="standard"
                    disabled
                    InputProps={{
                        startAdornment: getStartAdornment(),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setClipEndTime(null)} size="large">
                                    <Close />
                                </IconButton>
                            </InputAdornment>
                        ),
                        disableUnderline: true
                    }}
                />
            </Tooltip>
        );
    } else {
        return (
            <Button
                className={classes.setClipButton}
                startIcon={<Bookmark />}
                onClick={() => setClipEndTime(videoPlaybackProgress.playedSeconds)}
                disabled={clipEndTime === videoPlaybackProgress.playedSeconds}
                style={{ color: '#F7564A' }}
            >
                { t('sessionHighlights.buttons.setClipEnd') }
            </Button>
        );
    }
};

const ClipControl = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ClipStartControl />
            <ClipEndControl />
        </div>
    );
};

export default ClipControl;

// Used to define the top area (container) of a view.
// i.e. the blue section containing (e.g.) tabs, breadcrumbs, pill buttons UI.

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    ...theme.pageHeader
}));

const PageHeader = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            { props.children }
        </div>
    );
};

export default PageHeader;

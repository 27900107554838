import { Box, Button, Card, Skeleton, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import useReleaseNotes from 'api-new/useReleaseNotes';
import Markdown from 'markdown-to-jsx';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const SkeletonReleaseNote = () => {
    return <Card variant='outlined' sx={{padding: 1}}>
        <Typography gutterBottom variant="h5">
            <Skeleton variant="rectangular" width={380} />
        </Typography>
        <Typography gutterBottom variant="body1">
            <Skeleton variant="rectangular" width={380} />
        </Typography>
        <Typography variant="caption">
            <Skeleton variant="rectangular" width={380} />
        </Typography>
    </Card>;
};

const VLMarkdown = styled(Markdown)({
    '&': {
        fontSize: '90%'
    },
    img: {
        maxWidth: '100%',
        marginTop: '1em'
    }
});

const ReleaseNote = ({ entry }) => {
    const { t } = useTranslation();
    const openReleaseNote = () => window.open(entry.url, '_blank').focus();
    return <Box>
        <Card variant='outlined' sx={{paddingX: 1}}>
            <Typography variant="caption" color="text.secondary">
                { DateTime.fromISO(entry.publishedAt).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) }
            </Typography>
            <Typography gutterBottom variant="h5">
                <a href={entry.url} rel='noreferrer' target='_blank'>
                    {entry.title}
                </a>
            </Typography>
            <VLMarkdown sx={{maxHeight: 400, overflow: 'hidden'}}>{entry.markdownBody}</VLMarkdown>
            <Button
                sx={{marginBottom: 1, marginTop: 2, width: '100%'}}
                variant='contained'
                onClick={openReleaseNote}
            >
                { t('topBar.releaseNotes.showFullChangeDetails') }
            </Button>
        </Card>
    </Box>;
};
ReleaseNote.propTypes = {
    entry: PropTypes.object.isRequired
};

const ReleaseNotesEntryList = () => {
    const { data: entries, isLoading, isError } = useReleaseNotes();

    if (isLoading || isError) {
        return <Stack maxHeight={600} overflow='auto' spacing={1}>
            <SkeletonReleaseNote />
            <SkeletonReleaseNote />
            <SkeletonReleaseNote />
        </Stack>;
    } else {
        return <Stack maxHeight={600} overflow='auto' spacing={1}>
            {entries.map((entry) => <ReleaseNote entry={entry} key={entry.id} />)}
        </Stack>;
    }
};

export default ReleaseNotesEntryList;

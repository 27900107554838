import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getActivity = async (activityId) => {
    const { data } = await vlqrApi.get(`Activities/${activityId}`)
        .catch((error) => {
            handleError(error);
        });
    return data;
};

const useBasicActivity = (activityId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['basicActivity', activityId],
        () => getActivity(activityId)
    );

    return { data, refetch, isLoading, isError };
};

export default useBasicActivity;
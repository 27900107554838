import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import LoadingPlaceholder from './LoadingPlaceholder';

const VLLoadingContainer = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 200,
    backgroundColor: '#486581',
    backgroundImage: 'linear-gradient(to bottom right, #486581, #334E68)',
    color: 'white',
    '& .MuiCircularProgress-svg': {
        color: 'white'
    },
    '& .MuiTypography-root': {
        color: 'white'
    }
}));

export const LoadingPlaceholderFullPage = (props) => {
    return (
        <VLLoadingContainer>
            <LoadingPlaceholder>
                {props.children}
            </LoadingPlaceholder>
        </VLLoadingContainer>
    );
};

LoadingPlaceholderFullPage.propTypes = {
    children: PropTypes.object
};


import React, { useContext } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    SkipNext,
    SkipPrevious,
    Eject, // Rotated and used for forward/back one second
    PauseCircleOutline,
    PlayCircleOutline
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { VideoTrimmerContext } from './VideoTrimmerProvider';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiIconButton-root': {
            '& .MuiSvgIcon-root': {
                fill: '#102A43'
            }
        }
    },
    backOneSecondBtn: {
        '& .MuiSvgIcon-root': {
            transform: 'rotate(-90deg)'
        }
    },
    forwardOneSecondBtn: {
        '& .MuiSvgIcon-root': {
            transform: 'rotate(90deg)'
        }
    },
    playPauseBtn: {
        '& .MuiSvgIcon-root': {
            fontSize: 32
        }
    }
}));

const SeekControls = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        videoIsPlaying, setVideoIsPlaying,
        skipToClipStart,
        skipToClipEnd,
        skipForward,
        skipBackward
    } = useContext(VideoTrimmerContext);

    return (
        <div className={classes.root}>
            <IconButton onClick={skipToClipStart} size="large">
                <SkipPrevious />
            </IconButton>

            <Tooltip title={t('videoPlayer.tooltips.backOneSecond')}>
                <IconButton
                    className={classes.backOneSecondBtn}
                    onClick={() => skipBackward(1)}
                    size="large">
                    <Eject />
                </IconButton>
            </Tooltip>

            { videoIsPlaying ?
                <IconButton
                    className={classes.playPauseBtn}
                    onClick={() => setVideoIsPlaying(false)}
                    size="large">
                    <PauseCircleOutline />
                </IconButton> :
                <IconButton
                    className={classes.playPauseBtn}
                    onClick={() => setVideoIsPlaying(true)}
                    size="large">
                    <PlayCircleOutline />
                </IconButton>
            }

            <Tooltip title={t('videoPlayer.tooltips.forwardOneSecond')}>
                <IconButton
                    className={classes.forwardOneSecondBtn}
                    onClick={() => skipForward(1)}
                    size="large">
                    <Eject />
                </IconButton>
            </Tooltip>

            <IconButton onClick={skipToClipEnd} size="large">
                <SkipNext />
            </IconButton>
        </div>
    );
};

export default SeekControls;

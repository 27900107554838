import PropTypes from 'prop-types';
import { Fade, Link, Typography } from '@mui/material';
import useCompany from 'api/useCompany';
import useCurrentUserDetails from 'api/useCurrentUserDetails';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const CompanyLink = ({ companyId }) => {
    const { data: selectedCompany } = useCompany(companyId);

    return (
        <Link to={`/company/${selectedCompany?.id}`} component={RouterLink} underline="hover">
            <Typography variant="subtitle2">{ selectedCompany?.name }</Typography>
        </Link>
    );
};

CompanyLink.propTypes = {
    companyId: PropTypes.string
};

const UserCompanyHeading = ({ companyId }) => {
    const { userDetails } = useCurrentUserDetails();

    return (
        <Fade in={userDetails !== null}>
            <div className={'VL-UserCompanyHeading'}>
                <div>
                    <Typography variant="subtitle1" noWrap>
                        { userDetails?.firstName } { userDetails?.lastName }
                    </Typography>

                    { companyId &&
                        <CompanyLink companyId={companyId} />
                    }
                </div>
            </div>
        </Fade>
    );
};

UserCompanyHeading.propTypes = {
    companyId: PropTypes.string
};

export default UserCompanyHeading;

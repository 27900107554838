import React from 'react';
import PropTypes from 'prop-types';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import useRespondentChatForTextAnalysis from 'api-new/useRespondentChatForTextAnalysis';
import useParticipantsForActivity from 'api-new/useParticipantsForActivity';
import { ParticipantMessageViewer } from 'pages/TextAnalysisDashboard/ParticipantMessageViewer';
import { useTranslation } from 'react-i18next';

const filterChatLog = (data, filters) => {
    if (filters.sentiment) {
        data = data.filter(d => d.sentiment?.sentiment === filters.sentiment);
    }

    if (filters.user) {
        data = data.filter(d => d.participantId === filters.user);
    }

    if (filters.text) {
        data = data.filter(d => d.message.includes(filters.text));
    }

    if (filters.timeStart) {
        data = data.filter(d => d.recordingTimeInSeconds >= filters.timeStart);
    }

    if (filters.timeEnd) {
        data = data.filter(d => d.recordingTimeInSeconds <= filters.timeEnd);
    }

    return data;
};
 
export const ChatLogViewer = ({ activityId, filters, resetFilters }) => {
    const { t } = useTranslation();

    const { data: chatLog, isLoading: isChatLogLoading } = useRespondentChatForTextAnalysis(activityId);
    const { data: participants, isLoading: isParticipantsLoading } = useParticipantsForActivity(activityId);

    if (isChatLogLoading || isParticipantsLoading) {
        return <LoadingPlaceholder />;
    }

    const filteredChatLog = filters ? filterChatLog(chatLog, filters) : chatLog;

    return (
        <ParticipantMessageViewer 
            data={filteredChatLog} 
            participants={participants} 
            filters={filters} 
            resetFilters={resetFilters} 
            title={t('textAnalysisDashboard.labels.respondentChatLogTitle')}
        />
    );
};

ChatLogViewer.propTypes = {
    activityId: PropTypes.string,
    /** Filters should be an object containing any number of the following properties: sentiment, user, text, startTime, endTime */
    filters: PropTypes.object,
    resetFilters: PropTypes.func
};
// Exports an axios instance for interacting with the Portal Backend API.
//
// Note that a generic error handler is configured and should be used by most requests
// for all requests. When deviating from this error handling, consider the
// following pattern:
//
// import portalApi, { handleError } from 'portalApi'
//
// portalApi.get('api/example')
//   .then((response) => { // do something })
//   .catch((error) => {
//      if (someSpecificError) {
//        // handle
//      } else {
//        return Promise.reject(error)
//      }
//   })
//   .catch(handleError); // defer to generic error handler.
//
import Axios from 'axios';
import { apiUrl } from '../Config';

import Store from '../redux/store';
import { setIsOffline } from '../redux/actions';

export const handleAuthenticationError = (error) => {
    const currentUrl = encodeURIComponent(window.location.href);
    window.location.href = `${apiUrl}?ReturnUrl=${currentUrl}`;
};

const handleAuthorizationError = (error) => {
    const currentUrl = encodeURIComponent(window.location.href);
    window.href = `/denied?redirectTo=${currentUrl}`;
};

const handleCommsError = (error) => {
    Store.dispatch(setIsOffline(true));
};

const handleServerError = (error) => {
    if (error.response.status === 400 || error.response.status === 401) {
        handleAuthenticationError(error);
    } else if (error.response.status === 403) {
        handleAuthorizationError(error);
    }
};

export const handleError = (error) => {
    if (error.response) {
        // Server responded with status code outside of 2xx range
        handleServerError(error);
    } else if (error.request) {
        // Request was made but no response was received

        // Check to see if we're offline
        if (window.navigator.onLine === false) {
            handleCommsError();
        } else {
            console.error(error);
        }
    } else {
        // Something happened in setting up the request that triggered an Error.
        console.error(error);
    }
};

const api = Axios.create({
    baseURL: apiUrl,
    withCredentials: true
});

api.CancelToken = Axios.CancelToken;
api.isCancel = Axios.isCancel;

export default api;

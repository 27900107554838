import React from 'react';
import { useMatch } from 'react-router-dom';
import IconTab from './IconTab';
import EventIcon from '@mui/icons-material/Event';

const ActivityIconTab = () => {
    const routeIsActive = useMatch({
        path: '/company/:company_guid/project/:project_guid/activity/:activity_guid'
    });

    return (
        <span>
            <IconTab
                icon={<EventIcon />}
                link={''}
                isActive={Boolean(routeIsActive)}
            />
        </span>
    );
};

export default ActivityIconTab;

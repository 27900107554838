import PropTypes from 'prop-types';
import { Divider, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import React from 'react';
import copyToClipboard from 'copy-to-clipboard';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import CompanyRole from 'enums/CompanyRole';
import ActivityStatus from 'enums/ActivityStatus';
import { useTranslation } from 'react-i18next';

const PageMenu = ({
    companyId, projectId, activityId, anchorEl, onClose, onDeleteButtonClick, companyRole, status
}) => {
    const { t } = useTranslation();

    const DeleteButton = () => {
        if (CompanyRole[companyRole].canDeleteActivity === false) {
            return null;
        }

        return (
            <Tooltip title={ActivityStatus[status].allowsDeletion ? '' : t('activityPage.tooltips.cannotDeleteRunningActivity')} arrow>
                <div>
                    <MenuItem
                        onClick={onDeleteButtonClick}
                        disabled={ActivityStatus[status].allowsDeletion === false}
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        { t('activityPage.buttons.deleteActivity') }
                    </MenuItem>
                </div>
            </Tooltip>
        );
    };

    return (
        <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
        >
            <DeleteButton />

            <Divider style={{ margin: 4 }} />

            <MenuItem
                onClick={() => {
                    copyToClipboard(companyId);
                    onClose();
                }}
            >
                <ListItemIcon>
                    <FileCopyOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Copy Company ID
            </MenuItem>

            <MenuItem
                onClick={() => {
                    copyToClipboard(projectId);
                    onClose();
                }}
            >
                <ListItemIcon>
                    <FileCopyOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Copy Project ID
            </MenuItem>

            <MenuItem
                onClick={() => {
                    copyToClipboard(activityId);
                    onClose();
                }}
            >
                <ListItemIcon>
                    <FileCopyOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Copy Activity ID
            </MenuItem>
        </Menu>
    );
};

PageMenu.propTypes = {
    activityId: PropTypes.string,
    anchorEl: PropTypes.any,
    companyId: PropTypes.string,
    onClose: PropTypes.func,
    projectId: PropTypes.string
};

export default PageMenu;

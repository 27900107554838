import useActivityEventLog from 'api/useActivityEventLog';
import useActivityRecordings from 'api/useActivityRecordings';
import useActivityActualStartTime from 'api/useActivityActualStartTime';

const useActivityRecordingInfo = (activityId) => {
    const {
        data: activityActualStartTime,
        isLoading: isLoadingActivityActualStartTime
    } = useActivityActualStartTime(activityId);

    const { data: recordings, isLoading: isLoadingRecordings } = useActivityRecordings(activityId);
    const { data: eventLog, isLoading: isLoadingEventLog } = useActivityEventLog(activityId);

    return {
        data: {
            sessionStartDate: activityActualStartTime,
            recordings,
            eventLog
        },
        isLoading: isLoadingRecordings || isLoadingEventLog || isLoadingActivityActualStartTime
    };
};

export default useActivityRecordingInfo;

import { useEffect, useState } from 'react';
import SessionHighlightEncodingStatus from '../../enums/SessionHighlightEncodingStatus';
import vlqrApi from 'utils/vlqrApi';
import { useQuery } from 'react-query';

const whereHighlightIsProcessing = (highlight) => {
    const processingStatuses = [
        SessionHighlightEncodingStatus.NotStarted,
        SessionHighlightEncodingStatus.Running
    ];

    return processingStatuses.includes(SessionHighlightEncodingStatus[highlight.encodingStatus]);
};

const getSessionHighlights = async (activityId) => {
    const { data } = await vlqrApi.get(`FocusGroups/${activityId}/videoHighlights`);
    return data;
};

/**
 * @typedef {Object} useSessionHighlightsResponse
 * @property {Array} sessionHighlights - An array of session highlights
 * @property {Func} refetchSessionHighlights - Refetch the session highlights
 */

/**
 * fetch remote session highlights for a given Activity
 *
 * When a highlight is detected with a 'running' encodingStatus, this hook will poll the service until it is complete.
 *
 * @param {*} activityId
 * @returns {useSessionHighlightsResponse}
 */
const useSessionHighlights = (activityId) => {
    // used to poll service when unprocessed videos are detected
    const [refetchInterval, setRefetchInterval] = useState(false);

    const { data, refetch } = useQuery(['sessionHighlights', activityId], () => getSessionHighlights(activityId), {
        refetchInterval
    });

    // Checks for unprocessed videos and updates the refetchInterval accordingly.
    useEffect(() => {
        if (data?.find(whereHighlightIsProcessing)) {
            setRefetchInterval(5000);
        } else {
            setRefetchInterval(false);
        }
    }, [data]);

    return { data, refetch };
};

export default useSessionHighlights;

import PropTypes from 'prop-types';
import { Button, Collapse, Dialog, DialogActions, DialogContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import VideoTrimmer from './VideoTrimmer';
import VideoTrimmerProvider, { VideoTrimmerContext } from './VideoTrimmerProvider';
import { useTranslation } from 'react-i18next';
import useCreateHighlightRequest from './useCreateHighlightRequest';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiDialog-paper': {
            margin: 0
        },
        '@media (min-width: 1000px)': {
            '& video': {
                maxHeight: '70vh'
            }
        },
        '@media (min-width: 1600px)': {
            '& video': {
                maxHeight: '75vh'
            }
        }
    },
    dialogContent: {
        backgroundColor: '#F0F4F8',
        padding: 8,
        '&:first-child': {
            paddingTop: 8 // Override default top padding
        }
    }
}));

const Actions = ({ onCancel, onSaveHighlight, isSavingHighlight }) => {
    const { t } = useTranslation();

    const {
        clipStartTime, clipEndTime, highlightTitle, highlightDescription,
        errors, pageNumber, setPageNumber
    } = useContext(VideoTrimmerContext);

    return (
        <DialogActions>
            <Collapse orientation="horizontal" in={pageNumber === 1}>
                <Button
                    onClick={onCancel}
                    size="large"
                    variant="outlined"
                    endIcon={<CloseIcon />}
                >
                    { t('common.buttons.cancel') }
                </Button>
            </Collapse>

            <Collapse orientation="horizontal" in={pageNumber > 1}>
                <Button
                    variant="outlined"
                    size="large"
                    disabled={errors.length > 0 || isSavingHighlight}
                    onClick={() => setPageNumber(pageNumber - 1)}
                    startIcon={<ArrowBackIcon />}
                >
                    { t('sessionHighlights.buttons.back') }
                </Button>
            </Collapse>

            <Collapse orientation="horizontal" in={pageNumber < 2}>
                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={errors.length > 0 || isSavingHighlight}
                    onClick={() => setPageNumber(pageNumber + 1)}
                    endIcon={<ArrowForwardIcon />}
                >
                    { t('sessionHighlights.buttons.next') }
                </Button>
            </Collapse>

            <Collapse orientation="horizontal" in={pageNumber === 2}>
                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={errors.length > 0 || isSavingHighlight}
                    onClick={() => {
                        console.log({clipStartTime, clipEndTime, highlightTitle, highlightDescription});
                        onSaveHighlight(clipStartTime, clipEndTime, highlightTitle, highlightDescription);
                    }}
                    sx={{ whiteSpace: 'nowrap' }}
                    endIcon={<CheckIcon />}
                >
                    { t('sessionHighlights.buttons.saveHighlight') }
                </Button>
            </Collapse>
        </DialogActions>
    );
};

Actions.propTypes = {
    onSaveHighlight: PropTypes.func,
    isSavingHighlight: PropTypes.bool,
    onCancel: PropTypes.func,
    page: PropTypes.number
};

const CreateSessionHighlightModal = ({
    isOpen, onClose, videoRecordingUrl, clipStartTime, recordingId, onHighlightCreated, clipEndTime, forceInline
}) => {
    const classes = useStyles();

    const initialState = {
        videoRecordingUrl: videoRecordingUrl,
        clipStartTime,
        clipEndTime
    };

    const { mutate: createHighlight, isLoading: isSavingHighlight } = useCreateHighlightRequest();

    if (!isOpen) {
        return null;
    }

    const onSaveHighlight = (startTime, endTime, title, description) => {
        createHighlight({ recordingId, startTime, endTime, title, description }, {
            onSuccess: () => onHighlightCreated?.()
        });
    };

    return (
        <VideoTrimmerProvider initialState={initialState}>
            <Dialog
                open={isOpen}
                maxWidth={false}
                scroll={'body'}
                className={classes.root}
                disablePortal={forceInline}
                disableScrollLock={forceInline}
            >
                <DialogContent className={classes.dialogContent}>
                    <VideoTrimmer />
                </DialogContent>

                <Actions onCancel={onClose} onSaveHighlight={onSaveHighlight} isSavingHighlight={isSavingHighlight} pageTwo />
            </Dialog>
        </VideoTrimmerProvider>
    );
};

CreateSessionHighlightModal.propTypes = {
    recordingId: PropTypes.string.isRequired,
    clipStartTime: PropTypes.number,
    clipEndTime: PropTypes.number,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    videoRecordingUrl: PropTypes.string.isRequired,
    onHighlightCreated: PropTypes.func,
    forceInline: PropTypes.bool
};

export default CreateSessionHighlightModal;

import { useQuery } from 'react-query';
import portalApi from 'utils/portalApi';

const getProjectActivities = async (projectId) => {
    const { data } = await portalApi.get(`api/activities?projectId=${projectId}`);
    return data;
};

const useProjectActivities = (projectId) => {
    const { data, refetch, isLoading } = useQuery(
        ['projectActivities', projectId],
        () => getProjectActivities(projectId)
    );

    return { data, refetch, isLoading };
};

export default useProjectActivities;

import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import EventIcon from '@mui/icons-material/Event';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ArchiveIcon from '@mui/icons-material/Archive';

const useStyles = makeStyles(theme => ({
    runningActivitiesCountChip: {
        ...theme.projectStatusChip.default,
        ...theme.projectStatusChip.runningActivities
    },
    upcomingActivitiesCountChip: {
        ...theme.projectStatusChip.default
    },
    archivedChip: {
        ...theme.projectStatusChip.default
    }
}));

const ProjectStatusList = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { runningActivitiesCount, upcomingActivitiesCount, isArchived } = props.project;
    const projectId = props.project.id;

    var listItems = [];

    if (runningActivitiesCount > 0) {
        listItems.push(
            <div className={classes.runningActivitiesCountChip} key={`RunningActivitiesCount-${projectId}`}>
                <DirectionsRunIcon />
                <Typography variant="body2">
                    {t('projectPage.labels.totalRunningActivities', { count: runningActivitiesCount })}
                </Typography>
            </div>
        );
    }

    if (upcomingActivitiesCount > 0) {
        listItems.push(
            <div className={classes.upcomingActivitiesCountChip} key={`UpcomingActivitiesCount-${projectId}`}>
                <EventIcon />
                <Typography variant="body2">
                    {t('projectPage.labels.totalUpcomingActivities', { count: upcomingActivitiesCount })}
                </Typography>
            </div>
        );
    }

    if (isArchived) {
        listItems.push(
            <div className={classes.archivedChip} key={`ArchivedActivitiesCount-${projectId}`}>
                <ArchiveIcon />
                <Typography variant="body2">
                    {t('companyPage.text.archivedProject')}
                </Typography>
            </div>
        );
    }

    const renderList = () => {
        return (
            <div style={{ ...props.style }}>
                { listItems.reverse().map(item => item) }
            </div>
        );
    };

    return (
        listItems.length > 0 ? renderList() : null
    );
};

export default ProjectStatusList;

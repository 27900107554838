import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { handleAuthenticationError } from 'utils/portalApi';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';

// Internationalisation
import './i18n';

// Date/Timepickers
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

// Application State (Redux)
import { Provider } from 'react-redux';
import PortalStore from './redux/store';
import { CircularProgress } from '@mui/material';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            notifyOnChangeProps: 'tracked',
        },
    },
    queryCache: new QueryCache({
        onError: async (error) => {
            if (error.response?.status === 401) {
                handleAuthenticationError();
            }
        },
    }),
});

// // Enable API mocking when running in dev
// if (process.env.NODE_ENV === "development") {
//     const { worker } = require("./mocks/browser");
//     worker.start();
// }

const Loading = () => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#f5f5f5'
        }}>
            <CircularProgress style={{ color: '#c20202' }}/>
        </div>
    );
};

// Unregister service workers (no further SWs will be registered at this time due to
// a couple of issues).
if (navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
            registration.unregister();
        }
    });
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
    <Suspense fallback={<Loading />}>
        <QueryClientProvider client={queryClient}>
            <Provider store={PortalStore}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <App />
                </LocalizationProvider>
            </Provider>
        </QueryClientProvider>
    </Suspense>);

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Alert, AlertTitle, Autocomplete, Box, Button, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { ArrowForward, UploadFile } from '@mui/icons-material';
import {parse} from 'csv-parse/browser/esm/sync';
import _ from 'lodash';
import useParticipantsForActivity from 'api-new/useParticipantsForActivity';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import { DateTime, Duration } from 'luxon';
import vlqrApi from 'utils/vlqrApi';
import { useMutation } from 'react-query';
import useFocusGroupSchedule from 'api-new/useFocusGroupSchedule';

// Note that translations haven't been specified as of yet. The tool is aimed at internal users (dev/test) only and
// would be revisited design-wise should we target external users.

const VoiceTranscriptionsImporter = ({ focusGroupId }) => {
    const [importDidSucceed, setImportDidSucceed] = useState(false);
    const { data: focusGroupSchedule, isLoading: isLoadingFocusGroupSchedule } = useFocusGroupSchedule(focusGroupId);
    const { data: focusGroupParticipants, isLoading: isLoadingParticipants } = useParticipantsForActivity(focusGroupId);
    const { mutate: replaceVoiceTranscriptions, isLoading: isProcessingRequest } = useMutation(voiceTranscriptions => {
        return vlqrApi.post(`focusGroups/${focusGroupId}/voiceTranscriptions`, voiceTranscriptions);
    }, {
        onSuccess: () => {
            setImportDidSucceed(true);
        }
    });

    const [filename, setFilename] = useState(null);
    const [csvData, setCsvData] = useState(null);
    const transcriptParticipants = _.uniq(csvData?.map(x => x.User));
    const [participantMapping, setParticipantMapping] = useState({});

    // Set via the UI to offset the transcripts. Positive integer indicates how many minutes
    // to subtract from each timestamp.
    const [transcriptMinutesOffset, setTranscriptMinutesOffset] = useState(0);
    const transcriptOffsetDuration = Duration.fromMillis(transcriptMinutesOffset * 60 * 1000);

    const handleFileUpload = (event, b, c, d) => {
        if(!event.target.files) {
            return;
        }

        const file = event.target.files[0];
        const { name } = file;
        setFilename(name);

        const reader = new FileReader();

        reader.onload = (evt) => {
            if (!evt?.target?.result) {
                return;
            }

            // Read in the data, stripping out any BOMs that are present.
            const rawData = evt.target.result.replace(/^\uFEFF/, '');

            const records = parse(rawData, {
                bom: true,
                columns: true, // ['Time', 'User', 'Transcript']
                delimiter: ',',
                trim: true,
                skip_empty_lines: true
            });

            setCsvData(records);
        };

        reader.readAsText(file);
    };

    if (isLoadingParticipants || isLoadingFocusGroupSchedule) {
        return <LoadingPlaceholder />;
    }

    const focusGroupParticipantIds = focusGroupParticipants.map(x => x.projectParticipantId);
    const focusGroupParticipantsAsObject = _.keyBy(focusGroupParticipants, 'projectParticipantId');
    const isParticipantMapIncomplete = Boolean(transcriptParticipants.find(x => Boolean(participantMapping[x]) === false));

    // Populate imported transcripts using the session start time as a baseline (ensures that they will be included in reports)
    // Each transcript timestamp will then be adjusted by the same amount
    // Note that transcripts may be adjusted further via 'transcriptMinutesOffset'.
    const openingTranscriptDateTime = csvData?.[0] ? DateTime.fromFormat(csvData?.[0].Time, 'M/d/y h:m:s a') : null;
    const openingTranscriptDateDiff = openingTranscriptDateTime
        ? DateTime.fromISO(focusGroupSchedule.actualStartTime).diff(openingTranscriptDateTime)
        : null;

    const handleSubmit = () => {
        const payload = csvData.map(row => {
            // Dates in csv formatted as follows: e.g. `6/26/2024 9:28:19 PM`
            var timestamp = DateTime.fromFormat(row.Time, 'M/d/y h:m:s a');

            if (openingTranscriptDateDiff) {
                timestamp = timestamp.plus(openingTranscriptDateDiff).minus(transcriptOffsetDuration);
            }

            return {
                participantId: participantMapping[row.User],
                timestamp: timestamp.toUTC().toISO(),
                text: row.Transcript,
                translatedText: null // ignored for now
            };
        });

        replaceVoiceTranscriptions(payload);
    };

    if (importDidSucceed) {
        return (
            <Alert severity='success'>
                <AlertTitle>Done!</AlertTitle>
                Voice transcriptions were imported successfully.
            </Alert>
        );
    }

    return (
        <Stack gap={3}>
            <Box>
                <Typography variant="h6" gutterBottom>Import Transcriptions</Typography>
                <Typography variant="body1">Please be aware of the limitations of this tool. Should only be used by the dev team for now due to limited validation and error handling.</Typography>
            </Box>

            <Button
                size="large"
                component="label"
                variant="outlined"
                startIcon={<UploadFile />}
                color={csvData ? 'success' : 'newPrimary' }
            >
                { filename ?? 'Upload CSV' }
                <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
            </Button>

            { transcriptParticipants.length > 0 &&
                <Stack gap={2} sx={{ padding: 2, border: 'thin solid grey', borderColor: 'grey.9', borderRadius: 1 }}>
                    <Typography variant="overline">Map Participants</Typography>
                    <Typography variant="body2">Use the fields below to map transcript names to participants within this session</Typography>
                    <Stack gap={1}>
                        { transcriptParticipants.map(participantName => (
                            <Stack
                                key={`participant-mapper-item-${participantName}`}
                                direction='row'
                                alignItems='center'
                                gap={1}
                            >
                                <Typography variant="subtitle1">
                                    { participantName }
                                </Typography>

                                <ArrowForward />

                                <Autocomplete
                                    size='small'
                                    sx={{ minWidth: '20em' }}
                                    options={focusGroupParticipantIds}
                                    getOptionLabel={(participantId) => focusGroupParticipantsAsObject[participantId].name}
                                    renderInput={(params) => <TextField {...params} placeholder="Choose Target Participant" />}
                                    onChange={(event, value) => setParticipantMapping({ ...participantMapping, [participantName]: value }) }
                                    renderOption={(props, participantId) => {
                                        return (
                                            <li {...props} key={`targetParticipant-list-item-${participantId}`}>
                                                {focusGroupParticipantsAsObject[participantId].name}
                                            </li>
                                        );
                                    }}
                                />
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            }

            <Stack gap={2} sx={{ padding: 2, border: 'thin solid grey', borderColor: 'grey.9', borderRadius: 1 }}>
                <Typography variant="overline">Session Start Time Adjustment</Typography>
                <Typography variant="body2">
                    The timestamp of the first transcript found in the CSV will be overridden to match the ActualStartTime of this session. Other transcripts are then updated such that they remain relative to the first.
                </Typography>

                <Typography variant="body2">
                    This means that by default, all imported transcripts will be included when e.g. generating AI reports.
                </Typography>

                <Typography variant="body2">
                    To customise this, specify a value (in minutes) below which will be subtracted from each timestamp and so simulate a period of &apos;pre-session&apos; discussion, i.e. before the first respondent joined.
                </Typography>

                <Stack direction='row' alignItems='center' gap={1}>
                    <TextField
                        value={transcriptMinutesOffset}
                        onChange={(e) => setTranscriptMinutesOffset(e.target.value)}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                        }}
                    />
                </Stack>
            </Stack>

            { transcriptParticipants.length > 0 &&
                <Button
                    variant="contained"
                    sx={{ width: '10em' }}
                    disabled={isParticipantMapIncomplete || isProcessingRequest}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            }
        </Stack>
    );
};

VoiceTranscriptionsImporter.propTypes = {
    focusGroupId: PropTypes.string
};

export default VoiceTranscriptionsImporter;

import PropTypes from 'prop-types';
import React from 'react';
import ProductType, { AVSessionUmbrella } from '../../enums/ProductType';
import CompanyRole from '../../enums/CompanyRole';
import { Alert } from '@mui/material';
import ActivityStatus from '../../enums/ActivityStatus';

const TranslatorAccessCodeWarning = ({ activity, companyRole }) => {
    if (!activity) {
        return null;
    }

    // Only show for AV activities
    if (!AVSessionUmbrella.includes(ProductType[activity.details.activityType])) {
        return null;
    }

    // Don't show if the activity has a translator access code already.
    if (activity?.links.genericTranslatorLink) {
        return null;
    }

    // Only show to staff members
    if (companyRole !== CompanyRole.vlStaff) {
        return null;
    }

    const getWarningMessage = () => {
        if (ActivityStatus[activity?.details.detailedStatus] === ActivityStatus.running) {
            return (
                <Alert key="VL-Missing-Translator-Access-Code-Warning" severity="error">
                    This activity does not support Translator features.
                    These features cannot be enabled on a <i>Running</i> session.
                </Alert>
            );
        } else {
            return (
                <Alert key="VL-Missing-Translator-Access-Code-Warning" severity="error">
                    This activity does not support Translator features.

                    To enable, open <b>Edit Details</b> <i>(Activity Title, Private description, etc.)</i> and
                    click <b>Save</b>
                </Alert>
            );
        }
    };

    return (
        <div style={{ padding: 16 }}>
            { getWarningMessage() }
        </div>
    );
};

TranslatorAccessCodeWarning.propTypes = {
    activity: PropTypes.object,
    companyRole: PropTypes.instanceOf(CompanyRole)
};

export default TranslatorAccessCodeWarning;

import React, { useState, useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';import { NoActivitiesFound } from 'components/project/activities/EmptyActivitiesPlaceholder';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import ActivitiesTableView from './TableView';
import CreateActivityModal from 'components/project/activities/CreateActivityModal';
import { Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import CompanyRole from 'enums/CompanyRole';
import CheckboxGroup from 'components/shared/CheckboxGroup';
import ActivityStatus from 'enums/ActivityStatus';
import useCompany from 'api/useCompany';
import useProjectActivities from 'api/useProjectActivites';

const COMPLETED_STATUSES = [
    ActivityStatus.completed,
    ActivityStatus.didNotStart
];

const UPCOMING_STATUSES = [
    ActivityStatus.readyToStart,
    ActivityStatus.upcoming,
    ActivityStatus.cannotStart,
    ActivityStatus.running
];

const useGroupedActivities = (projectId) => {
    const { data: allActivities, refetch } = useProjectActivities(projectId);

    const groupedActivities = allActivities?.reduce((result, activity) => {
        if (result[activity.status]) {
            result[activity.status].push(activity);
        } else {
            result[activity.status] = [activity];
        }

        return result;
    }, {});

    return {
        allActivities,
        allStatuses: groupedActivities ? Object.keys(groupedActivities) : [],
        refetch
    };
};

const ActivitiesTab = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const {
        companyId,
        projectId
    } = useOutletContext();
    const { allActivities, allStatuses, refetch } = useGroupedActivities(projectId);
    const [isCreatingActivity, setIsCreatingActivity] = useState(false);
    const { data: selectedCompany } = useCompany(companyId);
    const [excludedStatuses, setExcludedStatuses] = useState([]);

    const companyRole = CompanyRole[selectedCompany?.role];
    const visibleStatuses = allStatuses.filter(x => !excludedStatuses.includes(x));

    const byStatusFilter = (activity) => {
        return !excludedStatuses.includes(activity.status);
    };

    const byScheduledStartDate = (a, b) => {
        if (a.startDate === b.startDate) {
            return 0;
        }

        return a.startDate > b.startDate || a.startDate === null ? 1 : -1;
    };

    const getActivities = () => {
        return allActivities
            .filter(byStatusFilter)
            .sort(byScheduledStartDate);
    };

    const onCreateActivityButtonClick = useCallback(() => {
        setIsCreatingActivity(true);
    }, []);

    const renderActivities = () => {
        if (!allActivities) {
            return (<LoadingPlaceholder height={'auto'} />);
        } else if (getActivities().length === 0) {
            return <NoActivitiesFound onCreateActivityButtonClick={onCreateActivityButtonClick} />;
        } else {
            return (
                <React.Fragment>
                    <ActivitiesTableView
                        activities={
                            getActivities()
                                .filter(activity => UPCOMING_STATUSES.includes(ActivityStatus[activity.status]))
                        }
                        companyId={companyId}
                        projectId={projectId}
                        companyRole={companyRole}
                    />

                    <ActivitiesTableView
                        activities={
                            getActivities()
                                .filter(activity => ActivityStatus[activity.status] === ActivityStatus.draft)
                        }
                        companyId={companyId}
                        projectId={projectId}
                        companyRole={companyRole}
                    />

                    <ActivitiesTableView
                        activities={
                            getActivities()
                                .filter(activity => COMPLETED_STATUSES.includes(ActivityStatus[activity.status]))
                                .reverse() // display past activities in reverse chronological order
                        }
                        companyId={companyId}
                        projectId={projectId}
                        companyRole={companyRole}
                        onActivityModified={() => {
                            refetch();
                        }}
                    />
                </React.Fragment>
            );
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1, padding: 32 }}>
                { renderActivities() }
            </div>

            <div style={{ minWidth: 320, width: 320, padding: 32 }}>
                { companyRole.canCreateActivity &&
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<AddIcon />}
                        onClick={onCreateActivityButtonClick}
                        style={{ marginBottom: 16 }}
                    >
                        { t('projectPage.buttons.createNewActivity') }
                    </Button>
                }

                <div style={{ padding: 16, background: theme.palette.background.default, borderRadius: 8 }}>
                    <CheckboxGroup
                        label={'Filter by Status'}
                        selected={visibleStatuses}
                        options={allStatuses.map(status => ({ value: status, label: t(`activityStatuses.text.${status}`) }))}
                        onChange={(selected) => {
                            setExcludedStatuses(allStatuses.filter(x => !selected.includes(x)));
                        }}
                    />
                </div>
            </div>

            <CreateActivityModal
                companyId={companyId}
                projectId={projectId}
                heading={'New Activity'}
                open={isCreatingActivity}
                onSuccess={(newActivity) => {
                    const newActivityId = newActivity.details.activityId;
                    navigate(`activities/${newActivityId}`);
                }}
                onCancel={() => {
                    refetch();
                    setIsCreatingActivity(false);
                }} />
        </div>
    );
};

export default ActivitiesTab;

import PropTypes from 'prop-types';
import { Button, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowDropDown } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Interval } from 'luxon';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToolbarSpacer from 'components/shared/ToolbarSpacer';
import { ActivityRecordingContext, DEFAULT_FILTER } from './ActivityRecordingProvider';
import getSentimentIcon from './SentimentIcons/getSentimentIcon';
import deepEqual from 'deep-equal';

const useStyles = makeStyles(theme => ({
    root: {
        width: 280,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#102A43',
        overflow: 'auto',
        '& .MuiTypography-h2': {
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '0.4px',
            color: '#F0F4F8'
        },
        '& .MuiIconButton-root': {
            color: '#F0F4F8'
        },
        '& .MuiButton-root': {
            color: '#F0F4F8'
        },
        '& .VL-EventsContainer': {
            paddingTop: 0,
            padding: 16,
            '& .VL-Event': {
                backgroundColor: '#243B53',
                padding: 12,
                borderRadius: 4,
                marginBottom: 8,
                '&.VL-Event-Active': {
                    boxShadow: '0px 0px 6px rgba(255, 255, 255, 0.7)'
                },
                '& .VL-EventInfo': {
                    alignItems: 'baseline',
                    display: 'flex',
                    color: '#829AB1',
                    '& .MuiTypography-body1': {
                        fontSize: 14
                    },
                    '& .MuiLink-root': {
                        color: '#F0F4F8',
                        cursor: 'pointer',
                        fontWeight: 500,
                        '&:hover': {
                            color: 'white'
                        }
                    },
                    '& .VL-SentimentIconContainer': {
                        width: 18,
                        height: 'auto'
                    }
                },
                '& .VL-EventBody': {
                    marginTop: 8,
                    color: '#D9E2EC'
                }
            }
        }
    }
}));

const useFilterModes = () => {
    const { t } = useTranslation();

    return {
        ALL: {
            label: t('activityRecordingViewer.text.allReactions'),
            filterSettings: {
                ...DEFAULT_FILTER
            }
        },
        MODERATOR_ONLY: {
            label: t('activityRecordingViewer.text.moderatorReactionsOnly'),
            filterSettings: {
                ...DEFAULT_FILTER,
                showObserverNotes: false,
                showRespondentReactions: false
            }
        },
        OBSERVER_ONLY: {
            label: t('activityRecordingViewer.text.observerReactionsOnly'),
            filterSettings: {
                ...DEFAULT_FILTER,
                showModeratorNotes: false,
                showRespondentReactions: false
            }
        },
        RESPONDENT_ONLY: {
            label: t('activityRecordingViewer.text.respondentReactionsOnly'),
            filterSettings: {
                ...DEFAULT_FILTER,
                showModeratorNotes: false,
                showObserverNotes: false
            }
        }
    };
};

const Event = ({ event, videoRecordingStartDate, isActive, onEventTimestampClick }) => {
    const eventData = JSON.parse(event.data);

    if (!videoRecordingStartDate) {
        return null;
    }

    const eventDateTime = DateTime.fromISO(event.sendDate);
    const startSeconds = Interval.fromDateTimes(videoRecordingStartDate, eventDateTime).toDuration('seconds');

    // Protect against negative durations (this would include comments added before the session started).
    if (isNaN(startSeconds)) {
        return null;
    }

    const formattedTime = startSeconds.as('hours') > 1 ?
        startSeconds.toFormat('hh:mm:ss') :
        startSeconds.toFormat('mm:ss');

    return (
        <div className={`VL-Event ${isActive ? 'VL-Event-Active' : ''}`}>
            <div className={'VL-EventInfo'}>
                <Link
                    onClick={() => onEventTimestampClick()}
                    underline="hover"
                    variant="body1"
                >
                    { formattedTime }
                </Link>

                <ToolbarSpacer />
                <Typography variant="body1">{ event.senderPsuedonym ?? event.senderFullName }</Typography>

                <ToolbarSpacer grow />
                <div className={'VL-SentimentIconContainer'}>
                    { getSentimentIcon(eventData.Sentiment) }
                </div>
            </div>

            { eventData.Note &&
                <div className={'VL-EventBody'}>
                    { eventData.Note }
                </div>
            }
        </div>
    );
};
Event.propTypes = {
    event: PropTypes.object,
    isActive: PropTypes.bool,
    videoRecordingStartDate: PropTypes.instanceOf(DateTime),
    onEventTimestampClick: PropTypes.func
};

const ReactionsPane = () => {
    const classes = useStyles();
    const filterModes = useFilterModes();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const {
        videoRecordingStartDate,
        noteEvents,
        reactionsPaneIsOpen,
        setReactionsPaneIsOpen,
        skipToEvent,
        activeNoteEvents,
        filterSettings, setFilterSettings
    } = useContext(ActivityRecordingContext);

    if (!reactionsPaneIsOpen) {
        return null;
    }

    const currentFilterMode = Object.values(filterModes).find(filterMode => {
        return deepEqual(filterMode.filterSettings, filterSettings);
    });

    return (
        <div className={classes.root}>
            <Toolbar>
                <Button
                    edge="start"
                    onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                    endIcon={<ArrowDropDown />}
                >
                    { currentFilterMode.label }
                </Button>

                <ToolbarSpacer grow />

                <IconButton edge="end" onClick={() => setReactionsPaneIsOpen(false)} size="large">
                    <HighlightOffIcon />
                </IconButton>
            </Toolbar>

            <div className={'VL-EventsContainer'}>
                { noteEvents.map(e =>
                    (<Event
                        key={`VL-EventsContainer-Event-${e.id}`}
                        event={e}
                        videoRecordingStartDate={videoRecordingStartDate}
                        onEventTimestampClick={() => skipToEvent(e.id)}
                        isActive={Boolean(activeNoteEvents.find(activeEvent => activeEvent.id === e.id))}
                    />))
                }
            </div>

            <Menu open={Boolean(menuAnchorEl)} anchorEl={menuAnchorEl} onClose={() => setMenuAnchorEl(null)}>
                { Object.values(filterModes).map(mode => (
                    <MenuItem
                        key={mode.label}
                        selected={mode.label === currentFilterMode.label}
                        disabled={mode.label === currentFilterMode.label}
                        onClick={() => {
                            setFilterSettings(mode.filterSettings);
                            setMenuAnchorEl(null);
                        }}
                    >
                        { mode.label }
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ReactionsPane;

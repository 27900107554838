const AiSummaryReportStatus = {
    // Note: 'None' is used only by the frontend to indicate that no reports exist
    // for a given session.
    NONE: 'None',
    PROCESSING: 'Processing',
    SUCCESS: 'Success',
    ERROR: 'Error'
};

export default AiSummaryReportStatus;

import { Enumify } from 'enumify';

class ActivityStatus extends Enumify {
    static running = new ActivityStatus({
        shouldShowLinks: true,
        shouldShowStatus: true,
        defaultAvatarColor: '#6bb75a',
        allowsDeletion: false
    });

    static readyToStart = new ActivityStatus({
        shouldShowLinks: true,
        shouldShowStatus: true,
        defaultAvatarColor: '#599B75',
        allowsDeletion: false
    });

    static upcoming = new ActivityStatus({
        shouldShowLinks: true,
        shouldShowStatus: true,
        defaultAvatarColor: '#599B75',
        allowsDeletion: true
    });

    static completed = new ActivityStatus({
        defaultAvatarColor: '#196de2',
        allowsDeletion: true,
        shouldShowReports: true
    });

    static cannotStart = new ActivityStatus({
        shouldShowLinks: true,
        shouldShowStatus: true,
        defaultAvatarColor: '#F44336',
        allowsDeletion: true
    });

    static didNotStart = new ActivityStatus({
        defaultAvatarColor: '#F44336',
        allowsDeletion: true
    });

    static draft = new ActivityStatus({
        defaultAvatarColor: '#ddd',
        allowsDeletion: true,
        canLinkToActivity: false
    });

    static _ = this.closeEnum();

    constructor (options) {
        super();

        // Determines whether the 'links' page should be shown
        this.shouldShowLinks = options?.shouldShowLinks;

        // Determines whether the 'status' info page should be shown.
        this.shouldShowStatus = options?.shouldShowStatus;

        // The default avatar colour for this status
        this.defaultAvatarColor = options?.defaultAvatarColor;

        // Whether or not an activity can be deleted when in this status
        this.allowsDeletion = options?.allowsDeletion ?? false;

        // Determines whether links to the activity can be used.
        // These are located under Overview > Actions
        this.canLinkToActivity = options?.canLinkToActivity ?? true;

        // Determines whether the 'reports' page should be shown
        this.shouldShowReports = options?.shouldShowReports ?? false;
    }
}

export default ActivityStatus;

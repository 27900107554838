import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// When populating the list below, use the appropriate
// value from the following:
// https://www.omniglot.com/language/names.htm
export const SUPPORTED_LANGUAGES = {
    ar: 'العربية',
    en: 'English'
};

i18n
    .use(initReactI18next)
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        backend: {
            loadPath: `/langs/{{lng}}.json?v=${process.env.REACT_APP_VERSION}`
        },
        compatibilityJSON: 'v3',
        supportedLngs: Object.keys(SUPPORTED_LANGUAGES),
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false // react already protects from xss
        }
    });

export default i18n;

import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as FocusGroupIcon } from './FocusGroupIcon.svg';
import { ReactComponent as IDIIcon } from './IDIIcon.svg';
import { ReactComponent as HeatmapIcon } from './HeatmapIcon.svg';
import { ReactComponent as HatchIcon } from './HatchIcon.svg';
import { ReactComponent as VideoDialIcon } from './VideoDialIcon.svg';
import { ReactComponent as BoardsIcon } from './BoardsIcon.svg';

import ProductType, { FocusGroupUmbrella, InDepthInterviewUmbrella } from '../../../enums/ProductType';

const ActivityIcon = (props) => {
    const foregroundColor = props.foregroundColor ?? 'white';

    const renderActivityIcon = () => {
        if (FocusGroupUmbrella.includes(props.productType)) {
            return (<FocusGroupIcon fill={foregroundColor} style={{ width: '60%', height: 'auto' }} />);
        } else if (InDepthInterviewUmbrella.includes(props.productType)) {
            return (<IDIIcon fill={foregroundColor} style={{ width: '60%', height: 'auto', marginTop: '-5%' }} />);
        } else if (props.productType === ProductType.heatmapConceptTest) {
            return (<HeatmapIcon fill={foregroundColor} style={{ width: '60%', height: 'auto' }} />);
        } else if (props.productType === ProductType.videodialConceptTest) {
            return (<VideoDialIcon fill={foregroundColor} style={{ width: '60%', height: 'auto' }} />);
        } else if (props.productType === ProductType.brainstorm) {
            return (<HatchIcon fill={foregroundColor} style={{ width: '60%', height: 'auto' }} />);
        } else if (props.productType === ProductType.bulletinBoard) {
            return (<BoardsIcon fill={foregroundColor} style={{ width: '60%', height: 'auto' }} />);
        } else {
            return null;
        }
    };

    return renderActivityIcon();
};

ActivityIcon.propTypes = {
    foregroundColor: PropTypes.string,
    productType: PropTypes.instanceOf(ProductType)
};

export default React.memo(ActivityIcon);

import { StrictFocusGroupUmbrella } from 'enums/ProductType';

export const createSetupRelativeURL = (productType, projectId, activityId) => {
    if (StrictFocusGroupUmbrella.includes(productType)) {
        return `/xx/${projectId}/FocusGroupWizard/PricingStep/${activityId}`;
    } else {
        return `/xx/${projectId}/${productType.urlComponent}/Setup/${activityId}`;
    }
};

export const createRescheduleRelativeURL = (productType, projectId, activityId) => {
    if (StrictFocusGroupUmbrella.includes(productType)) {
        return `/xx/${projectId}/${productType.urlComponent}/Reschedule/${activityId}`;
    } else {
        return `/xx/${projectId}/${productType.urlComponent}/Setup/${activityId}#/Schedule`;
    }
};

export const createLinksRelativeURL = (productType, projectId, activityId) => {
    return `/xx/${projectId}/${productType.urlComponent}/Links/${activityId}`;
};

export const createStatusRelativeURL = (productType, projectId, activityId) => {
    return `/xx/${projectId}/${productType.urlComponent}/Status/${activityId}`;
};

export const createReportsRelativeURL = (productType, projectId, activityId) => {
    if (StrictFocusGroupUmbrella.includes(productType)) {
        return `/xx/${projectId}/Report/Details/${activityId}`;
    } else {
        return `/xx/${projectId}/${productType.urlComponent}/Report/${activityId}`;
    }
};

export const createFocusGroupsReportsAreaRelativeURL = (productType, projectId, activityId, area) => {
    return `/xx/${projectId}/Report/${area}/${activityId}`;
};

export const FOCUS_GROUPS_REPORTS_AREAS = {
    Details: 'Details',
    VoiceTranscriptions: 'VoiceTranscriptions'
};

import { Badge, IconButton, Popover, Tooltip } from '@mui/material';
import React from 'react';
import { Campaign } from '@mui/icons-material';
import ReleaseNotesViewPanel from './ReleaseNotesViewPanel';
import useReleaseNotes from 'api-new/useReleaseNotes';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';


const ReleaseNotesNotice = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();
    const releaseNotesEntries = useReleaseNotes();
    const [lastReadReleaseNote, setLastReadReleaseNote] = useLocalStorage('visionslive-portal-lastReadReleaseNote');
    const releaseNotesHaveLoaded = !releaseNotesEntries.isLoading && !releaseNotesEntries.isError;
    const latestReleaseNoteDate = releaseNotesHaveLoaded ? new Date(releaseNotesEntries.data[0]?.publishedAt) : null;
    const hasNewReleaseNotes = lastReadReleaseNote == null || new Date(lastReadReleaseNote) < latestReleaseNoteDate;
    const tooltipTranslationCode = hasNewReleaseNotes ? 'topBar.tooltips.releaseNotesUpdated' : 'userMenu.buttons.openReleaseNotes';

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setLastReadReleaseNote(latestReleaseNoteDate);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return <>
        <Tooltip arrow title={t(tooltipTranslationCode)}>
            <IconButton sx={{ marginRight: 2 }} onClick={handleClick}>
                <Badge variant='dot' invisible={!hasNewReleaseNotes} color='error'>
                    <Campaign fontSize="inherit" />
                </Badge>
            </IconButton>
        </Tooltip>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            slotProps={{ paper: { sx: { padding: 1 } } }}
        >
            <ReleaseNotesViewPanel />
        </Popover>
    </>;
};

export default ReleaseNotesNotice;

import React from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ProjectStatusList from './StatusList';
import { Link, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        '& .VL-ProjectRow': {
            borderBottom: `2px solid ${theme.dividers.contentDivider.backgroundColor}`,
            '&:last-child': {
                border: 'none'
            }
        }
    }
}));

const useInfoIconStyles = makeStyles(theme => ({
    root: {
        ...theme.projectInfoIcon
    }
}));

const ProjectRow = (props) => {
    const infoIconStyle = useInfoIconStyles();
    const theme = useTheme();
    const { project, companyId } = props;

    return (
        <div className="VL-ProjectRow" style={{ display: 'flex', alignItems: 'center', padding: 16 }}>
            <div style={{ flexGrow: 1, display: 'flex' }}>
                <div>
                    <Link
                        to={`/company/${companyId}/project/${project.id}/activities`}
                        color="primary"
                        component={RouterLink}
                        variant="subtitle1"
                        underline="hover"
                        style={{ ...theme.typography.primaryItemLink }}
                    >
                        { project.name }
                    </Link>
                    <Typography variant="subtitle2">{ project.client }</Typography>
                </div>

                { project.description &&
                    <div style={{ marginLeft: 8 }}>
                        <Tooltip title={project.description ?? ''} placement="top" arrow>
                            <InfoOutlinedIcon className={infoIconStyle.root} />
                        </Tooltip>
                    </div>
                }
            </div>
            <ProjectStatusList project={project} style={{ marginLeft: 8 }} />
        </div>
    );
};

const ProjectsTableView = (props) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root} variant="outlined">
            {props.projects.map((project) => (
                <ProjectRow
                    key={`project-row-${project.id}`}
                    companyId={props.companyId}
                    project={project} />
            ))}
        </Paper>
    );
};

export default ProjectsTableView;

import PropTypes from 'prop-types';
import { CircularProgress, ListItemIcon, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import portalApi from 'utils/portalApi';
import CreditScoreIcon from '@mui/icons-material/CreditScore';

const MarkCompletedSessionsAsBilledMenuItem = ({ projectId, onComplete }) => {
    const [isProcessingRequest, setIsProcessingRequest] = useState(false);

    const markCompletedSessionsAsBilled = () => {
        setIsProcessingRequest(true);

        portalApi
            .post(`api/projects/${projectId}/markCompletedSessionsAsBilled`)
            .then(response => {
                // refetch activities via 'useProjectActivities' hook,
                // this should trigger re-render inside ActivitiesTab
                setIsProcessingRequest(false);
                onComplete?.();
            });
    };

    return (
        <MenuItem
            onClick={markCompletedSessionsAsBilled}
            disabled={isProcessingRequest}
        >
            <ListItemIcon>
                { isProcessingRequest
                    ? <CircularProgress size={20} variant="indeterminate" />
                    : <CreditScoreIcon />
                }
            </ListItemIcon>

            Mark Completed Activities as Billed
        </MenuItem>
    );
};

MarkCompletedSessionsAsBilledMenuItem.propTypes = {
    onComplete: PropTypes.func,
    projectId: PropTypes.string.isRequired
};

export default MarkCompletedSessionsAsBilledMenuItem;

import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getStimuli = async (activityId) => {
    const { data } = await vlqrApi.get(`reports/${activityId}/stimuli`)
        .catch((error) => {
            handleError(error);
        });
    return data;
};

const useStimuliForFocusGroup = (focusGroupId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['stimuliForFocusGroup', focusGroupId],
        () => getStimuli(focusGroupId)
    );

    return { data, refetch, isLoading, isError };
};

export default useStimuliForFocusGroup;
import React from 'react';
import PropTypes from 'prop-types';
import PortalIFrame from 'components/shared/PortalIFrame';
import { useNavigate, useOutletContext } from 'react-router-dom';

const CompanyIFrame = ({id, relativeUrlStub}) => {
    const { companyId } = useOutletContext();
    const navigate = useNavigate();

    return <PortalIFrame
        key={id}
        id={id}
        relativeUrl={relativeUrlStub.replace(':companyId', companyId)}
        onDashboardRedirect={() => navigate('../projects')}
    />;
};

CompanyIFrame.propTypes = {
    id: PropTypes.string,
    relativeUrlStub: PropTypes.string
};

export const CompanyUsersIframe = () => <CompanyIFrame id='companyUsersIframe' relativeUrlStub={'/x/:companyId/CompanyUsers'} />;
export const CompanyUsageAndBillingIframe = () => <CompanyIFrame id='companyUsageAndBillingIframe' relativeUrlStub={'/Account/x/:companyId/Billing'} />;
export const CompanySettingsIframe = () => <CompanyIFrame id='companySettingsIframe' relativeUrlStub={'/x/:companyId/TranscriptionSettings'} />;

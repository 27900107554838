import React from 'react';
import { SvgIcon } from '@mui/material';
import { mdiTextBoxEditOutline } from '@mdi/js';

const TextActivityIcon = () => (
    <SvgIcon>
        <path d={mdiTextBoxEditOutline} />
    </SvgIcon>
);

export default TextActivityIcon;
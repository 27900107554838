import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import { useTranslation } from 'react-i18next';

const CustomAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));
  
const CustomAccordionSummary = styled((props) => (
    <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));
  
const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const LinkedListItem = ({item, onItemClick}) => {
    return (
        <ListItem key={item.name}
            secondaryAction={
                <IconButton edge='end' aria-label='link'>
                    <a href={item.url} target='_blank' rel='noreferrer'><LinkIcon /></a>
                </IconButton>
            }
        >
            <ListItemText sx={{cursor: 'pointer'}} onClick={() => onItemClick(item.name)} primary={item.name} />
        </ListItem>
    );
};

LinkedListItem.propTypes = {
    item: PropTypes.object,
    onItemClick: PropTypes.func
};


const UnlinkedListItem = ({item, onItemClick}) => {
    return (
        <ListItem key={item.name}>
            <ListItemText sx={{cursor: 'pointer'}} onClick={() => onItemClick(item.name)} primary={item.name} />
        </ListItem>
    );
};

UnlinkedListItem.propTypes = {
    item: PropTypes.object,
    onItemClick: PropTypes.func
};

const EntityCategory = ({ categoryData, categoryName, expanded, handleChange, onItemClick }) => {
    const panelName = categoryName + 'Panel';

    if (categoryData.length > 0) {
        return (
            <CustomAccordion expanded={expanded === panelName} onChange={handleChange(panelName)}>
                <CustomAccordionSummary aria-controls={panelName + '-content'} id={panelName + '-header'}>
                    <Typography>{categoryName[0].toUpperCase() + categoryName.substring(1)}</Typography>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <Grid item xs={12} md={6} sx={{ maxWidth: '100% !important' }}>
                        <List>
                            { categoryData.map(e => {
                                return (
                                    e.url ?
                                        <LinkedListItem key={e.name} item={e} onItemClick={onItemClick} /> :
                                        <UnlinkedListItem key={e.name} item={e} onItemClick={onItemClick} />
                                );
                            }) }
                        </List>
                    </Grid>
                </CustomAccordionDetails>
            </CustomAccordion>
        );
    } else {
        return null;
    }
};

EntityCategory.propTypes = {
    categoryData: PropTypes.array,
    categoryName: PropTypes.string,
    expanded: PropTypes.string,
    handleChange: PropTypes.func,
    onItemClick: PropTypes.func
};

const RecognizedEntities = ({data, onEntityClick}) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState('locationsPanel');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <h2>{t('textAnalysisDashboard.labels.recognisedEntitiesTitle')}</h2>
            <div style={{ height: 550, width: 700, overflowY: 'scroll', textAlign: 'left' }}>
                <EntityCategory categoryData={data.locations} categoryName={t('textAnalysisDashboard.labels.locationsCategory')} expanded={expanded} handleChange={handleChange} onItemClick={onEntityClick} />
                <EntityCategory categoryData={data.organizations} categoryName={t('textAnalysisDashboard.labels.organisationsCategory')} expanded={expanded} handleChange={handleChange} onItemClick={onEntityClick} />
                <EntityCategory categoryData={data.people} categoryName={t('textAnalysisDashboard.labels.peopleCategory')} expanded={expanded} handleChange={handleChange} onItemClick={onEntityClick} />
                <EntityCategory categoryData={data.products} categoryName={t('textAnalysisDashboard.labels.productsCategory')} expanded={expanded} handleChange={handleChange} onItemClick={onEntityClick} />
                <EntityCategory categoryData={data.skills} categoryName={t('textAnalysisDashboard.labels.skillsCategory')} expanded={expanded} handleChange={handleChange} onItemClick={onEntityClick} />
            </div>
        </div>
    );
};

export default RecognizedEntities;

RecognizedEntities.propTypes = {
    data: PropTypes.object,
    onEntityClick: PropTypes.func
};

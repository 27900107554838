import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import HighlightTile from './HighlightTile';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 16,
        paddingBottom: 16
    }
}));

const TiledHighlightsView = ({ highlights, onSelectHighlight }) => {
    const classes = useStyles();

    const renderHighlightTiles = () => {
        return highlights.map(highlight => (
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12} key={`highlight-tile-${highlight.id}`}>
                <HighlightTile
                    highlight={highlight}
                    onClick={() => onSelectHighlight(highlight)}
                />
            </Grid>
        ));
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                { renderHighlightTiles() }
            </Grid>
        </div>
    );
};

TiledHighlightsView.propTypes = {
    highlights: PropTypes.array.isRequired,
    onSelectHighlight: PropTypes.func
};

export default TiledHighlightsView;

import PropTypes from 'prop-types';
import { AutoAwesome } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, CircularProgress, Link, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import vlqrApi from 'utils/vlqrApi';
import valImg from './val.png';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import useVoiceTranscriptionsForActivity from 'api-new/useVoiceTranscriptionsForActivity';
import { Trans, useTranslation } from 'react-i18next';
import AiSummaryReportStatus from 'enums/AiSummaryReportStatus';
import { handleError } from 'utils/portalApi';

const NoReportsPlaceholder = ({ focusGroupId, onGenerateReportsRequestSuccess, overallReportStatus }) => {
    const { t } = useTranslation();
    const [retryFailed, setRetryFailed] = useState(false);
    const isProcessingReports = overallReportStatus === AiSummaryReportStatus.PROCESSING;

    const { mutate: generateReports, isLoading: isGeneratingReports } = useMutation(() => {
        return vlqrApi.post(`focusgroups/${focusGroupId}/generateAiSummaryReports`);
    }, {
        onSuccess: () => {
            onGenerateReportsRequestSuccess?.();
        }, onError: (error) => {
            setRetryFailed(true);
            handleError(error);
        }
    });

    const { data: voiceTranscriptions, isLoading: isLoadingVoiceTranscriptions } = useVoiceTranscriptionsForActivity(focusGroupId);

    if (isLoadingVoiceTranscriptions) {
        return <LoadingPlaceholder />;
    }

    const isLackingVoiceTranscriptions = voiceTranscriptions.length === 0;
    const showGenerateReportsButton = !isLackingVoiceTranscriptions && [AiSummaryReportStatus.NONE, AiSummaryReportStatus.ERROR].includes(overallReportStatus);

    const missingVoiceTranscriptsAlert =
        <Alert severity='warning' variant='filled' sx={{ maxWidth: '40em' }}>
            <AlertTitle>{ t('aiReports.noVoiceTranscriptionsAvailable') }</AlertTitle>

            <Trans
                i18nKey="aiReports.noVoiceTranscriptionsExplanation"
                components={{ emailSupportLink: <Link color="warning.contrastText" href="mailto:support@visionslive.com" /> }}
            />
        </Alert>;

    const processingAlert =
        <Alert severity='info' variant='filled' sx={{ maxWidth: '40em' }}>
            <AlertTitle>{ t('session.aiReports.processingReports') }</AlertTitle>
            { t('session.aiReports.processingReportsExplanation') }
        </Alert>;

    const errorGeneratingAlert =
        <Alert severity='error' variant='filled' sx={{ maxWidth: '40em' }}>
            <AlertTitle>{ t('aiReports.errorGeneratingReports') }</AlertTitle>

            <Trans
                i18nKey="aiReports.errorGeneratingReportsExplanation"
                components={{ emailSupportLink: <Link color="warning.contrastText" href="mailto:support@visionslive.com" /> }}
            />
        </Alert>;

    const generateReportsButton =
        <LoadingButton
            loading={isGeneratingReports}
            loadingPosition="start"
            disabled={retryFailed}
            startIcon={<AutoAwesome />}
            variant="contained"
            color="newPrimary"
            size="large"
            onClick={() => generateReports()}
        >
            { t('aiReports.generateAISummary') }
        </LoadingButton>;

    return (
        <Stack
            alignItems='center'
            sx={{ backgroundColor: 'grey.10', px: 4, py: 8, borderRadius: 2 }}
            gap={3}
        >
            <Box sx={{ borderRadius: '100%', height: 140, width: 140, overflow: 'hidden', position: 'relative' }}>
                <img src={valImg} alt="val" height="100%" width="auto" />

                { isProcessingReports &&
                    <CircularProgress
                        loading
                        size={150}
                        sx={{
                            animationDuration: '5s',
                            position: 'absolute',
                            opacity: 0.7,
                            top: -5, left: -5, right: 0, bottom: 0
                        }}
                    />
                }
            </Box>

            <Stack gap={1} alignItems='center' sx={{ width: '40em' }}>
                <Typography variant="h5" sx={{ color: 'grey.2' }}>
                    { t('aiReports.meetVal') }
                </Typography>

                <Typography variant="body1" align='center'>
                    { t('aiReports.meetValBlurb') }
                </Typography>
            </Stack>

            { isProcessingReports && processingAlert }
            { overallReportStatus === AiSummaryReportStatus.ERROR && errorGeneratingAlert }
            { isLackingVoiceTranscriptions && missingVoiceTranscriptsAlert }

            { showGenerateReportsButton && generateReportsButton }
        </Stack>
    );
};

NoReportsPlaceholder.propTypes = {
    focusGroupId: PropTypes.string,
    onGenerateReportsRequestSuccess: PropTypes.func,
    overallReportStatus: PropTypes.oneOf(Object.values(AiSummaryReportStatus))
};

export default NoReportsPlaceholder;

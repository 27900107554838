import PropTypes from 'prop-types';
import React from 'react';

import withStyles from '@mui/styles/withStyles';
import Tabs from '@mui/material/Tabs';

// Customised tabs to make more appropriate for display in the TopBar
const ThemedTabs = withStyles(theme => ({
    ...theme.tabs
}))(Tabs);

const VerticalTabs = (props) => (
    <ThemedTabs {...props}>
        { props.children }
    </ThemedTabs>
);
VerticalTabs.propTypes = {
    children: PropTypes.node
};

export default VerticalTabs;

import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ActivityRecordingProvider, { ActivityRecordingContext } from './ActivityRecordingProvider';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import VideoPlayer from './VideoPlayer';
import VideoControls from './VideoControls';
import useActivityRecordingInfo from './useActivityRecordingInfo';
import makeStyles from '@mui/styles/makeStyles';
import HighlightsPane from './SessionHighlights/HighlightsPane';

const TOOLBAR_HEIGHT = 80;

const useStyles = makeStyles(theme => ({
    playerRoot: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        backgroundColor: '#DDDDDD'
    },
    playerToolbar: {
        backgroundColor: 'white',
        minHeight: TOOLBAR_HEIGHT,
        height: TOOLBAR_HEIGHT,
        maxHeight: TOOLBAR_HEIGHT
    }
}));

const InlineHighlightsPane = () => {
    const {
        activityId,
        recordingId,
        videoRecordingUrl,
        highlightsPaneIsOpen, setHighlightsPaneIsOpen
    } = useContext(ActivityRecordingContext);

    return (
        <HighlightsPane
            activityId={activityId}
            recordingId={recordingId}
            videoRecordingUrl={videoRecordingUrl}
            isOpen={highlightsPaneIsOpen}
            onCloseBtnClick={() => setHighlightsPaneIsOpen(false)}
        />
    );
};

export const InlineRecordingViewer = ({ activityId, startTime, clipDuration }) => {
    const classes = useStyles();
    const { data: recordingInfo, isLoading } = useActivityRecordingInfo(activityId);

    if (isLoading) {
        return <LoadingPlaceholder />;
    }

    return (
        <ActivityRecordingProvider
            initialState={{
                activityId: activityId,
                sessionStartDate: recordingInfo.sessionStartDate,
                recordings: recordingInfo.recordings,
                eventLog: recordingInfo.eventLog,
                startSeconds: startTime,
                clipStartTime: startTime,
                clipDuration: clipDuration
            }}
        >
            <React.Fragment>
                <div className={classes.playerRoot}>
                    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', overflow: 'auto' }}>
                        <div style={{ margin: 'auto' }}>
                            <VideoPlayer skipTo={startTime} maxWidth={1200} />
                        </div>
                    </div>
                    <div className={classes.playerToolbar}>
                        <VideoControls showReactionFeatures={false} />
                    </div>
                </div>
                <InlineHighlightsPane />
            </React.Fragment>
        </ActivityRecordingProvider>
    );
};

InlineRecordingViewer.propTypes = {
    activityId: PropTypes.string,
    recordingId: PropTypes.string,
    startTime: PropTypes.number,
    clipDuration: PropTypes.number
};

export default InlineRecordingViewer;

import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

import portalApi, { handleError } from '../../utils/portalApi';

const Delete = (props) => {
    const theme = useTheme();
    const [deleteInProgress, setDeleteInProgress] = useState(false);

    const handleDelete = (event) => {
        if (deleteInProgress) {
            return;
        }

        setDeleteInProgress(true);

        portalApi
            .delete(`/api/projects/${props.projectId}`)
            .then(() => props.onSuccess())
            .catch(handleError);
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            aria-labelledby="delete-confirmation-title"
            aria-describedby="delete-description"
        >
            <DialogTitle id="delete-confirmation-title">{'Delete Project?'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="delete-description">
                    Note that you will no longer be able to access this project or the activities within.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {!deleteInProgress &&
                    <React.Fragment>
                        <Button onClick={props.onCancel}>
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} color="error">
                            Delete
                        </Button>
                    </React.Fragment>
                }

                {deleteInProgress &&
                    <React.Fragment>
                        <Button disabled>Deleting</Button>
                        <CircularProgress size={20} style={{ marginRight: theme.spacing(2) }} />
                    </React.Fragment>
                }
            </DialogActions>
        </Dialog>
    );
};

export default Delete;

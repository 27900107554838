import React from 'react';
import PropTypes from 'prop-types';
import useHeatmapResponsesForActivity from 'api-new/stim-based/useHeatmapResponsesForActivity';
import useHeatmapSentimentOptionsForActivity from 'api-new/stim-based/useHeatmapSentimentOptionsForActivity';
import { LoadingPlaceholderFullPage } from 'components/shared/LoadingPlaceholderFullPage';
import { HeatmapActivitySentimentOverview } from 'vl-reporting-components';
import { Box } from '@mui/material';
import _ from 'lodash';

const ActivitiesListItemSentimentOverview = ({activityId}) => {
    const { data: heatmapResponsesData, isLoading: isLoadingHeatmapResponses } = useHeatmapResponsesForActivity(activityId);
    const { data: heatmapSentimentOptionsData, isLoading: isLoadingHeatmapSentimentOptions } = useHeatmapSentimentOptionsForActivity(activityId);

    if (isLoadingHeatmapResponses || isLoadingHeatmapSentimentOptions) {
        return <LoadingPlaceholderFullPage />;
    }

    if (heatmapResponsesData?.length === 0) {
        return null;
    }

    const customSentimentConfig = _.keyBy(heatmapSentimentOptionsData, 'sentiment');

    return (
        <Box sx={{ fontSize: '0.9rem', flexShrink: 0 }}>
            <HeatmapActivitySentimentOverview
                responses={heatmapResponsesData}
                customSentimentConfig={customSentimentConfig}
                activityId={activityId}
                sx={{ height: 35 }}
            />
        </Box>
    );
};

ActivitiesListItemSentimentOverview.propTypes = {
    activityId: PropTypes.string
};

export default ActivitiesListItemSentimentOverview;

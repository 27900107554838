import PropTypes from 'prop-types';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Modal, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { styled } from '@mui/system';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import portalApi, { handleError } from 'utils/portalApi';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        '& .VL-ModalVideoContainer': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxHeight: '90vh',
            display: 'flex',
            aspectRatio: '21 / 9',
            borderRadius: 4,
            overflow: 'hidden'
        }
    }
}));

const HighlightModalInfoContainer = styled('div')(({ theme }) => ({
    padding: 16,
    minWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 0,
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    rowGap: 8
}));

const HighlightModalInfoContainerContent = styled('div')({
    flexGrow: 1,
    flexBasis: 0,
    minHeight: 200,
    overflow: 'auto'
});

const ConfirmDeleteModal = ({
    activityId,
    highlightId,
    open,
    onClose,
    onSuccess
}) => {
    const { t } = useTranslation();
    const [deleteInProgress, setDeleteInProgress] = useState(false);

    const deleteHighlight = () => {
        setDeleteInProgress(true);

        portalApi
            .delete(`/api/activity/${activityId}/highlights/${highlightId}`)
            .then((response) => {
                setDeleteInProgress(false);
                onSuccess();
            })
            .catch(handleError);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                { t('sessionHighlights.text.deleteHighlightTitle') }
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { t('sessionHighlights.text.deleteHighlightDesc') }
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>{ t('common.buttons.cancel') }</Button>

                <Button
                    onClick={deleteHighlight}
                    variant="outlined"
                    color="error"
                    disabled={deleteInProgress}
                    startIcon={
                        deleteInProgress && <CircularProgress variant="indeterminate" size={20} />
                    }
                >
                    { deleteInProgress ? t('common.text.deleting') : t('common.buttons.delete') }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const HighlightModal = ({
    activityId,
    highlightId,
    title,
    description,
    videoUri,
    isOpen,
    onClose,
    onHighlightDeleted
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

    return (
        <React.Fragment>
            <Modal
                className={classes.root}
                open={isOpen}
                onClose={onClose}
                disableAutoFocus={true}
            >
                <div className={'VL-ModalVideoContainer'}>
                    <div style={{ backgroundColor: '#D9E2EC', display: 'flex', alignItems: 'center' }}>
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            url={videoUri}
                            playing
                            volume={1}
                            controls
                        />
                    </div>

                    <HighlightModalInfoContainer>
                        <HighlightModalInfoContainerContent>
                            <Typography variant="h6" gutterBottom>{ title ?? t('sessionHighlights.text.untitledHighlight') }</Typography>
                            <Typography variant="body2">{ description ?? t('sessionHighlights.text.highlightPlaceholderDescription') }</Typography>
                        </HighlightModalInfoContainerContent>

                        <Divider />

                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
                            <Button
                                variant="outlined"
                                fullWidth
                                startIcon={<DeleteForeverIcon />}
                                onClick={() => setIsConfirmingDelete(true)}
                                color="error"
                            >
                                { t('sessionHighlights.buttons.deleteHighlight') }
                            </Button>
                        </div>
                    </HighlightModalInfoContainer>
                </div>
            </Modal>

            <ConfirmDeleteModal
                open={isConfirmingDelete}
                activityId={activityId}
                highlightId={highlightId}
                onClose={() => setIsConfirmingDelete(false)}
                onSuccess={() => {
                    setIsConfirmingDelete(false);
                    onHighlightDeleted();
                }}
            />
        </React.Fragment>
    );
};

HighlightModal.propTypes = {
    activityId: PropTypes.string.isRequired,
    description: PropTypes.string,
    highlightId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onHighlightDeleted: PropTypes.func,
    title: PropTypes.string,
    videoUri: PropTypes.string.isRequired
};

export default HighlightModal;

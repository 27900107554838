import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import ActivityStatus from '../../enums/ActivityStatus';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Edit, OpenInNew, Visibility, Link as LinkIcon, Info, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import CompanyRole from '../../enums/CompanyRole';

const MainActivityLinks = (props) => {
    const { t } = useTranslation();
    const moderatorLink = props.activity.links?.moderatorLink;
    const status = props.activity.details.detailedStatus;
    const companyRole = props.companyRole;
    const adminLink = props.activity.links?.adminLink;
    const staffObserverLink = props.activity.links?.staffObserverLink;
    const isBilled = props.activity.details.isBilled;

    const activityStatus = ActivityStatus[status];

    // Return a string describing why the moderator link button ('View Content / Edit Activity Content')
    // is disabled.
    const getModeratorLinkText = () => {
        if (!moderatorLink && !adminLink) {
            return t('activityPage.text.notModeratorOfThisActivity');
        } else if (!activityStatus.canLinkToActivity) {
            return 'Setup must be completed';
        } else {
            return '';
        }
    };

    const isOpenSessionButtonDisabled = () => {
        return getModeratorLinkText() !== '';
    };

    const OpenSessionButton = () => {
        return (

            <Grid item xs={12}>
                <Tooltip title={getModeratorLinkText()} arrow>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            startIcon={<OpenInNew />}
                            style={{ justifyContent: 'flex-start' }}
                            disabled={isOpenSessionButtonDisabled()}
                            onClick={() => {
                                if (moderatorLink) {
                                    window.open(moderatorLink, '_blank');
                                } else if (adminLink) {
                                    window.open(adminLink, '_blank');
                                }
                            }}
                        >
                            Enter Session
                        </Button>
                    </div>
                </Tooltip>
            </Grid>
        );
    };

    const ObserveButton = () => {
        if (!staffObserverLink) {
            return null;
        }

        return (
            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<Visibility />}
                    style={{ justifyContent: 'flex-start' }}
                    onClick={() => window.open(staffObserverLink, '_blank')}
                >
                    Observe
                </Button>
            </Grid>
        );
    };

    const LinksButton = () => {
        // Always show links to vlStaff, otherwise only show for relevant activity statuses.
        if (!activityStatus.shouldShowLinks && !CompanyRole[companyRole].canAlwaysViewActivityLinks) {
            return null;
        }

        return (
            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<LinkIcon />}
                    style={{ justifyContent: 'flex-start' }}
                    onClick={props.onLinksBtnClick}
                >
                    Links
                </Button>
            </Grid>
        );
    };

    const SetupButton = () => {
        if (CompanyRole[companyRole].canSetupActivity === false) {
            return null;
        }

        // Don't show the setup button for the following statuses.
        var excludedStatuses = [
            ActivityStatus.draft,
            ActivityStatus.completed,
            ActivityStatus.didNotStart
        ];

        if (excludedStatuses.includes(activityStatus)) {
            return null;
        }

        return (
            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<Edit />}
                    style={{ justifyContent: 'flex-start' }}
                    onClick={props.onSetupBtnClick}
                >
                    Setup
                </Button>
            </Grid>
        );
    };

    const ViewParticipantStatusButton = () => {
        if (CompanyRole[companyRole] !== CompanyRole.vlStaff) {
            return null;
        }

        return (
            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    fullWidth
                    style={{ justifyContent: 'flex-start' }}
                    startIcon={<Info />}
                    onClick={props.onViewStatusBtnClick}
                >
                    { t('activityPage.text.participantStatus') }
                </Button>
            </Grid>
        );
    };

    const SetIsBilledButton = () => {
        if (CompanyRole[companyRole].canSetActivityBilled === false) {
            return null;
        }
        let buttonIcon = null;
        if (props.isBilledRequestPending) {
            buttonIcon = <CircularProgress size={20} color="inherit" />;
        } else if (isBilled) {
            buttonIcon = <CheckBox />;
        } else {
            buttonIcon = <CheckBoxOutlineBlank />;
        }

        return (
            <Grid item xs={12}>
                <Tooltip
                    title={isBilled ? t('activityPage.tooltips.setIsBilledToFalse') : t('activityPage.tooltips.setIsBilledToTrue')}
                    enterDelay={500}
                    arrow
                >
                    <div>
                        <Button
                            variant="outlined"
                            fullWidth
                            style={{ justifyContent: 'flex-start' }}
                            startIcon={buttonIcon}
                            onClick={props.onSetIsBilledButtonClick}
                            disabled={props.isBilledRequestPending}
                        >
                            { isBilled ?
                                t('activityPage.buttons.setIsBilledToFalse') :
                                t('activityPage.buttons.setIsBilledToTrue') }
                        </Button>
                    </div>
                </Tooltip>
            </Grid>
        );
    };

    const StaffButtons = () => {
        if (CompanyRole[companyRole] !== CompanyRole.vlStaff) {
            return null;
        }

        return (
            <React.Fragment>
                <Grid item xs={12} style={{ padding: 8 }}>
                    <Typography variant="caption">{ t('common.text.vlStaffTools') }:</Typography>
                </Grid>
                <ViewParticipantStatusButton />
                <SetIsBilledButton />
            </React.Fragment>
        );
    };

    return (
        <Grid container spacing={1}>
            <OpenSessionButton />

            <ObserveButton />

            <LinksButton />

            <SetupButton />

            <StaffButtons />
        </Grid>
    );
};

export default MainActivityLinks;

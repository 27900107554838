import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';

import OfflineIcon from '@mui/icons-material/CloudOff';

import { setIsOffline } from '../../redux/actions';

const OfflineView = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <Container>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}>

                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title={'Network Connection Issue'} avatar={<Avatar><OfflineIcon /></Avatar>}/>
                        <CardContent>
                            <Typography variant="body2">{ t('common.errors.networkConnectionIssueDetected') }</Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => { dispatch(setIsOffline(false)); }}>{ t('common.buttons.tryAgain') }</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default OfflineView;

import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import useCompanies from 'api/useCompanies';
import useCompany from 'api/useCompany';
import React from 'react';
import { useMatch } from 'react-router-dom';
import IconTab from './IconTab';
import BusinessIcon from '@mui/icons-material/Business';

const CompanyIconTab = ({ companyId }) => {
    const { data: company } = useCompany(companyId);
    const { data: companies } = useCompanies();

    const routeIsActive = useMatch({
        path: '/company/:company_guid/:tab',
        end: true,
        exact: true
    });

    if (!company || companies?.length === 1) {
        // Don't show when there is only one company.
        return null;
    }

    return (
        <Tooltip title={company.name} arrow>
            <span>
                <IconTab
                    icon={<BusinessIcon />}
                    link={`/company/${company.id}`}
                    isActive={Boolean(routeIsActive)}
                />
            </span>
        </Tooltip>
    );
};

CompanyIconTab.propTypes = {
    companyId: PropTypes.string.isRequired
};

export default CompanyIconTab;

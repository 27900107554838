import PropTypes from 'prop-types';
import { Avatar, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BusinessIcon from '@mui/icons-material/Business';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const CompanySearchResult = ({ 
    companyId, 
    companyName, 
}) => {
    const theme = useTheme();

    return (
        <div className="VL-SearchResult" style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar style={{ ...theme.activityAvatar.neutral }}>
                <BusinessIcon />
            </Avatar>
            <div style={{ marginLeft: 24 }}>
                <Link
                    to={`/company/${companyId}`}
                    component={RouterLink}
                    variant="subtitle1"
                    underline="hover"
                    style={{ ...theme.typography.primaryItemLink }}
                >
                    { companyName }
                </Link>
            </div>
        </div>
    );
};

CompanySearchResult.propTypes = {
    companyId: PropTypes.string,
    companyName: PropTypes.string
};

export default CompanySearchResult;

import React, { useMemo, useState } from 'react';
import { useNavigate, useParams, useMatch, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import copyToClipboard from 'copy-to-clipboard';
import Container from '@mui/material/Container';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import VerticalTabs from 'components/shared/VerticalTabs';
import CompanyRole from 'enums/CompanyRole';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import Delete from 'components/project/Delete';
import PageButtonsContainer from 'components/shared/PageButtonsContainer';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import PageHeader from 'components/shared/PageHeader';
import StyledPageTab from 'components/shared/StyledPageTab';
import useCompany from 'api/useCompany';
import useProject from 'api/useProject';
import TopBar from 'components/TopBar/TopBar';
import useProjectActivities from 'api/useProjectActivites';
import MarkCompletedSessionsAsBilledMenuItem from './MarkCompletedSessionsAsBilledMenuItem';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const getTabs = (t, companyRole) => ([
    {
        key: 'activities',
        label: t('projectPage.tabs.manageActivities'),
        enabled: true
    },
    {
        key: 'users',
        label: t('projectPage.tabs.manageParticipants'),
        enabled: companyRole?.canManageProjectUsers
    },
    {
        key: 'settings',
        label: t('projectPage.tabs.projectSettings'),
        enabled: true
    }
]);

const ProjectOverview = () => {
    const {
        company_guid: companyId,
        project_guid: projectId
    } = useParams();
    const projectTabMatch = useMatch({
        path: '/company/:company_guid/project/:project_guid/:tab',
        end: true,
        exact: true
    });
    const tabKey = projectTabMatch?.params?.tab;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { refetch } = useProjectActivities(projectId);

    const { data: selectedCompany, isLoading: isCompanyLoading } = useCompany(companyId);
    const { data: selectedProject, isLoading: isProjectLoading } = useProject(projectId);

    const companyRole = CompanyRole[selectedCompany?.role];
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    const [pageMenuAnchorEl, setPageMenuAnchorEl] = useState(null);

    const tabs = useMemo(() => getTabs(t, companyRole), [t, companyRole]).filter(tab => tab.enabled);

    const loading = () => (
        isProjectLoading || isCompanyLoading ? <LoadingPlaceholder /> : null
    );

    const main = () => (
        <div style={{ backgroundColor: 'white' }}>
            <TopBar
                companyId={companyId}
                projectId={projectId}
            />
            <PageHeader>
                <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        <Typography variant="h1">{selectedProject.name}</Typography>
                        <Typography variant="subtitle1">{selectedProject.client}</Typography>

                        <VerticalTabs
                            value={Math.max(tabs.findIndex((tab) => tab.key === tabKey), 0)}
                            onChange={(event, newValue) => {
                                const targetUrl = `/company/${companyId}/project/${projectId}/${tabs[newValue].key}`;
                                navigate(targetUrl);
                            }}
                            variant="scrollable"
                        >
                            { tabs.filter(tab => tab.enabled).map(tab =>
                                <StyledPageTab key={tab.key} label={tab.label}/>
                            )}
                        </VerticalTabs>
                    </div>

                    <PageButtonsContainer>
                        <IconButton onClick={(e) => setPageMenuAnchorEl(e.currentTarget)} size="large">
                            <MoreHorizIcon />
                        </IconButton>
                    </PageButtonsContainer>
                </Container>
            </PageHeader>

            <Container>
                <div style={{ flexGrow: 1 }}>
                    <Outlet context={{companyId, projectId}} />
                </div>
            </Container>

            <Delete
                open={deleteDialogIsOpen}
                projectId={selectedProject.id}
                onCancel={() => setDeleteDialogIsOpen(false)}
                onSuccess={() => {
                    navigate(`/company/${companyId}/`);
                }} />

            <Menu
                open={Boolean(pageMenuAnchorEl)}
                anchorEl={pageMenuAnchorEl}
                onClose={() => setPageMenuAnchorEl(null)}
            >
                { companyRole.canSetActivityBilled &&
                    <MarkCompletedSessionsAsBilledMenuItem
                        projectId={projectId}
                        onComplete={() => {
                            refetch();
                            setPageMenuAnchorEl(null);
                        }}
                    />
                }

                { companyRole.canDeleteProject &&
                    <MenuItem
                        onClick={() => setDeleteDialogIsOpen(true)}
                    >
                        <ListItemIcon>
                            <DeleteForeverIcon fontSize="small" />
                        </ListItemIcon>
                        { t('projectPage.tooltips.deleteProject') }
                    </MenuItem>
                }

                <MenuItem
                    onClick={() => {
                        copyToClipboard(selectedProject.id);
                        setPageMenuAnchorEl(null);
                    }}
                >
                    <ListItemIcon>
                        <FileCopyOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    Copy Project ID
                </MenuItem>
            </Menu>
        </div>
    );

    return (
        loading() || main()
    );
};

export default ProjectOverview;

import { InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

const useSearchStyles = makeStyles(theme => ({
    root: {
        ...theme.globalSearch
    }
}));

export const useSearchQueryParam = () => {
    const params = new URLSearchParams(useLocation().search);
    return params.get('q') ?? '';
};

const GlobalSearchBox = () => {
    const { t } = useTranslation();
    const classes = useSearchStyles();
    const searchParam = useSearchQueryParam();

    const [searchText, setSearchText] = useState(searchParam ?? '');
    const navigate = useNavigate();

    useEffect(() => {
        setSearchText(searchParam);
    }, [searchParam]);

    const runSearch = () => {
        navigate(`/search?q=${encodeURIComponent(searchText)}`);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13 && searchText.length > 0) {
            // Enter key pressed
            runSearch();
        }
    };

    return (
        <div className={classes.root}>
            <TextField
                id="global-search"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" disablePointerEvents>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    disableUnderline: true
                }}
                fullWidth
                hiddenLabel
                placeholder={t('appBar.labels.globalSearch')}
                size="small"
                value={searchText}
                autoComplete="off"
                onChange={(e) => { setSearchText(e.currentTarget.value); }}
                onFocus={(e) => e.currentTarget.select()}
                onKeyDown={handleKeyDown}
                variant="standard"
            />
        </div>
    );
};

export default GlobalSearchBox;

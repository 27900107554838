import useCompanies from 'api/useCompanies';
import React from 'react';
import { useMatch } from 'react-router-dom';
import IconTab from './IconTab';
import HomeIcon from '@mui/icons-material/Home';

const HomeIconTab = () => {
    const { data: companies } = useCompanies();

    const homeRouteIsActive = useMatch({
        path: '/selectCompany',
        end: true,
        exact: true
    });

    const companyRouteIsActive = useMatch({
        path: '/company/:company_guid/:tab',
        end: true,
        exact: true
    });

    return (
        <span>
            <IconTab
                icon={<HomeIcon />}
                link={'/'}
                isActive={companies?.length > 1 ? Boolean(homeRouteIsActive) : Boolean(companyRouteIsActive)}
            />
        </span>
    );
};

export default HomeIconTab;
import React, { createContext, createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { validate } from './VideoTrimmerValidation';

export const VideoTrimmerContext = createContext();

const createDefaultState = (props) => {
    return {
        videoRecordingUrl: props.videoRecordingUrl,
        clipStartTime: props.clipStartTime ?? null,
        clipEndTime: props.clipEndTime ?? null
    };
};

const VideoTrimmerProvider = ({ initialState, children }) => {
    const defaultState = createDefaultState(initialState);

    const { videoRecordingUrl } = defaultState;

    const videoPlayerRef = createRef();
    const [videoIsPlaying, setVideoIsPlaying] = useState(false);
    const [videoDuration, setVideoDuration] = useState(null);
    const [clipStartTime, setClipStartTime] = useState(defaultState.clipStartTime);
    const [clipEndTime, setClipEndTime] = useState(defaultState.clipEndTime);
    const [highlightTitle, setHighlightTitle] = useState('');
    const [highlightDescription, setHighlightDescription] = useState('');
    const [videoPlaybackProgress, setVideoPlaybackProgress] = useState({
        played: 0,
        loaded: 0,
        playedSeconds: 0,
        loadedSeconds: 0
    });
    const [pageNumber, setPageNumber] = useState(1);

    const errors = validate(clipStartTime, clipEndTime, highlightTitle, highlightDescription);

    // Automatically jump to clipStartTime if one is specified by default.
    // Video duration should only change on first load.
    useEffect(() => {
        if (videoDuration && clipStartTime !== null) {
            videoPlayerRef.current.seekTo(clipStartTime);
        }
    }, [videoDuration]);

    const skipToClipStart = () => {
        videoPlayerRef.current.seekTo(clipStartTime);
    };

    const skipToClipEnd = () => {
        videoPlayerRef.current.seekTo(clipEndTime);
    };

    const skipBackward = (seconds) => {
        videoPlayerRef.current.seekTo(videoPlaybackProgress.playedSeconds - (seconds ?? 1));
    };

    const skipForward = (seconds) => {
        videoPlayerRef.current.seekTo(videoPlaybackProgress.playedSeconds + (seconds ?? 1));
    };

    return (
        <VideoTrimmerContext.Provider
            value={{
                videoRecordingUrl,
                videoPlayerRef,
                videoIsPlaying, setVideoIsPlaying,
                videoDuration, setVideoDuration,
                videoPlaybackProgress, setVideoPlaybackProgress,
                clipStartTime, setClipStartTime,
                clipEndTime, setClipEndTime,
                highlightTitle, setHighlightTitle,
                highlightDescription, setHighlightDescription,
                skipToClipStart,
                skipToClipEnd,
                skipForward,
                skipBackward,
                pageNumber, setPageNumber,
                errors
            }}
        >
            { children }
        </VideoTrimmerContext.Provider>
    );
};

VideoTrimmerProvider.propTypes = {
    children: PropTypes.node,
    initialState: PropTypes.shape({
        /** Clip Start Time (in seconds) */
        clipStartTime: PropTypes.number,
        /** Clip End Time (in seconds) */
        clipEndTime: PropTypes.number,
        /** The Video Url */
        videoRecordingUrl: PropTypes.string.isRequired,
        highlightTitle: PropTypes.string.isRequired,
        highlightDescription: PropTypes.string.isRequired
    })
};

export default VideoTrimmerProvider;

import { makeStyles } from '@mui/styles';
import { useWindowSize } from '@react-hook/window-size';
import React, { useContext } from 'react';
import ReactPlayer from 'react-player';
import { VideoTrimmerContext } from './VideoTrimmerProvider';

/**
 * @typedef {Object} PlayerDimensions
 * @property {string} playerHeight - The player height (either 'auto' or '100%')
 * @property {string} playerWidth - The player width (either 'auto' or '100%')
 */

/**
 * Determines the player height/width settings based on the current window size.
 *
 * - For portrait, the video should take up all the available width and auto size the height.
 * - For landscape, the video should take up all the available height and auto size the width.
 *
 * @returns {PlayerDimensions}
 */
const usePlayerDimensions = () => {
    const [width, height] = useWindowSize();
    const playerHeight = height > width ? 'auto' : '100%';
    const playerWidth = height >= width ? '100%' : 'auto';

    return { playerHeight, playerWidth };
};

const useStyles = makeStyles({
    root: {
        '& > video': {
            maxWidth: '98vw',
            maxHeight: '76vh'
        }
    }
});

const VideoPlayer = () => {
    const {
        videoRecordingUrl,
        videoIsPlaying, setVideoIsPlaying,
        videoPlayerRef,
        setVideoDuration,
        setVideoPlaybackProgress
    } = useContext(VideoTrimmerContext);

    const { playerHeight, playerWidth } = usePlayerDimensions();

    const classes = useStyles();

    return (
        <div>
            <ReactPlayer
                height={playerHeight}
                width={playerWidth}
                className={classes.root}
                ref={videoPlayerRef}
                url={videoRecordingUrl}
                playing={videoIsPlaying}
                volume={1}
                onDuration={(duration) => { setVideoDuration(duration); }}
                onProgress={(progress) => setVideoPlaybackProgress(progress)}
                onPlay={() => setVideoIsPlaying(true)}
                onPause={() => setVideoIsPlaying(false)}
                progressInterval={500}
                controls
            />
        </div>
    );
};

export default VideoPlayer;

import { styled, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { LoadingPlaceholderFullPage } from 'components/shared/LoadingPlaceholderFullPage';
import useParticipantsForActivity from 'api-new/useParticipantsForActivity';
import useRankingAnswersForActivity from 'api-new/stim-based/useRankingAnswersForActivity';
import useRankingOptionsForActivity from 'api-new/stim-based/useRankingOptionsForActivity';
import useStimuliForFocusGroup from 'api-new/stim-based/useStimuliForFocusGroup';
import { RankingActivityResultView } from 'vl-reporting-components';
import _ from 'lodash';
import { PrintOutlined } from '@mui/icons-material';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';
import useActivity from 'api/useActivity';
import 'yet-another-react-lightbox/styles.css';

const PrintButton = styled(Button)(({ theme }) => ({
    ...theme.viewReportButton
}));

const RankingActivityResults = ({activity, focusGroupId, isOpen, onClose}) => {
    const { data: rankingOptionsData, isLoading: isLoadingRankingOptions } = useRankingOptionsForActivity(activity.id);
    const { data: rankingAnswersData, isLoading: isLoadingRankingAnswers } = useRankingAnswersForActivity(activity.id);
    const { data: stimuli, isLoading: isLoadingStimuli } = useStimuliForFocusGroup(focusGroupId);
    const { data: participants, isLoading: isLoadingParticipants } = useParticipantsForActivity(focusGroupId);
    const { data: focusGroup, isLoading: isLoadingFocusGroup } = useActivity(focusGroupId);

    const { t } = useTranslation();

    let componentRef = useRef();

    if (isLoadingRankingOptions || isLoadingRankingAnswers || isLoadingStimuli || isLoadingParticipants || isLoadingFocusGroup) {
        return <LoadingPlaceholderFullPage />;
    }

    // Format participant names so that they are ready for PollActivityResultView component
    const participantNameMap = _.keyBy(participants, 'focusGroupUserId');

    // Add the stim uri to each ranking option so the image can be displayed
    const rankingOptionsWithUris = rankingOptionsData?.map(option => ({
        ...option,
        uri: stimuli.find(x => x.id === option.stimulusId).uri
    }));

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth='xl'
            fullWidth
        >
            <DialogContent
                ref={componentRef}
                sx={{
                    '& .MuiTypography-subtitle2': { fontWeight: 500 },
                    '& .MuiListItemText-secondary': { color: 'grey.1' }
                }}
            >
                <RankingActivityResultView
                    options={rankingOptionsWithUris}
                    answers={rankingAnswersData}
                    participants={participantNameMap}
                    activity={activity}
                    focusGroupTitle={focusGroup?.details.title}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>
                    { t('common.buttons.close') }
                </Button>

                <ReactToPrint
                    trigger={() =>
                        <PrintButton startIcon={<PrintOutlined />}>
                            {t('focusGroups.activities.print')}
                        </PrintButton>
                    }
                    content={() => componentRef.current}
                />
            </DialogActions>
        </Dialog>
    );
};

RankingActivityResults.propTypes = {
    activity: PropTypes.object,
    focusGroupId: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};

export default RankingActivityResults;

import { Enumify } from 'enumify';

class SessionHighlightEncodingStatus extends Enumify {
    static Running = new SessionHighlightEncodingStatus();
    static Completed = new SessionHighlightEncodingStatus();
    static Error = new SessionHighlightEncodingStatus();
    static Cancelled = new SessionHighlightEncodingStatus();
    static NotStarted = new SessionHighlightEncodingStatus();
}

export default SessionHighlightEncodingStatus;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';

import ActivityTableRow from './ActivityTableRow';
import ResponsiveDialog from 'components/shared/ResponsiveDialog';
import { DialogContent } from '@mui/material';
import PortalIFrame from 'components/shared/PortalIFrame';
import { createRescheduleRelativeURL } from 'utils/activityUrlHelper';
import ProductType from 'enums/ProductType';
import FocusGroupReportsPanel from 'components/reports/FocusGroupReportsPanel/FocusGroupReportsPanel';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginBottom: '16px',
        '& .VL-ActivityRow': {
            borderBottom: `2px solid ${theme.dividers.contentDivider.backgroundColor}`,
            '&:last-child': {
                borderBottom: 'none'
            }
        }
    }
}));

const ActivitiesTableView = ({ activities, companyId, companyRole, projectId, onActivityModified }) => {
    const classes = useStyles();
    const [reportViewSettings, setReportViewSettings] = useState(null);
    const [rescheduleActivityViewSettings, setRescheduleActivityViewSettings] = useState(null);

    if (activities.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <Paper className={classes.root} variant="outlined">
                {activities.map((activity) => (
                    <ActivityTableRow
                        key={activity.activityId}
                        activity={activity}
                        companyId={companyId}
                        companyRole={companyRole}
                        projectId={projectId}
                        onViewReportClick={() => {
                            setReportViewSettings({
                                activityName: activity.name,
                                activityId: activity.activityId
                            });
                        }}
                        onRescheduleBtnClick={() => {
                            setRescheduleActivityViewSettings({
                                activityName: activity.name,
                                rescheduleUrl: createRescheduleRelativeURL(ProductType[activity.productType], projectId, activity.activityId)
                            });
                        }}
                    />
                ))}
            </Paper>

            <ResponsiveDialog
                heading={reportViewSettings?.activityName}
                open={Boolean(reportViewSettings)}
                onCancel={() => setReportViewSettings(null)}
                keepMounted={false}
                maxWidth='lg'
                fullWidth
            >
                <DialogContent>
                    <FocusGroupReportsPanel projectId={projectId} activityId={reportViewSettings?.activityId} />
                </DialogContent>
            </ResponsiveDialog>

            <ResponsiveDialog
                heading={`Reschedule Activity (${ rescheduleActivityViewSettings?.activityName })`}
                open={Boolean(rescheduleActivityViewSettings)}
                onCancel={() => setRescheduleActivityViewSettings(null)}
            >
                <DialogContent>
                    <PortalIFrame
                        relativeUrl={rescheduleActivityViewSettings?.rescheduleUrl}
                        containerProps={{ disableGutters: true }}
                        paperProps={{ elevation: 0, style: { padding: 0 } }}
                        onDashboardRedirect={() => {
                            // Optional function syntax
                            // eslint-disable-next-line no-unused-expressions
                            onActivityModified?.();
                            setRescheduleActivityViewSettings(null);
                        }} />
                </DialogContent>
            </ResponsiveDialog>
        </React.Fragment>
    );
};

ActivitiesTableView.propTypes = {
    activities: PropTypes.array,
    companyId: PropTypes.string,
    companyRole: PropTypes.object,
    projectId: PropTypes.string,
    onActivityModified: PropTypes.func
};

export default ActivitiesTableView;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const ActivityStatusChip = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <div style={{ ...theme.activityStatusChip.default, ...theme.activityStatusChip[props.status] }}>
            { t(`activityStatuses.text.${props.status}`) }
        </div>
    );
};

export default ActivityStatusChip;

import PropTypes from 'prop-types';
import { Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Interval } from 'luxon';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import ToolbarSpacer from 'components/shared/ToolbarSpacer';
import { ActivityRecordingContext } from './ActivityRecordingProvider';
import getSentimentIcon from './SentimentIcons/getSentimentIcon';
import { AnimatePresence, motion } from 'framer-motion';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        bottom: 56,
        right: 24,
        width: 280,
        overflow: 'auto',
        '& .MuiTypography-h2': {
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '0.4px',
            color: '#F0F4F8'
        },
        '& .MuiIconButton-root': {
            color: '#F0F4F8'
        },
        '& .VL-EventsContainer': {
            padding: 16, // Adding padding so that the drop shadow effect is visible on each event
            '& .VL-Event': {
                backgroundColor: '#102A43',
                padding: 12,
                borderRadius: '4px 4px 0px 4px',
                boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.5)',
                marginTop: 8,
                '& .VL-EventInfo': {
                    alignItems: 'baseline',
                    display: 'flex',
                    color: '#829AB1',
                    '& .MuiTypography-body1': {
                        fontSize: 14
                    },
                    '& .MuiLink-root': {
                        color: '#F0F4F8',
                        cursor: 'pointer',
                        fontWeight: 500,
                        '&:hover': {
                            color: 'white'
                        }
                    },
                    '& .VL-SentimentIconContainer': {
                        width: 18,
                        height: 'auto'
                    }
                },
                '& .VL-EventBody': {
                    marginTop: 8,
                    color: '#D9E2EC'
                }
            }
        }
    }
}));

const Event = (props) => {
    const { event, videoRecordingStartDate } = props;
    const eventData = JSON.parse(event.data);

    if (!videoRecordingStartDate || event.type !== 'moderatorNote') {
        return null;
    }

    const eventDateTime = DateTime.fromISO(event.sendDate);
    const startSeconds = Interval.fromDateTimes(videoRecordingStartDate, eventDateTime).toDuration('seconds');

    const formattedTime = startSeconds.as('hours') > 1 ?
        startSeconds.toFormat('hh:mm:ss') :
        startSeconds.toFormat('mm:ss');

    return (
        <motion.div
            key={`VL-ActiveEventsView-EventAnimation-${event.id}`}
            className={'VL-Event'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className={'VL-EventInfo'}>
                <Link
                    onClick={() => props.onEventTimestampClick()}
                    underline="hover"
                    variant="body1"
                >
                    { formattedTime }
                </Link>

                <ToolbarSpacer />
                <Typography variant="body1">{ event.senderPsuedonym ?? event.senderFullName }</Typography>

                <ToolbarSpacer grow />
                <div className={'VL-SentimentIconContainer'}>
                    { getSentimentIcon(eventData.Sentiment) }
                </div>
            </div>

            { eventData.Note &&
                <div className={'VL-EventBody'}>
                    { eventData.Note }
                </div>
            }
        </motion.div>
    );
};
Event.propTypes = {
    event: PropTypes.shape({
        data: PropTypes.string,
        id: PropTypes.string,
        sendDate: PropTypes.string,
        senderFullName: PropTypes.string,
        senderPsuedonym: PropTypes.string,
        type: PropTypes.string
    }),
    onEventTimestampClick: PropTypes.func,
    videoRecordingStartDate: PropTypes.instanceOf(DateTime)
};

const ActiveEventsView = () => {
    const classes = useStyles();
    const {
        videoRecordingStartDate,
        activeNoteEvents,
        reactionsPaneIsOpen,
        skipToEvent
    } = useContext(ActivityRecordingContext);

    if (reactionsPaneIsOpen || !videoRecordingStartDate) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={'VL-EventsContainer'}>
                <AnimatePresence>
                    { activeNoteEvents.map(e =>
                        (<Event
                            key={e.id}
                            event={e}
                            videoRecordingStartDate={videoRecordingStartDate}
                            onEventTimestampClick={() => skipToEvent(e.id)}
                        />))
                    }
                </AnimatePresence>
            </div>
        </div>
    );
};

export default ActiveEventsView;

import PropTypes from 'prop-types';
import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatSecondsDuration } from '../util';
import { Error, PlayCircleFilled } from '@mui/icons-material';
import SessionHighlightEncodingStatus from '../../../enums/SessionHighlightEncodingStatus';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        cursor: 'pointer',
        position: 'relative',
        padding: 4,
        borderRadius: 4,
        backgroundColor: '#334E68',
        '& img': {
            display: 'block', // remove extra bit of padding that otherwise displays below the image
            width: '100%',
            height: 'auto'
        },
        '& .VL-HighlightTile-Duration': {
            backgroundColor: '#334E68',
            padding: '4px 8px',
            position: 'absolute',
            bottom: 0,
            right: 0,
            fontSize: '0.9rem',
            borderRadius: 4
        },
        '& .VL-HighlightTile-IconContainer': {
            width: '80%',
            textAlign: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
            '& .MuiSvgIcon-root': {
                fill: '#334E68',
                fontSize: 36,
                opacity: 0.2,
                transition: 'opacity 300ms',
                marginBottom: 8
            },
            '& .MuiCircularProgress-root': {
                marginBottom: 8,
                width: 20,
                height: 20,
                '& .MuiCircularProgress-svg': {
                    color: 'white'
                }
            },
            '& .MuiTypography-root': {
                color: 'white'
            }
        },
        '&:hover': {
            '& .VL-HighlightTile-IconContainer': {
                '& .MuiSvgIcon-root':  {
                    opacity: 1
                }
            }
        },
        '&.VL-HighlightTile-Unprocessed': {
            cursor: 'auto',
            '& img': {
                visibility: 'hidden'
            }
        },
        '&.VL-HighlightTile-Errored': {
            cursor: 'auto',
            backgroundColor: '#b31a0e',
            '& .VL-HighlightTile-IconContainer': {
                '& .MuiSvgIcon-root': {
                    fill: 'white',
                    opacity: 1
                }
            },
            '& img': {
                visibility: 'hidden'
            }
        },
        '& .VL-HighlightTile-TextBlock': {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            background: '#334E68',
            left: '2%',
            top: 8,
            padding: '4px 8px',
            maxHeight: '100%',
            maxWidth: '96%',
            zIndex: 20,
            borderRadius: 4
        }
    }
}));

const HighlightTile = ({ highlight, onClick }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const showTitle = highlight.title && highlight.title?.trim() !== '';

    const formattedDuration = formatSecondsDuration(highlight.endTime - highlight.startTime);

    switch (SessionHighlightEncodingStatus[highlight.encodingStatus]) {
        case SessionHighlightEncodingStatus.Completed:
            return (
                <div className={classes.root} onClick={onClick}>
                    { showTitle &&
                        <div className={'VL-HighlightTile-TextBlock'}>
                            <div className={'VL-HighlightTile-Title'}>{ highlight.title }</div>
                        </div>
                    }

                    <img src={highlight.thumbnailUri} height="755" width="1382" />

                    <div className="VL-HighlightTile-Duration">
                        {formattedDuration}
                    </div>
                    <div className="VL-HighlightTile-IconContainer">
                        <PlayCircleFilled />
                    </div>
                </div>
            );
        case SessionHighlightEncodingStatus.NotStarted:
        case SessionHighlightEncodingStatus.Running:
            return (
                <div className={`${classes.root} VL-HighlightTile-Unprocessed`}>
                    <img src="data:," height="755" width="1382" alt="Processing Highlight" />
                    <div className="VL-HighlightTile-IconContainer">
                        <CircularProgress size={30} />
                        <Typography variant="subtitle2" display="block">Processing Highlight</Typography>
                    </div>
                </div>
            );
        case SessionHighlightEncodingStatus.Cancelled:
        case SessionHighlightEncodingStatus.Error:
            return (
                <div className={`${classes.root} VL-HighlightTile-Errored`}>
                    <img src="data:," height="755" width="1382" alt="Error Processing Highlight" />
                    <div className="VL-HighlightTile-IconContainer">
                        <Error />
                        <Typography variant="subtitle2" display="block">
                            { t('sessionHighlights.text.genericEncodingError') }
                        </Typography>
                    </div>
                </div>
            );
        default:
            console.error(`Encountered unrecognised SessionHighlightEncodingStatus: ${highlight.encodingStatus}`);
            return null;
    }
};

HighlightTile.propTypes = {
    highlight: PropTypes.shape({
        /** ISO8601 formatted duration string */
        duration: PropTypes.string,
        /** The highlight encodingStatus */
        encodingStatus: PropTypes.string.isRequired,
        thumbnailUri: PropTypes.string,
        title: PropTypes.string,
        endTime: PropTypes.number,
        startTime: PropTypes.number
    }),
    onClick: PropTypes.func
};

export default HighlightTile;

import { Enumify } from 'enumify';

const fgProps = { urlComponent: 'FocusGroup', shouldShowStatus: true, iconColor: '#32615E' };
const idiProps = { urlComponent: 'FocusGroup', shouldShowStatus: true, iconColor: '#E69700' };

class ProductType extends Enumify {
    static focusGroup = new ProductType(fgProps);
    static focusGroupAv = new ProductType(fgProps);
    static focusGroupV2 = new ProductType({
        ...fgProps,
        portalCreateURL: '/FocusGroupWizard/Create?Type=FocusGroupV2'
    });
    static focusGroupV2Av = new ProductType({
        ...fgProps,
        portalCreateURL: '/FocusGroupWizard/Create?Type=FocusGroupV2Av'
    });
    static stimBasedFocusGroupV2 = new ProductType({
        ...fgProps,
        portalCreateURL: '/FocusGroupWizard/Create?Type=FocusGroupV2&IsStimBasedSession=true'
    });
    static stimBasedFocusGroupV2Av = new ProductType({
        ...fgProps,
        portalCreateURL: '/FocusGroupWizard/Create?Type=FocusGroupV2Av&IsStimBasedSession=true'
    });

    static inDepthInterview = new ProductType(idiProps);
    static inDepthInterviewAv = new ProductType(idiProps);
    static inDepthInterviewV2 = new ProductType({
        ...idiProps,
        portalCreateURL: '/InDepthInterviewWizard/Create?Type=InDepthInterviewV2'
    });
    static inDepthInterviewV2Av = new ProductType({
        ...idiProps,
        portalCreateURL: '/InDepthInterviewWizard/Create?Type=InDepthInterviewV2Av'
    });
    static stimBasedInDepthInterviewV2 = new ProductType({
        ...fgProps,
        portalCreateURL: '/InDepthInterViewWizard/Create?Type=InDepthInterviewV2&IsStimBasedSession=true'
    });
    static stimBasedInDepthInterviewV2Av = new ProductType({
        ...fgProps,
        portalCreateURL: '/InDepthInterViewWizard/Create?Type=InDepthInterviewV2Av&IsStimBasedSession=true'
    });

    static bulletinBoard = new ProductType();
    static survey = new ProductType();

    static brainstorm = new ProductType({
        urlComponent: 'Brainstorm',
        iconColor: '#935555',
        portalCreateURL: '/Brainstorm/Create'
    });

    static heatmapConceptTest = new ProductType({
        urlComponent: 'Heatmap',
        iconColor: 'rgb(179, 76, 208)',
        portalCreateURL: '/Heatmap/Create'
    });

    static videodialConceptTest = new ProductType({
        urlComponent: 'Videodial',
        iconColor: '#465C79',
        portalCreateURL: '/Videodial/Create'
    });

    static _ = this.closeEnum();

    constructor (options) {
        super();
        this.urlComponent = options?.urlComponent;
        this.shouldShowStatus = options?.shouldShowStatus ?? false;
        this.iconColor = options?.iconColor;
        this.name = options?.name;
        this.portalCreateURL = options?.portalCreateURL;
    }
}

export const FocusGroupUmbrella = [
    ProductType.focusGroup,
    ProductType.focusGroupAv,
    ProductType.focusGroupV2,
    ProductType.focusGroupV2Av,
    ProductType.stimBasedFocusGroupV2Av
];

export const InDepthInterviewUmbrella = [
    ProductType.inDepthInterview,
    ProductType.inDepthInterviewAv,
    ProductType.inDepthInterviewV2,
    ProductType.inDepthInterviewV2Av,
    ProductType.stimBasedInDepthInterviewV2Av
];

// Strict indicates that the FocusGroup product is ultimately
// used for In-Depth Interviews as well as traditional Focus Groups (2+ participants).
export const StrictFocusGroupUmbrella = [...FocusGroupUmbrella, ...InDepthInterviewUmbrella];

export const AVSessionUmbrella = [
    ProductType.focusGroupAv,
    ProductType.focusGroupV2Av,
    ProductType.inDepthInterviewAv,
    ProductType.inDepthInterviewV2Av,
    ProductType.stimBasedInDepthInterviewV2Av,
    ProductType.stimBasedFocusGroupV2Av
];

export default ProductType;

import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const useReleaseNotes = () => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['releaseNotes'],
        () => getReleaseNotes()
    );

    return { data, refetch, isLoading, isError };
};

const getReleaseNotes = async () => {
    const { data } = await vlqrApi.get('ReleaseNotes')
        .catch((error) => {
            handleError(error);
        });

    return data;
};

export default useReleaseNotes;
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

const ScalingChartContainer = ({title, chartSize, scalar, children}) => {
    const [expanded, setExpanded] = useState(false);

    let size = { width: chartSize.width, height: chartSize.height, scale: '1' };
    if (expanded) {
        size = { width: chartSize.width * scalar, height: chartSize.height * scalar, scale: scalar };
    }

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ textAlign: 'center', width: size.width, height: size.height + 76 }}>
                {
                    expanded
                        ? <Button sx={{ position: 'absolute', right: '20px' }} variant="outlined" onClick={() => setExpanded(false)}><CloseFullscreenIcon /></Button>
                        : <Button sx={{ position: 'absolute', right: '20px' }} variant="outlined" onClick={() => setExpanded(true)}><OpenInFullIcon /></Button>
                }
                <h2>{title}</h2>
                <div style={{ position: 'relative' }}>
                    <div style={{ scale: size.scale.toString(), position: 'absolute', marginTop: chartSize.height / -2, marginLeft: chartSize.width / -2, transform: 'translate(50%, 50%)' }}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

ScalingChartContainer.propTypes = {
    title: PropTypes.string,
    chartSize: PropTypes.object,
    scalar: PropTypes.number,
    children: PropTypes.node
};

export default ScalingChartContainer;
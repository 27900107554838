import { useMutation } from 'react-query';
import vlqrApi from 'utils/vlqrApi';

const useCreateHighlightRequest = () => {
    const { mutate, isLoading } = useMutation(highlight => {
        return vlqrApi.post('VideoHighlights', highlight);
    });

    return { mutate, isLoading };
};

export default useCreateHighlightRequest;

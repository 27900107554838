import React, { useCallback, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import { Link, Paper, useTheme } from '@mui/material';
import PageHeader from 'components/shared/PageHeader';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import TopBar from 'components/TopBar/TopBar';
import useCompanies from 'api/useCompanies';
import useRecentCompanies from 'api/useRecentCompanies';

const useStyles = makeStyles(theme => ({
    recentCompaniesList: {
        '& .VL-RecentCompany': {
            padding: '16px',
            borderBottom: `1px solid ${theme.dividers.contentDivider.backgroundColor}`,
            '&:last-child': {
                border: 'none'
            }
        }
    }
}));

const SelectCompany = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();

    const { data: companies } = useCompanies();
    const { data: recentCompanies, isLoading } = useRecentCompanies();

    const selectCompany = useCallback(async (company) => {
        navigate(`/company/${company.id}`);
    }, [navigate]);

    useEffect(() => {
        // If only one company is detected, select that company immediately.
        if (companies?.length === 1) {
            selectCompany(companies[0]);
        }
    }, [companies, selectCompany]);

    const renderLoading = () => {
        if (isLoading) {
            return (
                <LoadingPlaceholder />
            );
        }

        return null;
    };

    const renderResultsTable = () => (
        <React.Fragment>
            <Paper className={classes.recentCompaniesList}>
                { recentCompanies.map(recentCompany => (
                    <div
                        key={`recent-company-${recentCompany.companyId}`}
                        className="VL-RecentCompany"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Link
                            to={`/company/${recentCompany.companyId}`}
                            component={RouterLink}
                            variant="subtitle1"
                            underline="hover"
                            style={{ ...theme.typography.primaryItemLink }}
                        >
                            { recentCompany.companyName }
                        </Link>
                    </div>
                ))}
            </Paper>
        </React.Fragment>
    );

    const renderSelectionUI = () => (
        <React.Fragment>
            <TopBar />
            <PageHeader>
                <Container>
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                title: t('selectCompanyPage.text.recentCompanies')
                            }
                        ]}
                    />
                </Container>
            </PageHeader>

            <Container style={{ paddingTop: 16 }}>
                <div style={{ paddingTop: 16, maxWidth: 620, margin: 'auto' }}>
                    { recentCompanies.length > 0 ?
                        renderResultsTable() :
                        <Paper style={{ padding: 16 }}>
                            <Typography variant="body2">{ t('selectCompanyPage.text.noRecentCompaniesFound') }</Typography>
                        </Paper>
                    }
                </div>
            </Container>
        </React.Fragment>
    );

    return (
        renderLoading() || renderSelectionUI()
    );
};

export default SelectCompany;

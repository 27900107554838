import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IFrameResizer from 'iframe-resizer-react';
import { useTheme } from '@mui/material/styles';
import Fade from '@mui/material/Fade';

import { apiUrl } from '../../Config';
import LoadingPlaceholder from '../shared/LoadingPlaceholder';
import { handleAuthenticationError } from '../../utils/portalApi';

const VL_EVENT_TYPES = {
    CURRENT_LOCATION: 'currentLocation',
    ON_BEFORE_UNLOAD: 'onBeforeUnload',
    FETCH_FRAME_INFO: 'fetchFrameInfo',
    FRAME_INFO_FETCHED: 'frameInfoFetched'
};

const PortalIFrame = (props) => {
    const theme = useTheme();
    const history = window.history;
    const [frameIsLoaded, setFrameIsLoaded] = useState(false);
    const frameSource = `${apiUrl}${props.relativeUrl}`;

    const [isLoading, setIsLoading] = useState(false);

    const { onUrlChange, onDashboardRedirect, onSummaryRedirect, frameInfo } = props;
    const [frameKey, setFrameKey] = useState(`frame-key-${Date.now()}`);

    useEffect(() => {
        const handleCurrentLocationEvent = (event) => {
            setIsLoading(false);

            // Optional function syntax
            // eslint-disable-next-line no-unused-expressions
            onUrlChange?.(event.data.href, event);

            // When we detect /Project/Dashboard in legacy Portal it usually means that
            // the action within the iframe has been completed.
            if (event.data.href.indexOf('/Project/Dashboard') >= 0) {
                // Update the frame key, which forces the iframe to reload and therefore
                // return to the original URL.
                setFrameKey(`${frameKey}-${Date.now()}`);

                // Optional function syntax
                // eslint-disable-next-line no-unused-expressions
                onDashboardRedirect?.();
            }

            // When we detect /FocusGroup/Summary in legacy Portal is means that we've
            // been redirected back to the Summary view. This would occur after editing
            // a specific area of activity details, e.g. Activity Details, Schedule, etc.
            if (event.data.href.indexOf('/FocusGroup/Summary') >= 0) {
                // Optional function syntax
                // eslint-disable-next-line no-unused-expressions
                onSummaryRedirect?.();
            }

            if (event.data.href.indexOf('/Membership/LogIn') >= 0) {
                handleAuthenticationError();
            }
        };

        const handleFetchFrameInfoEvent = (source, origin) => {
            source.postMessage({ vlEventType:  VL_EVENT_TYPES.FRAME_INFO_FETCHED, data: frameInfo }, origin);
        };

        const eventHandler = (event) => {
            if (event.origin === apiUrl) {
                if (event.data.vlEventType === VL_EVENT_TYPES.CURRENT_LOCATION) {
                    handleCurrentLocationEvent(event);
                } else if (event.data.vlEventType === VL_EVENT_TYPES.ON_BEFORE_UNLOAD) {
                    setIsLoading(true);
                } else if (event.data.vlEventType === VL_EVENT_TYPES.FETCH_FRAME_INFO) {
                    handleFetchFrameInfoEvent(event.source, event.origin);
                }
            }
        };

        window.addEventListener('message', eventHandler);

        return (() => {
            // Remove eventListener when component is cleaned up.
            window.removeEventListener('message', eventHandler);
        });
    }, [history, frameKey, onUrlChange, onDashboardRedirect, frameInfo, onSummaryRedirect]);

    const handleFrameLoad = (event) => {
        if (frameIsLoaded === false) {
            setFrameIsLoaded(true);
            return;
        }
    };

    return (
        <React.Fragment>
            <div style={{ padding: theme.spacing(1), overflow: 'auto' }} {...props?.paperProps}>
                { !frameIsLoaded && <LoadingPlaceholder height={'auto'} /> }

                <Fade in={frameIsLoaded}>
                    <div style={{ position: 'relative' }}>
                        <IFrameResizer
                            key={frameKey}
                            allowFullScreen
                            heightCalculationMethod="taggedElement"
                            id={props.id}
                            width="960px"
                            height="0"
                            title={props.title ?? props.id}
                            src={frameSource}
                            onLoad={handleFrameLoad}
                            style={{ border: 'none', display: 'block', margin: 'auto' }} />

                        { isLoading &&
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(255,255,255,0.75)'
                            }}>
                                <LoadingPlaceholder height="100%" />
                            </div>
                        }
                    </div>
                </Fade>
            </div>
        </React.Fragment>
    );
};

PortalIFrame.propTypes = {
    relativeUrl: PropTypes.string,
    onUrlChange: PropTypes.func,
    onDashboardRedirect: PropTypes.func,
    onSummaryRedirect: PropTypes.func,
    frameInfo: PropTypes.object,
    paperProps: PropTypes.object,
    id: PropTypes.string,
    title: PropTypes.string
};

export default PortalIFrame;

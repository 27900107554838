import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getHeatmapActivityResponses = async (activityId) => {
    const { data } = await vlqrApi.get(`reports/activities/${activityId}/heatmapResponses`)
        .catch((error) => {
            handleError(error);
        });

    // Exclude deleted sentiment/comments
    return data?.filter(x => !x.deletedOn);
};

const useHeatmapResponsesForActivity = (activityId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['heatmapResponsesForActivity', activityId],
        () => getHeatmapActivityResponses(activityId)
    );

    return { data, refetch, isLoading, isError };
};

export default useHeatmapResponsesForActivity;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { CardActionArea } from '@mui/material';

import ActivityIcon from '../shared/activityIcon/ActivityIcon';
import ProductType from '../../enums/ProductType';
import TestBackground from './productCards/idi.jpg';
import { useCardStyles } from './UseCardStyles';
import AVProductCardActions from './AVProductCardActions';
import { NewProductChip } from './NewProductChip';

const StimSessionIDICard = ({ onClick }) => {
    const { t } = useTranslation();
    const classes = useCardStyles({ color: '#E7762B' });

    return (
        <Card className={classes.card}>
            <CardActionArea className={classes.cardActionArea} disabled>
                <Avatar className={classes.iconAvatar}>
                    <ActivityIcon productType={ProductType.stimBasedInDepthInterviewV2Av} />
                </Avatar>

                <CardMedia
                    image={TestBackground}
                    title={t('productTypes.text.stimBasedSessions.inDepthInterview')}
                    className={classes.media}
                >
                    <NewProductChip />
                </CardMedia>


                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                        { t('productTypes.text.stimBasedSessions.inDepthInterview') }
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        { t('productDescriptions.text.stimBasedSessions.inDepthInterview') }
                    </Typography>
                </CardContent>
            </CardActionArea>

            <AVProductCardActions
                productType={ProductType.stimBasedInDepthInterviewV2}
                avProductType={ProductType.stimBasedInDepthInterviewV2Av}
                onClick={onClick}
                isStimBasedSession />
        </Card>
    );
};

StimSessionIDICard.propTypes = {
    onClick: PropTypes.func
};

export default React.memo(StimSessionIDICard);

import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Stack, Button, FormControl, OutlinedInput, InputLabel, InputAdornment, IconButton, TextField, Divider, Snackbar, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import vlqrApi, { handleError } from 'utils/vlqrApi';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';

const ListItem = styled('li')(({ theme }) => ({
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:hover': {
        background: 'lightgray'
    }
}));

const EditFiltersContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '300px'
}));

export const EditTextAnalysisFilter = ({item, goBack}) => {
    const { t } = useTranslation();

    const [words, setWords] = useState(item.filter);
    const [newWord, setNewWord] = useState('');
    const [name, setName] = useState(item.name);

    const [snackbar, setSnackbar] = useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    const removeWord = (word) => {
        setWords(words.filter((w) => w !== word));
    };

    const addWord = (word) => {
        const trimmedWord = word.trim();

        // Guard against empty words being added.
        if (trimmedWord.length === 0) {
            return;
        }

        if (trimmedWord.includes(';')) {
            // Words can't contain semi-colons
            setSnackbar({ children: t('textAnalysisDashboard.text.filterWordError'), severity: 'error' });
        } else {
            const index = words.indexOf(trimmedWord);
            if (index === -1) {
                setWords(words => [...words, trimmedWord]);
            }

            setNewWord('');
        }
    };

    const onNewWordChange = (e) => {
        setNewWord(e.target.value);
    };

    const onNameChange = (e) => {
        setName(e.target.value);
    };

    const saveFilter = () => {
        item.name = name;
        item.filter = words;

        const config = { headers: {'Content-Type': 'application/json'} };

        if (item.id) {
            return new Promise((resolve, reject) =>
                vlqrApi.put('TextAnalysis/WordCloudFilter', item, config)
                    .then(response => {
                        resolve(response);
                        goBack();
                    })
                    .catch(error => {
                        handleError(error);
                        reject(new Error(error));
                        setSnackbar({ children: t('textAnalysisDashboard.text.filterSaveError'), severity: 'error' });
                    })
            );
        } else {
            return new Promise((resolve, reject) =>
                vlqrApi.post('TextAnalysis/WordCloudFilter', item, config)
                    .then(response => {
                        resolve(response);
                        goBack();
                    })
                    .catch(error => {
                        handleError(error);
                        reject(new Error(error));
                        setSnackbar({ children: t('textAnalysisDashboard.text.filterSaveError'), severity: 'error' });
                    })
            );
        }
    };

    return (
        <EditFiltersContainer>
            <Box
                component="form"
                sx={{
                    m: '10px', width: '500px'
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    id="filter-name"
                    label="Filter Name"
                    value={name}
                    onChange={onNameChange}
                    sx={{ width: '100%', marginBottom: '10px' }}
                />
                <Divider />
                <FormControl sx={{ width: '100%', marginTop: '10px' }} variant="outlined">
                    <InputLabel id="new-word-label">{t('textAnalysisDashboard.labels.addWord')}</InputLabel>
                    <OutlinedInput
                        id="new-word"
                        labelId="new-word-label"
                        type='text'
                        value={newWord}
                        onChange={onNewWordChange}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                addWord(newWord);
                            }
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={t('textAnalysisDashboard.labels.addWordToFilter')}
                                    onClick={() => addWord(newWord)}
                                    edge="end"
                                    disabled={newWord.trim().length === 0}
                                >
                                    <AddIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        label={t('textAnalysisDashboard.labels.addWord')}
                    />
                </FormControl>
            </Box>
            {
                words.length > 0 ?
                    <Box
                        sx={{
                            listStyle: 'none',
                            p: 0.5,
                            m: 1,
                            maxHeight: '500px',
                            maxWidth: '500px',
                            overflowY: 'scroll'
                        }}
                        component="ul"
                    >
                        {
                            words.sort().map((w) =>
                                <ListItem key={w}>
                                    <span>{w} <DeleteIcon sx={{ float: 'right', cursor: 'pointer' }} onClick={() => removeWord(w)} /></span>
                                </ListItem>
                            )
                        }
                    </Box>
                    :
                    <p style={{ margin: '10px', maxWidth: '500px', textAlign: 'center' }}>
                        {
                            t('textAnalysisDashboard.text.filterNoWords')
                        }
                    </p>
            }
            <Box sx={{ height: '60px', position: 'relative', bottom: '0px', padding: '15px' }}>
                <Button variant="outlined" onClick={goBack}>Back</Button>
                <Stack sx={{ float: 'right' }} direction="row" spacing={2}>
                    <Button variant="outlined" onClick={() => setWords([])}>{t('textAnalysisDashboard.labels.clearFilter')}</Button>
                    <Button variant="contained" onClick={() => saveFilter()}>{t('textAnalysisDashboard.labels.saveFilter')}</Button>
                </Stack>
            </Box>
            {snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={5000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </EditFiltersContainer>
    );
};

EditTextAnalysisFilter.propTypes = {
    item: PropTypes.object,
    goBack: PropTypes.func
};
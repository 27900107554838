import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactWordcloud from 'react-wordcloud';
import { filterWords } from '../WordFilter';
import Button from '@mui/material/Button';
import ResponsiveDialog from 'components/shared/ResponsiveDialog';
import { TextAnalysisFilters } from '../TextAnalysisFilters';
import useWordCloudFilterForActivity from 'api-new/useWordCloudFilterForActivity';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import ScalingChartContainer from './ScalingChartContainer';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
    deterministic: true,
    enableOptimizations: true,
    enableTooltip: true,
    scale: 'log',
    spiral: 'archimedean',
    fontFamily: 'times new roman',
    fontSizes: [8, 64],
    padding: 1,
    tooltipOptions: {
        theme: ''
    }
};

const size = [700, 550];


const Wordcloud = ({words, onWordClick, activityId, projectId}) => {
    const { t } = useTranslation();
    const { data: filter, isLoading: filterLoading, refetch: reloadFilter } = useWordCloudFilterForActivity(activityId);
    const [editWordFilterDialogIsOpen, setEditWordFilterDialogIsOpen] = useState(false);

    const filteredWords = useMemo(() => {
        if (filter) {
            return filterWords(words, filter.filter);
        } else {
            return words;
        }
    }, [words, filter]);

    const callbacks = useMemo(() => {
        return { onWordClick: word => onWordClick(word) };
    }, [onWordClick]);

    const closeFilterModal = () => {
        setEditWordFilterDialogIsOpen(false);
        reloadFilter();
    };

    if (filterLoading) {
        return (
            <LoadingPlaceholder />
        );
    }

    return (
        <div style={{ position: 'relative' }}>
            <Button sx={{ position: 'absolute', left: '20px', zIndex: '2' }} variant="contained" onClick={() => setEditWordFilterDialogIsOpen(true)}>Filters</Button>
            <ScalingChartContainer title={t('textAnalysisDashboard.labels.wordCloudTitle')} chartSize={{ width: 700, height: 550 }} scalar={1.5}>
                <ReactWordcloud words={filteredWords} options={options} size={size} callbacks={callbacks} />
            </ScalingChartContainer>

            <ResponsiveDialog
                fullWidth={false}
                maxWidth='md'
                heading={t('textAnalysisDashboard.text.wordCloudFiltersTitle')}
                open={editWordFilterDialogIsOpen}
                onCancel={() => closeFilterModal()}
                onClose={() => closeFilterModal()}
            >
                <TextAnalysisFilters activityId={activityId} projectId={projectId} currentFilterId={filter.id} onApplyFilter={reloadFilter}/>
            </ResponsiveDialog>
        </div>
    );
};

Wordcloud.propTypes = {
    words: PropTypes.array,
    onWordClick: PropTypes.func,
    activityId: PropTypes.string,
    projectId: PropTypes.string
};

export default Wordcloud;
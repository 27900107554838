import { styled, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { LoadingPlaceholderFullPage } from 'components/shared/LoadingPlaceholderFullPage';
import useParticipantsForActivity from 'api-new/useParticipantsForActivity';
import usePollAnswersForActivity from 'api-new/stim-based/usePollAnswersForActivity';
import usePollOptionsForActivity from 'api-new/stim-based/usePollOptionsForActivity';
import useStimulusActivity from 'api-new/stim-based/useStimulusActivity';
import { PollActivityResultView } from 'vl-reporting-components';
import PollActivityResultsExportButton from './PollActivityResultsExportButton';
import _ from 'lodash';
import { PrintOutlined } from '@mui/icons-material';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';
import useActivity from 'api/useActivity';

const PrintButton = styled(Button)(({ theme }) => ({
    ...theme.viewReportButton
}));

const PollActivityResults = ({activityId, focusGroupId, isOpen, onClose}) => {
    const { data: activity, isLoading: isLoadingActivity } = useStimulusActivity(activityId);
    const { data: pollOptionsData, isLoading: isLoadingPollOptions } = usePollOptionsForActivity(activityId);
    const { data: pollAnswersData, isLoading: isLoadingPollAnswers } = usePollAnswersForActivity(activityId);
    const { data: participants, isLoading: isLoadingParticipants } = useParticipantsForActivity(focusGroupId);
    const { data: focusGroup, isLoading: isLoadingFocusGroup } = useActivity(focusGroupId);

    const { t } = useTranslation();

    let componentRef = useRef();
    const [hideAnswersWithoutVote, setHideAnswersWithoutVote] = useState(false);

    const onFilterChange = (filter) => {
        setHideAnswersWithoutVote(filter.hideAnswersWithoutVote);
    };

    if (isLoadingActivity || isLoadingPollOptions || isLoadingPollAnswers || isLoadingParticipants || isLoadingFocusGroup) {
        return <LoadingPlaceholderFullPage />;
    }

    // Format participant names so that they are ready for PollActivityResultView component
    const participantNameMap = _.keyBy(participants, 'focusGroupUserId');

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth='xl'
            fullWidth
        >
            <DialogContent
                ref={componentRef}
                sx={{
                    '& .MuiTypography-subtitle2': { fontWeight: 500 },
                    '& .MuiListItemText-secondary': { color: 'grey.1' }
                }}
            >
                <PollActivityResultView
                    options={pollOptionsData}
                    answers={pollAnswersData}
                    participants={participantNameMap}
                    activity={activity}
                    onFilterChange={onFilterChange}
                    focusGroupTitle={focusGroup.details.title}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>
                    { t('common.buttons.close') }
                </Button>

                <ReactToPrint
                    trigger={() =>
                        <PrintButton startIcon={<PrintOutlined />}>
                            {t('focusGroups.activities.print')}
                        </PrintButton>
                    }
                    content={() => componentRef.current}
                />

                <PollActivityResultsExportButton
                    pollAnswersData={pollAnswersData}
                    pollOptionsData={pollOptionsData}
                    participants={participants}
                    activity={activity}
                    hideAnswersWithoutVote={hideAnswersWithoutVote}
                />
            </DialogActions>
        </Dialog>
    );
};

PollActivityResults.propTypes = {
    activityId: PropTypes.string,
    focusGroupId: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};

export default PollActivityResults;

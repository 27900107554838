import React from 'react';
import { ReactComponent as LikeIcon } from './1f44d.svg';
import { ReactComponent as DislikeIcon } from './1f44e.svg';
import { ReactComponent as LoveIcon } from './2764.svg';
import { ReactComponent as IdeaIcon } from './1f4a1.svg';
import { ReactComponent as LaughIcon } from './1f602.svg';
import { ReactComponent as SurpriseIcon } from './1f632.svg';

const SENTIMENT_ICONS = {
    LIKE: <LikeIcon />,
    DISLIKE: <DislikeIcon />,
    LOVE: <LoveIcon />,
    IDEA: <IdeaIcon />,
    LAUGH: <LaughIcon />,
    SURPRISE: <SurpriseIcon />
};

const getSentimentIcon = (vlSentimentIdentifier) => {
    if (SENTIMENT_ICONS[vlSentimentIdentifier]) {
        return SENTIMENT_ICONS[vlSentimentIdentifier];
    }

    return null;
};

export default getSentimentIcon;

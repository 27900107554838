import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, Button, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingPlaceholderFullPage } from 'components/shared/LoadingPlaceholderFullPage';
import { Typography } from '@mui/material';
import vlqrApi from 'utils/vlqrApi';
import { useNavigate } from 'react-router-dom';

export const TextAnalysisGeneration = ({activityId, isAVSession}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [generationError, setGenerationError] = useState(false);

    let url = `TextAnalysis/GenerateVoiceTranscriptionData/${activityId}`;
    if (!isAVSession) {
        url = `TextAnalysis/GenerateRespondentChatData/${activityId}`;
    }

    const onClickGenerate = () => {
        setLoading(true);
        vlqrApi
            .put(url)
            .then(response => {
                console.log(response);
                setLoading(false);
                navigate(0);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
                setGenerationError(true);
            });
    };

    return (
        <React.Fragment>
            { loading &&
                <LoadingPlaceholderFullPage>
                    <Typography variant="overline">Generating Text Analysis Data</Typography>
                </LoadingPlaceholderFullPage>
            }
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    backgroundColor: '#f0f4f8'
                }}
            >
                { generationError ? 
                    <Card variant="outlined" sx={{ margin: '5px', maxWidth: '1200px', textAlign: 'center', paddingBottom: '16px' }}>
                        <Alert sx={{ mt: 2, margin: '16px' }} severity="error">{ t('textAnalysisDashboard.text.generationError') }</Alert>
                    </Card> 
                    :
                    <Card variant="outlined" sx={{ margin: '5px', maxWidth: '1200px', textAlign: 'center', paddingBottom: '16px' }}>
                        <Alert sx={{ mt: 2, margin: '16px' }} severity="info">{ t('textAnalysisDashboard.text.generationInstructions') }</Alert>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => onClickGenerate(activityId)}
                        >
                            Generate Text Analysis Data
                        </Button>
                    </Card>   
                }
            </Box>
        </React.Fragment>
    );
};

TextAnalysisGeneration.propTypes = {
    activityId: PropTypes.string,
    isAVSession: PropTypes.bool
};
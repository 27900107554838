import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ActivityAvatar from 'components/shared/ActivityAvatar';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

const ActivitySearchResult = ({ 
    activityType, 
    activityName, 
    projectId, 
    activityId, 
    companyId, 
    companyName, 
    projectName
}) => {
    const theme = useTheme();

    return (
        <div className="VL-SearchResult" style={{ display: 'flex', alignItems: 'center' }}>
            <ActivityAvatar productType={activityType} status="neutral" />
            <div style={{ marginLeft: 24 }}>
                <Link
                    to={`/company/${companyId}/project/${projectId}/activity/${activityId}`}
                    component={RouterLink}
                    variant="subtitle1"
                    underline="hover"
                    style={{ ...theme.typography.primaryItemLink }}
                >
                    { activityName }
                </Link>

                <MuiBreadcrumbs separator="›">
                    <Link
                        to={`/company/${companyId}`}
                        component={RouterLink}
                        variant="subtitle2"
                        color="textSecondary"
                        underline="hover">
                        { companyName }
                    </Link>
                    <Link
                        to={`/company/${companyId}/project/${projectId}`}
                        component={RouterLink}
                        variant="subtitle2"
                        color="textSecondary"
                        underline="hover">
                        { projectName }
                    </Link>
                </MuiBreadcrumbs>
            </div>
        </div>
    );
};

ActivitySearchResult.propTypes = {
    activityId: PropTypes.string,
    activityName: PropTypes.string,
    activityType: PropTypes.string,
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    projectId: PropTypes.string,
    projectName: PropTypes.string
};

export default ActivitySearchResult;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { VideoTrimmerContext } from './CreateSessionHighlight/VideoTrimmerProvider';
import { ERROR_CODES } from './CreateSessionHighlight/VideoTrimmerValidation';
import { Error } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    root: {
    }
}));
const HighlightDetails = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        highlightTitle,
        setHighlightTitle,
        highlightDescription,
        setHighlightDescription,
        errors
    } = useContext(VideoTrimmerContext);

    const getTitleTooltip = () => {
        if (errors.includes(ERROR_CODES.TITLE_TOO_LONG)) {
            return t('sessionHighlights.errors.titleTooLong');
        } else {
            return t('sessionHighlights.tooltips.title');
        }
    };

    const getDescriptionTooltip = () => {
        if (errors.includes(ERROR_CODES.DESCRIPTION_TOO_LONG)) {
            return t('sessionHighlights.errors.descriptionTooLong');
        } else {
            return t('sessionHighlights.tooltips.description');
        }
    };

    const getTitleAdornment = () => {
        if (errors.includes(ERROR_CODES.TITLE_TOO_LONG)) {
            return (
                <InputAdornment position="start">
                    <Error style={{ fill: 'red' }} />
                </InputAdornment>
            );
        } else {
            return null;
        }
    };

    const getDescriptionAdornment = () => {
        if (errors.includes(ERROR_CODES.DESCRIPTION_TOO_LONG)) {
            return (
                <InputAdornment position="start">
                    <Error style={{ fill: 'red' }} />
                </InputAdornment>
            );
        } else {
            return null;
        }
    };


    return (<div className={classes.root}>
        <Tooltip arrow title={getTitleTooltip()}>
            <TextField
                fullWidth
                variant="standard"
                size="small"
                margin="dense"
                className={classes.clipValue}
                value={highlightTitle}
                label={t('sessionHighlights.text.title')}
                onChange={e => setHighlightTitle(e.target.value)}
                InputProps={{
                    startAdornment: getTitleAdornment(),
                }}
            />
        </Tooltip>

        <Tooltip arrow title={getDescriptionTooltip()}>
            <TextField
                fullWidth
                variant="standard"
                size="small"
                margin="dense"
                className={classes.clipValue}
                value={highlightDescription}
                label={t('sessionHighlights.text.description')}
                multiline
                rows={4}
                onChange={e => setHighlightDescription(e.target.value)}
                InputProps={{
                    startAdornment: getDescriptionAdornment(),
                }}
            />
        </Tooltip>
    </div>);
};

export default HighlightDetails;

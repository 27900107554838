import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const IconTab = ({ link, icon, isActive }) => {
    const classes = classNames({ 'VL-IconTab': true, 'VL-IconTabActive': isActive });

    return (
        <RouterLink to={link} className={classes}>
            { icon }
        </RouterLink>
    );
};

IconTab.propTypes = {
    icon: PropTypes.node,
    isActive: PropTypes.bool,
    link: PropTypes.string
};

export default IconTab;

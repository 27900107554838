import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useMatch } from 'react-router-dom';
import Container from '@mui/material/Container';
import ProjectsIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import portalApi, { handleError } from 'utils/portalApi';
import VerticalTabs from 'components/shared/VerticalTabs';
import { useTranslation } from 'react-i18next';
import CompanyRole from 'enums/CompanyRole';
import PageHeader from 'components/shared/PageHeader';
import { Typography } from '@mui/material';
import StyledPageTab from 'components/shared/StyledPageTab';
import TopBar from 'components/TopBar/TopBar';
import useCompany from 'api/useCompany';
import { Outlet } from 'react-router-dom';

const logCompanySelection = (companyId) => {
    portalApi
        .post(`/api/companies/${companyId}/logSelection`)
        .catch(handleError);
};

const getTabs = (companyRole, t) => {
    return [
        {
            key: 'projects',
            label: t('companyPage.tabs.manageProjects'),
            icon: <ProjectsIcon />,
            enabled: true
        },
        {
            key: 'users',
            label: t('companyPage.tabs.manageUsers'),
            icon: <GroupIcon />,
            enabled: companyRole?.canManageUsers
        },
        {
            key: 'usage-and-billing',
            label: t('companyPage.tabs.manageUsageAndBilling'),
            icon: <WalletIcon />,
            enabled: companyRole?.canManageUsageAndBilling
        },
        {
            key: 'settings',
            label: t('companyPage.tabs.companySettings'),
            icon: <SettingsIcon />,
            enabled: companyRole?.canManageSettings
        }
    ];
};

const CompanyOverview = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        company_guid: companyId
    } = useParams();
    const companyTabMatch = useMatch({
        path: '/company/:company_guid/:tab',
        end: true,
        exact: true
    });
    const tabKey = companyTabMatch?.params?.tab;

    const { data: selectedCompany, isLoading } = useCompany(companyId);

    const tabs = useMemo(() => getTabs(CompanyRole[selectedCompany?.role], t), [selectedCompany, t]);

    useEffect(() => {
        // Log the company as 'recently visited/selected'
        logCompanySelection(companyId);
    }, [companyId]);
    
    const loading = () => (
        isLoading ? <LoadingPlaceholder /> : null
    );

    const main = () => (
        <div style={{ backgroundColor: 'white' }}>
            <TopBar companyId={companyId} />
            <PageHeader>
                <Container>
                    <Typography variant="h1">{ selectedCompany.name }</Typography>

                    <VerticalTabs
                        value={Math.max(tabs.findIndex((tab) => tab.key === tabKey), 0)}
                        onChange={(event, newValue) => {
                            const targetUrl = `/company/${companyId}/${tabs[newValue].key}`;
                            navigate(targetUrl);
                        }}
                        variant="scrollable"
                    >
                        { tabs.filter(tab => tab.enabled).map(tab =>
                            <StyledPageTab key={tab.key} label={tab.label} sx={{ textTransform: 'none' }} />
                        )}
                    </VerticalTabs>
                </Container>
            </PageHeader>

            <Container>
                <div>
                    <Outlet context={{companyId}} />
                </div>
            </Container>
        </div>
    );

    return (
        loading() || main()
    );
};

export default CompanyOverview;

import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import makeStyles from '@mui/styles/makeStyles';
import UserMenu from '../shared/Menus/UserMenu';
import UserAvatar from '../shared/UserAvatar/UserAvatar';
import HomeIconTab from './HomeIconTab';
import CompanyIconTab from './CompanyIconTab';
import ProjectIconTab from './ProjectIconTab';
import ActivityIconTab from './ActivityIconTab';
import GlobalSearchBox from './GlobalSearchBox';
import UserCompanyHeading from './UserCompanyHeading';
import ReleaseNotesNotice from './ReleaseNotesNotice';
import { styled } from '@mui/system';

const useStyles = makeStyles(theme => ({
    ...theme.appBar,
    grow: {
        flexGrow: 1
    }
}));

const VLBreadcrumbIconsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
    '& > span:last-of-type': {
        borderRight: `1px solid ${theme.palette.divider}`
    }
}));

const TopBar = ({
    companyId,
    projectId,
    activityId,
    children
}) => {
    const classes = useStyles();
    const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);

    const handleUserMenuButtonClick = (event) => {
        setUserMenuAnchorEl(event.target);
    };

    const handleCloseUserMenu = () => {
        setUserMenuAnchorEl(null);
    };

    return (
        <React.Fragment>
            <AppBar position="static" className={classes.root} elevation={0} >
                <Toolbar
                    variant="dense"
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <VLBreadcrumbIconsContainer>
                        <HomeIconTab />

                        { companyId &&
                            <CompanyIconTab companyId={companyId} />
                        }

                        { projectId &&
                            <ProjectIconTab companyId={companyId} projectId={projectId} />
                        }

                        { activityId &&
                            <ActivityIconTab activityId={activityId} />
                        }
                    </VLBreadcrumbIconsContainer>

                    <div style={{ flexBasis: 0 }}>
                        <GlobalSearchBox />
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            flexGrow: 1,
                            flexBasis: 0,
                            alignItems: 'center'
                        }}
                    >
                        <UserAvatar style={{ cursor: 'pointer' }} onClick={handleUserMenuButtonClick} />
                        <UserCompanyHeading companyId={companyId} />
                        <ReleaseNotesNotice />
                    </div>
                </Toolbar>

                { children }
            </AppBar>

            <UserMenu
                anchorEl={userMenuAnchorEl}
                onClose={handleCloseUserMenu} />
        </React.Fragment>
    );
};
TopBar.propTypes = {
    children: PropTypes.node
};

export default TopBar;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import useCurrentUserDetails from 'api/useCurrentUserDetails';

const CurrentTimezoneIndicator = (props) => {
    const { i18n, t } = useTranslation();

    const { userDetails } = useCurrentUserDetails();
    const selectedTimezone = userDetails?.ianaTimezoneId;

    const currentTime = DateTime.local().toISO();
    const currentLanguage = props.language ?? i18n.language;
    const timezone = selectedTimezone ?? DateTime.local().zoneName;

    const date = DateTime
        .fromISO(currentTime, { locale: currentLanguage })
        .setZone(timezone);

    return (
        <React.Fragment>
            <Typography variant="body2" display="block">
                { t('currentTimezoneIndicator.text.timezoneInfo', { timezoneName: date.toFormat('ZZZZZ') }) }
            </Typography>
        </React.Fragment>
    );
};

CurrentTimezoneIndicator.propTypes = {
    timezone: PropTypes.string,
    language: PropTypes.string
};

export default CurrentTimezoneIndicator;

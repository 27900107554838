import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextAnalysisContext } from './TextAnalysisProvider';
import { Typography, Alert } from '@mui/material';
import { LoadingPlaceholderFullPage } from 'components/shared/LoadingPlaceholderFullPage';
import Wordcloud from './Charts/WordCloud';
import OverallSentimentChart from './Charts/OverallSentimentChart';
import RecognizedEntities from './Charts/RecognizedEntities';
import SentimentAreaChart from './Charts/SentimentAreaChart';
import { VoiceTranscriptionViewer } from 'components/VoiceTranscriptions/VoiceTranscriptionViewer';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import InlineRecordingViewer from 'pages/ActivityRecordingViewer/InlineRecordingViewer';
import { ChatLogViewer } from 'components/ChatLog/ChatLogViewer';

const getWordCloudDataFromKeyPhrases = (phrases) => {
    return phrases.map(p => ({ text: p.phrase.toLowerCase(), value: p.numberOfInstances }));
};

const getOverallSentimentChartData = (sentiment, t) => {
    return [
        {
            score: sentiment.positiveScore * 100,
            name: t('textAnalysisDashboard.labels.positive'),
        },
        {
            score: sentiment.neutralScore * 100,
            name: t('textAnalysisDashboard.labels.neutral'),
        },
        {
            score: sentiment.negativeScore * 100,
            name: t('textAnalysisDashboard.labels.negative'),
        }
    ];
};

const getRecognizedEntitiesData = (entities) => {
    return {
        locations: entities.filter(e => e.category === 'Location'),
        organizations: entities.filter(e => e.category === 'Organization'),
        people: entities.filter(e => e.category === 'Person'),
        products: entities.filter(e => e.category === 'Product'),
        skills: entities.filter(e => e.category === 'Skill')
    };
};

const getSentimentOverTimeData = (sentimentOverTime) => {
    return {
        fiveMinuteAverages: sentimentOverTime.fiveMinuteAverages
            .map(t => ({
                text: t.text,
                positive: t.positiveScore * 100,
                negative: t.negativeScore * 100,
                time: t.timeInSeconds / 60
            }))
    };
};

const createTranscriptionFilter = (word, sentiment, time) => {
    return {
        text: word ? word.text : null,
        sentiment: sentiment ? sentiment.name : null,
        timeStart: time ? time.start * 60 : null,
        timeEnd: time ? time.end * 60 : null
    };
};


const TextAnalysisDashboard = ({ showLoadingIndicator }) => {
    const { t } = useTranslation();

    const {
        activityId,
        projectId,
        textAnalysisInfo,
        isAVSession
    } = useContext(TextAnalysisContext);

    const wordCloudData = useMemo(() => 
        getWordCloudDataFromKeyPhrases(textAnalysisInfo.keyPhrases.data), [textAnalysisInfo.keyPhrases.data]
    );
    const overallSentimentData = useMemo(() => 
        getOverallSentimentChartData(textAnalysisInfo.overallSentiment.data, t), [textAnalysisInfo.overallSentiment.data, t]
    );
    const recognizedEntitiesData = useMemo(() => 
        getRecognizedEntitiesData(textAnalysisInfo.recognizedEntities.data), [textAnalysisInfo.recognizedEntities.data]
    );
    const sentimentOverTimeData = useMemo(() => 
        getSentimentOverTimeData(textAnalysisInfo.sentimentOverTime.data), [textAnalysisInfo.sentimentOverTime.data]
    );

    const videoContainerRef = useRef(null);

    const [wordFilter, setWordFilter] = useState(null);
    const [sentimentFilter, setSentimentFilter] = useState(null);
    const [timeFilter, setTimeFilter] = useState(null);
    const [showRecordingAtTime, setShowRecordingAtTime] = useState(null);

    const setEntityFilter = (entity) => {
        setWordFilter({ text: entity });
    };

    const transcriptionFilters = createTranscriptionFilter(wordFilter, sentimentFilter, timeFilter);

    const openRecordingAtTime = (time) => {
        setShowRecordingAtTime(time);
        videoContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });  
    };

    const resetFilters = () => {
        setWordFilter(null);
        setSentimentFilter(null);
        setTimeFilter(null);
    };

    return (
        <React.Fragment>
            { !textAnalysisInfo && (showLoadingIndicator ?? true) &&
                <LoadingPlaceholderFullPage>
                    <Typography variant="overline">{t('textAnalysisDashboard.text.loadingData')}</Typography>
                </LoadingPlaceholderFullPage>
            }

            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#f0f4f8'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        maxWidth: '1500px'
                    }}
                >
                    <Alert sx={{ mt: 2, width: '800px' }} severity='info'>
                        { t('textAnalysisDashboard.text.helpTooltip') } - <a href='https://visionslive.zendesk.com/hc/en-gb/articles/12063438226577' target={'_blank'} rel='noreferrer'>{t('textAnalysisDashboard.text.userGuide')}</a>
                    </Alert>
                    <Card variant="outlined" sx={{ margin: '5px' }}>
                        <Wordcloud words={wordCloudData} onWordClick={setWordFilter} activityId={activityId} projectId={projectId} />
                    </Card>
                    <Card variant="outlined" sx={{ margin: '5px' }}>
                        <OverallSentimentChart data={overallSentimentData} onBarClick={setSentimentFilter} />
                    </Card>
                    <Card variant="outlined" sx={{ margin: '5px' }}>
                        <RecognizedEntities data={recognizedEntitiesData} onEntityClick={setEntityFilter} />
                    </Card>
                    <Card variant="outlined" sx={{ margin: '5px' }}>
                        <SentimentAreaChart data={sentimentOverTimeData} onSelectTimePeriod={setTimeFilter} />
                    </Card>
                </Box>
            </Box>
            
            <div ref={videoContainerRef}>
                {
                    showRecordingAtTime &&
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            backgroundColor: '#f0f4f8'
                        }}
                    >
                        <Card variant="outlined" sx={{ margin: '5px' }}>
                            <InlineRecordingViewer activityId={activityId} startTime={showRecordingAtTime} clipDuration={30} />
                        </Card>
                    </Box>
                }
            </div>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    backgroundColor: '#f0f4f8'
                }}
            >
                {
                    isAVSession &&
                    <Card variant="outlined" sx={{ margin: '5px' }}>
                        <VoiceTranscriptionViewer
                            activityId={activityId}
                            filters={transcriptionFilters} 
                            resetFilters={resetFilters} 
                            openRecordingAtTime={openRecordingAtTime} 
                        />
                    </Card>
                }
                {
                    !isAVSession &&
                    <Card variant="outlined" sx={{ margin: '5px' }}>
                        <ChatLogViewer
                            activityId={activityId}
                            filters={transcriptionFilters} 
                            resetFilters={resetFilters} 
                        />
                    </Card>
                }
            </Box>
        </React.Fragment>
    );
};

TextAnalysisDashboard.propTypes = {
    showLoadingIndicator: PropTypes.bool
};

export default TextAnalysisDashboard;
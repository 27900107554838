import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const useStyles = makeStyles(theme => ({
    ...theme.checkboxGroup
}));

const CheckboxGroup = (props) => {
    const selected = props.selected;
    const classes = useStyles();
    const allValues = props.options.map(opt => opt.value);
    const allOptionsAreSelected = selected.length === allValues.length;
    const isSelectAllCheckboxHidden = props.hideSelectAllCheckbox ?? false;

    const handleChange = (event) => {
        const value = event.currentTarget.name;

        if (selected.includes(value)) {
            props.onChange(selected.filter(i => i !== value));
        } else {
            props.onChange([...selected, value]);
        }
    };

    const handleAllChange = () => {
        if (allOptionsAreSelected) {
            props.onChange([]);
        } else {
            props.onChange(allValues);
        }
    };

    const options = props.options.map(opt => (
        <div key={`checkbox-group-option-${opt.value}`}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={selected.includes(opt.value)}
                        onChange={handleChange} name={opt.value}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                    />
                }
                label={opt.label}
            />
        </div>
    ));

    return (
        <React.Fragment>
            <FormControl component="fieldset" className={classes.root}>
                <FormLabel component="legend">{ props.label }</FormLabel>
                <FormGroup>
                    { !isSelectAllCheckboxHidden &&
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    checked={allOptionsAreSelected}
                                    onChange={handleAllChange}
                                    name="all"
                                />
                            }
                            label="All"
                        />
                    }

                    { options }
                </FormGroup>
            </FormControl>
        </React.Fragment>
    );
};

export default CheckboxGroup;

// This is a placeholder for a future login page. Currently just handles authenticating against the vlqrApi and redirects to select company page
import React, { useEffect } from 'react';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { vlqrApiUrl } from 'Config';
import { handleUnavailableError } from 'utils/vlqrApi';
import { useNavigate, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 200,
        backgroundColor: '#486581',
        backgroundImage: 'linear-gradient(to bottom right, #486581, #334E68)',
        color: 'white',
        '& .MuiCircularProgress-svg': {
            color: 'white'
        },
        '& .MuiTypography-root': {
            color: 'white'
        }
    }
}));

const authApi = async (navigate, returnUrl) => {
    await fetch(`${vlqrApiUrl}/auth/isLoggedIn`, {
        credentials: 'include'
    }).then(response => {
        if (!response.ok) {
            let url = encodeURIComponent(window.location.origin);
            if (returnUrl) {
                url = encodeURIComponent(returnUrl);
            }

            window.location.replace(`${vlqrApiUrl}/auth/login?returnUrl=${url}`);
        } else if (returnUrl) {
            window.location.replace(returnUrl);
        } else {
            navigate('/');
        }
    }).catch(response => {
        if (response.message === 'Failed to fetch') {
            // Set that api is unavailable in redux state, and then continue as normal
            handleUnavailableError();
            if (returnUrl) {
                window.location.replace(returnUrl);
            } else {
                navigate('/');
            }
        }
    });
};

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Login = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const query = useQuery();

    const returnUrl = query.get('returnUrl');

    // Authenticate against vlqr api and then redirect to root
    useEffect(() => {
        authApi(navigate, returnUrl);
    }, [navigate, returnUrl]);

    return (
        <React.Fragment>
            <div className={classes.loadingRecordingContainer}>
                <LoadingPlaceholder>
                    <Typography variant="overline">Authenticating</Typography>
                </LoadingPlaceholder>
            </div>
        </React.Fragment>
    );
};

export default Login;

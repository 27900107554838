import React from 'react';
import PropTypes from 'prop-types';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import useVoiceTranscriptionsForActivity from 'api-new/useVoiceTranscriptionsForActivity';
import useParticipantsForActivity from 'api-new/useParticipantsForActivity';
import { ParticipantMessageViewer } from 'pages/TextAnalysisDashboard/ParticipantMessageViewer';
import { useTranslation } from 'react-i18next';

const filterVoiceTranscriptions = (data, filters) => {
    if (filters.sentiment) {
        data = data.filter(d => d.sentiment?.sentiment === filters.sentiment);
    }

    if (filters.user) {
        data = data.filter(d => d.participantId === filters.user);
    }

    if (filters.text) {
        data = data.filter(d => d.text.includes(filters.text));
    }

    if (filters.timeStart) {
        data = data.filter(d => d.recordingTimeInSeconds >= filters.timeStart);
    }

    if (filters.timeEnd) {
        data = data.filter(d => d.recordingTimeInSeconds <= filters.timeEnd);
    }

    return data;
};
 
export const VoiceTranscriptionViewer = ({ activityId, filters, resetFilters, openRecordingAtTime }) => {
    const { t } = useTranslation();

    const { data: voiceTranscriptions, isLoading: isTranscriptionsLoading } = useVoiceTranscriptionsForActivity(activityId);
    const { data: participants, isLoading: isParticipantsLoading } = useParticipantsForActivity(activityId);

    if (isTranscriptionsLoading || isParticipantsLoading) {
        return <LoadingPlaceholder />;
    }

    const filteredTranscripts = filterVoiceTranscriptions(voiceTranscriptions, filters);

    return (
        <ParticipantMessageViewer 
            data={filteredTranscripts} 
            participants={participants} 
            filters={filters} 
            resetFilters={resetFilters} 
            openRecordingAtTime={openRecordingAtTime} 
            title={t('textAnalysisDashboard.labels.voiceTranscriptionsTitle')}
        />
    );
};

VoiceTranscriptionViewer.propTypes = {
    activityId: PropTypes.string,
    /** Filters should be an object containing any number of the following properties: sentiment, user, text, startTime, endTime */
    filters: PropTypes.object,
    resetFilters: PropTypes.func,
    openRecordingAtTime: PropTypes.func
};
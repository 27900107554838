import React from 'react';
import { useTranslation } from 'react-i18next';
import TextAnalysisProvider from './TextAnalysisProvider';
import { Typography, Container, Link, Alert } from '@mui/material';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import useTextAnalysisInfo from 'api-new/useTextAnalysisInfo';
import TopBar from 'components/TopBar/TopBar';
import PageHeader from 'components/shared/PageHeader';
import useBasicActivity from 'api-new/useBasicActivity';
import { styled } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextAnalysisGeneration } from './TextAnalysisGeneration';
import ProductType, { AVSessionUmbrella } from 'enums/ProductType';
import TextAnalysisDashboard from './TextAnalysisDashboard';

const VLActivityLink = styled(Link)(({ theme }) => ({
    color: '#DCEEFB',
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: 4,
    padding: 4,
    paddingRight: 8,
    border: 'thin solid #DCEEFB',
    borderRadius: 4,
    '&:hover': {
        color: 'white',
        borderColor: 'white',
        backgroundColor: '#4098D7'
    },
    transition: 'all .3s'
}));

export const TextAnalysisDashboardPage = () => {
    const { t } = useTranslation();

    const {
        activity_guid: activityId
    } = useParams();

    const { data: selectedActivity, isLoading: activityIsLoading, isError: errorLoadingActivity } = useBasicActivity(activityId);
    const { data: textAnalysisInfo, isLoading, isError: errorLoadingTextAnalysisInfo } = useTextAnalysisInfo(activityId);

    if (isLoading || activityIsLoading) {
        return <LoadingPlaceholder />;
    }

    if (errorLoadingActivity) {
        return (
            <div style={{ backgroundColor: 'white', paddingBottom: 24, display: 'flex', justifyContent: 'center' }}>
                <Alert sx={{ mt: 2, maxWidth: 1200 }} severity="error">{ t('vlqrApi.errors.loadingActivity') }</Alert>
            </div>
        );
    }

    const textAnalysisInfoExists = textAnalysisInfo.overallSentiment && (textAnalysisInfo.overallSentiment.data !== '');
    const isAVSession = AVSessionUmbrella.includes(ProductType[selectedActivity.activityType]);

    return (
        <TextAnalysisProvider
            initialState={{
                activityId: activityId,
                projectId: selectedActivity.projectId,
                textAnalysisInfo: textAnalysisInfo,
                isAVSession: isAVSession
            }}
        >
            <div style={{ backgroundColor: 'white' }}>
                <TopBar
                    companyId={selectedActivity.companyId}
                    projectId={selectedActivity.projectId}
                    activityId={activityId}
                />
                <PageHeader>
                    <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 12 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <VLActivityLink
                                to={`/company/${selectedActivity.companyId}/project/${selectedActivity.projectId}/activity/${activityId}`}
                                component={RouterLink}
                                underline="none"
                            >
                                <ArrowBackIcon />
                                <span>{ t('textAnalysisDashboard.text.backToActivity') }</span>
                            </VLActivityLink>
                            <Typography variant="h1">{selectedActivity?.description ?? selectedActivity?.title} - Text Analysis Dashboard</Typography>
                        </div>
                    </Container>
                </PageHeader>
                {
                    errorLoadingTextAnalysisInfo ?
                        <div style={{display: 'flex', justifyContent: 'center' }}>
                            <Alert sx={{ mt: 2, maxWidth: 1200 }} severity="error">{ t('vlqrApi.errors.loadingTextAnalysisInfo') }</Alert>
                        </div> :
                        textAnalysisInfoExists ? 
                            <TextAnalysisDashboard /> : 
                            <TextAnalysisGeneration activityId={activityId} isAVSession={isAVSession} />
                }
            </div>
        </TextAnalysisProvider>
    );
};

TextAnalysisDashboardPage.propTypes = {};

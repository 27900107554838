import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ComposedChart, Line, Area, XAxis, YAxis, ReferenceArea} from 'recharts';
import ResizeChartContainer from './ResizeChartContainer';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';

const AreaSelectChart = styled('div')(({ theme }) => ({
    '& .recharts-surface': {
        cursor: 'pointer'
    },
    '& .recharts-cartesian-axis': {
        userSelect: 'none'
    }
}));

const LineChartMargins = {
    top: 20,
    right: 15,
    left: 5,
    bottom: 5,
};

const calculateOverallAverageSentiment = (data) => {
    let positiveValue = 0, negativeValue = 0;
    data.forEach((d) => {
        positiveValue += d.positive;
        negativeValue += d.negative;
    });

    if (positiveValue >= negativeValue)
        return 'positive';
    else
        return 'negative';
};

const calculateXAxisTicks = (dataMax) => {
    const tickInterval = dataMax <= 60 ? 5 : 10; 
    const ticks = [];
    let currentTick = 0;

    while (currentTick < dataMax) {
        ticks.push(currentTick);
        currentTick += tickInterval;
    }

    return ticks;
};

const defaultSize = { width: 700, height: 550 };

export const SentimentAreaChart = ({ data, onSelectTimePeriod }) => {
    const { t } = useTranslation();
    const [selectingTime, setSelectingTime] = useState(false);
    const [timeFilterStart, setTimeFilterStart] = useState(null);
    const [timeFilterEnd, setTimeFilterEnd] = useState(null);
    const [chartSize, setChartSize] = useState(defaultSize);

    const setTimeFilter = () => {
        setSelectingTime(false);

        if (timeFilterStart && timeFilterEnd) {
            if (timeFilterStart <= timeFilterEnd) {
                onSelectTimePeriod({ start: timeFilterStart, end: timeFilterEnd });
            }
            else {
                onSelectTimePeriod({ start: timeFilterEnd, end: timeFilterStart });
            }
        }
    };

    const overallSentiment = calculateOverallAverageSentiment(data.fiveMinuteAverages);
    const xAxisTicks = calculateXAxisTicks(data.fiveMinuteAverages[data.fiveMinuteAverages.length - 1].time);

    return (
        <ResizeChartContainer title={t('textAnalysisDashboard.labels.sentimentOverTimeTitle')} chartSize={defaultSize} scalar={1.5} setChartSize={setChartSize}>
            <AreaSelectChart>
                <ComposedChart
                    width={chartSize.width}
                    height={chartSize.height}
                    data={data.fiveMinuteAverages}
                    margin={LineChartMargins}
                    onMouseDown={(e) => { 
                        setSelectingTime(true); 
                        setTimeFilterEnd(null); 
                        setTimeFilterStart(e.activeLabel); 
                    }}
                    onMouseMove={(e) => selectingTime && setTimeFilterEnd(e.activeLabel)}
                    onMouseUp={() => setTimeFilter()}
                >
                    <defs>
                        <linearGradient id="colorPos" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#288C45" stopOpacity={1} />
                            <stop offset="95%" stopColor="#eaf4ec" stopOpacity={1} />
                        </linearGradient>
                        <linearGradient id="colorNeg" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#D34D51" stopOpacity={1} />
                            <stop offset="95%" stopColor="#fbedee" stopOpacity={1} />
                        </linearGradient>
                    </defs>
                    <XAxis type="number" dataKey="time" label={t('textAnalysisDashboard.labels.sentimentOverTimeXAxis')} ticks={xAxisTicks} domain={[0, dataMax => Math.round(dataMax)]} height={60} />
                    <YAxis type="number" />
                    {
                        /* The order of these areas dictates front-to-back ordering when rendered. This chart is generally more aesthetically pleasing when the colours are split equally 
                        and so if the sentiment is generally more positive, the positive section should be rendered at the back and vice-versa with negative */
                        overallSentiment === 'positive' &&
                        <Area type="basis" fillOpacity={1} dataKey="positive" stroke="#288C45" fill="url(#colorPos)" />
                    }
                    <Area type="basis" fillOpacity={1} dataKey="negative" stroke="#D34D51" fill="url(#colorNeg)" />
                    {
                        overallSentiment === 'negative' &&
                        <Area type="basis" fillOpacity={1} dataKey="positive" stroke="#288C45" fill="url(#colorPos)" />
                    }
                    <Line type="basis" dataKey="negative" stroke="#D34D51" dot={false} strokeWidth={2} />
                    <Line type="basis" dataKey="positive" stroke="#288C45" dot={false} strokeWidth={2} />
                    {timeFilterStart && timeFilterEnd ? (
                        <ReferenceArea alwaysShow={true} isFront={true} x1={timeFilterStart} x2={timeFilterEnd} strokeOpacity={0.3} />
                    ) : null}
                </ComposedChart>
            </AreaSelectChart>
        </ResizeChartContainer>
    );
};

SentimentAreaChart.propTypes = {
    data: PropTypes.object,
    onSelectTimePeriod: PropTypes.func
};

export default SentimentAreaChart;

import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { NoProjectsFound } from './EmptyProjectsPlaceholder';
import portalApi, { handleError } from 'utils/portalApi';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import ProjectsTableView from './TableView';
import CreateProjectModal from 'components/CreateProjectModal/CreateProjectModal';
import { Button, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import CheckboxGroup from 'components/shared/CheckboxGroup';
import CompanyRole from 'enums/CompanyRole';
import useCompany from 'api/useCompany';

const loadProjects = (companyId, setAllProjects) => {
    let source = portalApi.CancelToken.source();

    portalApi
        .get(`/api/projects?companyID=${companyId}`, {
            cancelToken: source.token
        })
        .then(response => {
            setAllProjects(response.data);
        })
        .catch(handleError);
};

const ProjectsTab = ({
    onProjectsLoaded,
    style
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const { companyId } = useOutletContext();

    const [allProjects, setAllProjects] = useState(null);
    const [isCreatingProject, setIsCreatingProject] = useState(false);
    const [visibleStatuses, setVisibleStatuses] = useState(['active']); // e.g. Active, Archived
    const allStatuses = ['active', 'archived'];

    const { data: selectedCompany } = useCompany(companyId);
    const companyRole = CompanyRole[selectedCompany.role];

    const byStatus = (activity) => {
        if (activity.isArchived && visibleStatuses.includes('archived')) {
            return true;
        }

        if (activity.isArchived === false && visibleStatuses.includes('active')) {
            return true;
        }

        return false;
    };

    const byCreationDate = (a, b) => {
        if (a.createdDate === b.createdDate) {
            return 0;
        }

        return a.createdDate < b.createdDate ? 1 : -1;
    };

    const getProjects = () => {
        return allProjects.filter(byStatus).sort(byCreationDate);
    };

    const onProjectSelected = useCallback(project => {
        // Redirect to /activities for now until we get a 'recent activities' component
        // in the Project Overview.
        navigate(`project/${project.id}/activities`);
    }, [navigate]);

    const onCreateProjectBtnClick = () => {
        setIsCreatingProject(true);
    };

    const renderProjects = () => {
        if (!allProjects) {
            return (<LoadingPlaceholder height={'auto'} />);
        } else if (getProjects().length === 0) {
            return <NoProjectsFound onCreateProjectButtonClick={onCreateProjectBtnClick} />;
        } else {
            return (
                <ProjectsTableView
                    companyId={companyId}
                    projects={getProjects()}
                    onProjectSelected={onProjectSelected} />
            );
        }
    };

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        loadProjects(companyId, setAllProjects);

        return () => {
            source.cancel('Cancelled in useEffect cleanup');
        };
    }, [companyId, onProjectsLoaded]);

    return (
        <div style={{ display: 'flex', ...style }}>
            <div style={{ flexGrow: 1, padding: 32 }}>
                { renderProjects() }
            </div>

            <div style={{ minWidth: 320, width: 320, padding: 32 }}>
                { companyRole.canCreateProject &&
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<AddIcon />}
                        onClick={onCreateProjectBtnClick}
                        style={{ marginBottom: 16 }}
                    >
                        { t('companyPage.buttons.createNewProject') }
                    </Button>
                }

                <div style={{ padding: 16, background: theme.palette.background.default, borderRadius: 8 }}>
                    <CheckboxGroup
                        hideSelectAllCheckbox
                        label={'Filter by Status'}
                        selected={visibleStatuses}
                        options={allStatuses.map(status => ({ value: status, label: t(`projectStatuses.text.${status}`) }))}
                        onChange={(selected) => setVisibleStatuses(selected)}
                    />
                </div>
            </div>

            <CreateProjectModal
                companyId={companyId}
                open={isCreatingProject}
                onCancel={() => setIsCreatingProject(false)}
                onProjectCreated={() => {
                    setIsCreatingProject(false);
                    loadProjects(companyId, setAllProjects);
                }} />
        </div>
    );
};

ProjectsTab.propTypes = {
    onProjectsLoaded: PropTypes.func,
    style: PropTypes.object
};

export default ProjectsTab;

import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getPollResult = async (pollId) => {
    const { data } = await vlqrApi.get(`Reports/Polls/${pollId}`)
        .catch((error) => {
            handleError(error);
        });
    return data;
};

const usePollResult = (pollId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['pollResult', pollId],
        () => getPollResult(pollId)
    );

    return { data, refetch, isLoading, isError };
};

export default usePollResult;

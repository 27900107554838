import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react';
import { Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import RecordingListIcon from './RecordingListIcon';
import PropTypes from 'prop-types';
import RecordingType from 'enums/RecordingType';

const RecordingListItem = ({ recording }) => {
    const { t } = useTranslation();

    return <ListItem key={recording.id}>
        <ListItemAvatar>
            <Avatar variant='rounded' sx={{ bgcolor: 'primary.main' }}>
                <RecordingListIcon type={recording.type} />
            </Avatar>
        </ListItemAvatar>
        <ListItemText>{t(`reporting.recordings.link.${recording.type?.enumKey}`)}</ListItemText>
        <IconButton href={recording.uri} download target='_blank'>
            <Download color='primary' />
        </IconButton>
    </ListItem>;
};

RecordingListItem.propTypes = {
    recording: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.instanceOf(RecordingType),
        uri: PropTypes.string
    })
};

export default RecordingListItem;

import { Button, ListSubheader, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown, MusicVideo } from '@mui/icons-material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityRecordingContext } from './ActivityRecordingProvider';
import { getRecordingDisplayName } from './util';

const RecordingSelector = () => {
    const { t } = useTranslation();
    const { recordings, selectedRecording, setSelectedRecording } = useContext(ActivityRecordingContext);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    return (
        <React.Fragment>
            <Button
                startIcon={<MusicVideo />}
                endIcon={<ArrowDropDown />}
                onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                sx = {{color: 'primary.contrastText'}}
            >
                { getRecordingDisplayName(selectedRecording, t) }
            </Button>

            <Menu
                open={Boolean(menuAnchorEl)}
                anchorEl={menuAnchorEl}
                onClose={() => setMenuAnchorEl(null)}
            >
                <ListSubheader>{ t('activityRecordingViewer.text.selectRecording') }</ListSubheader>
                {
                    recordings.map((recording, index) => (
                        <MenuItem
                            key={recording.id}
                            onClick={() => {
                                setSelectedRecording(recording);
                                setMenuAnchorEl(null);
                            }}
                            disabled={selectedRecording.id === recording.id}
                        >
                            { getRecordingDisplayName(recording, t) }
                        </MenuItem>
                    ))
                }
            </Menu>
        </React.Fragment>
    );
};

export default RecordingSelector;

import { useQuery } from 'react-query';
import portalApi from 'utils/portalApi';

const getCompanies = async () => {
    const { data } = await portalApi.get('api/companies');
    return data;
};

const useCompanies = () => {
    const { data, refetch, isLoading } = useQuery(
        'allCompanies',
        () => getCompanies()
    );

    return { data, refetch, isLoading };
};

export default useCompanies;

import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FastForward, FastRewind, Pause, PlayArrow, SkipNext, SkipPrevious } from '@mui/icons-material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityRecordingContext } from './ActivityRecordingProvider';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: 4,
        '& .MuiIconButton-root': {
            padding: 8,
            '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.1)'
            },
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        }
    }
}));

/**
 * Provides a simple UI governing basic video navigation controls.
 *
 * - Skip to previous/next Event
 * - Fast-forward/rewind 10 seconds
 * - Play/Pause
 *
 * Must be nested within an ActivityRecordingProvider in order to function.
 */
const BasicVideoControls = ({ showReactionFeatures }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        videoIsPlaying, setVideoIsPlaying,
        videoPlayerRef,
        videoPlaybackProgress,
        skipToPreviousEvent,
        skipToNextEvent
    } = useContext(ActivityRecordingContext);

    return (
        <div className={classes.root}>
            {
                showReactionFeatures &&
                <IconButton onClick={skipToPreviousEvent} size="large">
                    <SkipPrevious />
                </IconButton>
            }

            <Tooltip title={t('activityRecordingViewer.tooltips.backTenSeconds')}>
                <IconButton
                    onClick={() => {
                        videoPlayerRef.current.seekTo(videoPlaybackProgress.playedSeconds - 10);
                    }}
                    size="large">
                    <FastRewind />
                </IconButton>
            </Tooltip>

            { videoIsPlaying ?
                <IconButton onClick={() => setVideoIsPlaying(false)} size="large">
                    <Pause style={{ height: 38, width: 38 }} />
                </IconButton> :
                <IconButton onClick={() => setVideoIsPlaying(true)} size="large">
                    <PlayArrow style={{ height: 38, width: 38 }} />
                </IconButton>
            }

            <Tooltip title={t('activityRecordingViewer.tooltips.forwardTenSeconds')}>
                <IconButton
                    onClick={() => {
                        videoPlayerRef.current.seekTo(videoPlaybackProgress.playedSeconds + 10);
                    }}
                    size="large">
                    <FastForward />
                </IconButton>
            </Tooltip>

            {
                showReactionFeatures &&
                <IconButton onClick={skipToNextEvent} size="large">
                    <SkipNext />
                </IconButton>
            }
        </div>
    );
};

export default BasicVideoControls;

BasicVideoControls.propTypes = {
    showReactionFeatures: PropTypes.bool
};

BasicVideoControls.defaultProps = {
    showReactionFeatures: true
};

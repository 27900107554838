import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import { Paper, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';


import FindInPageIcon from '@mui/icons-material/FindInPage';

import Breadcrumbs from '../shared/Breadcrumbs';
import portalApi, { handleError } from '../../utils/portalApi';
import LoadingPlaceholder from '../shared/LoadingPlaceholder';

import TopBar from '../TopBar/TopBar';
import PageHeader from '../shared/PageHeader';
import { useSearchQueryParam } from 'components/TopBar/GlobalSearchBox';
import ActivitySearchResult from './ActivitySearchResult';
import ProjectSearchResult from './ProjectSearchResult';
import CompanySearchResult from './CompanySearchResult';

const useStyles = makeStyles(theme => ({
    resultList: {
        width: '45em',
        margin: 'auto',
        '& .VL-SearchResult': {
            padding: '12px 16px',
            borderBottom: `2px solid ${theme.dividers.contentDivider.backgroundColor}`,
            '&:last-child': {
                border: 'none'
            },
            '& .MuiBreadcrumbs-root': {
                lineHeight: 'normal'
            }
        }
    }
}));

const runGlobalSearch = (requestBody, setSearchResults) => {
    portalApi
        .post('/api/globalSearch', requestBody)
        .then(response => {
            setSearchResults(response.data);
        })
        .catch(handleError);
};

const SearchResultTypes = {
    COMPANY: 'COMPANY',
    PROJECT: 'PROJECT',
    ACTIVITY: 'ACTIVITY'
};

const getSearchResultType = (searchResult) => {
    if (searchResult.activityId) {
        return SearchResultTypes.ACTIVITY;
    } else if (searchResult.projectId) {
        return SearchResultTypes.PROJECT;
    } else if (searchResult.companyId) {
        return SearchResultTypes.COMPANY;
    }
};

const SearchResults = ({ match }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const searchText = useSearchQueryParam();
    const [searchResults, setSearchResults] = useState(null);

    // Combines Companies/Projects/Activities into single list and sorts by search relevance.
    const getCombinedSearchResults = () => {
        return [
            ...searchResults.companies,
            ...searchResults.projects,
            ...searchResults.activities
        ];
    };

    const renderSearchResult = (searchResult) => {
        switch (getSearchResultType(searchResult)) {
            case SearchResultTypes.ACTIVITY:
                return (
                    <ActivitySearchResult
                        key={`activity-result-${searchResult.activityId}`} 
                        activityType={searchResult.activityType} 
                        activityName={searchResult.activityName} 
                        activityId={searchResult.activityId}
                        companyName={searchResult.companyName}  
                        companyId={searchResult.companyId} 
                        projectName={searchResult.projectName}
                        projectId={searchResult.projectId} 
                    />
                );
                
            case SearchResultTypes.PROJECT:
                return (
                    <ProjectSearchResult 
                        key={`project-result-${searchResult.projectId}`}
                        companyId={searchResult.companyId}
                        companyName={searchResult.companyName}
                        projectId={searchResult.projectId}
                        projectName={searchResult.projectName} 
                    />
                );

            case SearchResultTypes.COMPANY:
                return (
                    <CompanySearchResult 
                        key={`company-result-${searchResult.companyId}`}
                        companyId={searchResult.companyId}
                        companyName={searchResult.companyName} 
                    />
                );
            default: {
                console.warn('Unrecognised type of search result detected');
                return null;
            }
        }
    };

    useEffect(() => {
        let source = portalApi.CancelToken.source();

        setSearchResults(null);
        runGlobalSearch({ searchText: searchText }, setSearchResults);

        return () => {
            source.cancel('runGlobalSearch cancelled in useEffect cleanup');
        };
    }, [setSearchResults, searchText]);

    const loading = () => {
        if (searchResults === null) {
            return <LoadingPlaceholder height={'5em'} />;
        } else {
            return null;
        }
    };

    const results = () => {
        var combinedSearchResults = getCombinedSearchResults();

        if (combinedSearchResults.length === 0) {
            return null;
        }

        return (
            <div style={{ width: '45em', margin: 'auto' }}>
                { searchResults.companies.length > 0 &&
                    <div style={{ marginBottom: 32 }}>
                        <Typography variant="h6" gutterBottom>Companies</Typography>
                        <Paper className={classes.resultList}>
                            { searchResults.companies.map(result => (
                                renderSearchResult(result)
                            )) }
                        </Paper>
                    </div>
                }

                { searchResults.projects.length > 0 &&
                    <div style={{ marginBottom: 32 }}>
                        <Typography variant="h6" gutterBottom>Projects</Typography>
                        <Paper className={classes.resultList}>
                            { searchResults.projects.map(result => (
                                renderSearchResult(result)
                            )) }
                        </Paper>
                    </div>
                }

                { searchResults.activities.length > 0 &&
                    <div style={{ marginBottom: 32 }}>
                        <Typography variant="h6" gutterBottom>Activities</Typography>
                        <Paper className={classes.resultList}>
                            { searchResults.activities.map(result => (
                                renderSearchResult(result)
                            )) }
                        </Paper>
                    </div>
                }
            </div>
        );
    };

    const nothingFound = () => (
        <Paper style={{ padding: 16, width: '20em', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar style={{ ...theme.activityAvatar.neutral, height: 60, width: 60, marginBottom: 16 }}>
                <FindInPageIcon />
            </Avatar>
            <Typography variant="h6">No Search Results Found</Typography>
        </Paper>
    );

    const main = () => (
        <div>
            <TopBar />
            <PageHeader>
                <Container>
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                title: t('searchResults.text.searchResultsForX', { searchText })
                            }
                        ]}
                    />
                </Container>
            </PageHeader>

            <Container style={{ paddingTop: 32 }}>
                { loading() || results() || nothingFound() }
            </Container>
        </div>
    );

    return (
        main()
    );
};

export default SearchResults;

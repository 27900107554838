import PropTypes from 'prop-types';
import React from 'react';
import { LoadingPlaceholderFullPage } from 'components/shared/LoadingPlaceholderFullPage';
import useTextPromptAnswersForActivity from 'api-new/stim-based/useTextPromptAnswersForActivity';
import { TextPromptActivityResponsesIndicator as RCTextPromptActivityResponsesIndicator } from 'vl-reporting-components';

const TextPromptActivityResponsesIndicator = ({ activityId }) => {
    const { data: textPromptAnswers, isLoading: isLoadingTextPromptAnswers } = useTextPromptAnswersForActivity(activityId);

    if (isLoadingTextPromptAnswers) {
        return <LoadingPlaceholderFullPage />;
    }

    return (
        <RCTextPromptActivityResponsesIndicator
            answers={textPromptAnswers}
            sx={{
                height: 35
            }}
        />
    );
};

TextPromptActivityResponsesIndicator.propTypes = {
    activityId: PropTypes.string
};

export default TextPromptActivityResponsesIndicator;

import PropTypes from 'prop-types';
import { Button, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Add from '@mui/icons-material/Add';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AbstractThumbnails from './AbstractHighlightThumbnails.png';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 32,
        color: '#102A43',
        '& .MuiButton-root': {
            backgroundColor: '#102A43',
            color: 'white',
            fontWeight: 400
        },
        '& .MuiTypography-h6': {
            fontWeight: 500
        }
    },
    imgContainer: {
        paddingTop: 16,
        paddingBottom: 16
    },
    actionsContainer: {
        textAlign: 'center'
    }
}));

const NoHighlightsPlaceholder = ({ onCreateHighlightBtnClick }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Typography variant="h6" gutterBottom>
                { t('sessionHighlights.text.noHighlightsFound') }
            </Typography>

            <Typography variant="body1">
                { t('sessionHighlights.text.sessionHighlightsFeatureSummary') }
            </Typography>

            <div className={classes.imgContainer}>
                <img src={AbstractThumbnails} width="900px" height="auto" />
            </div>

            <div className={classes.actionsContainer}>
                <Button size="large" variant="contained" startIcon={<Add />} onClick={onCreateHighlightBtnClick}>
                    { t('sessionHighlights.buttons.createHighlight') }
                </Button>
            </div>
        </Paper>
    );
};

NoHighlightsPlaceholder.propTypes = {
    onCreateHighlightBtnClick: PropTypes.func
};

export default NoHighlightsPlaceholder;

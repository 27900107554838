import { Forum } from '@mui/icons-material';
import React, { useContext } from 'react';
import { ActivityRecordingContext } from './ActivityRecordingProvider';
import SquareIconButton from './SquareIconButton';

const ToggleReactionsButton = () => {
    const {
        reactionsPaneIsOpen, setReactionsPaneIsOpen, noteEvents
    } = useContext(ActivityRecordingContext);

    if (!noteEvents || noteEvents.length === 0) {
        return null;
    }

    if (reactionsPaneIsOpen) {
        return (
            <SquareIconButton
                className={'VL-SquareIconButton-Selected'}
                onClick={() => setReactionsPaneIsOpen(false)}
            >
                <Forum />
            </SquareIconButton>
        );
    } else {
        return (
            <SquareIconButton onClick={() => setReactionsPaneIsOpen(true)}>
                <Forum />
            </SquareIconButton>
        );
    }
};

export default ToggleReactionsButton;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, styled } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';

const ExportButton = styled(Button)(({ theme }) => ({
    ...theme.viewReportButton,
    textDecoration: 'none !important'
}));

const PollActivityResultsExportButton = ({ pollAnswersData, pollOptionsData, participants, activity, hideAnswersWithoutVote }) => {
    const { t } = useTranslation();

    const csvHeaders = [t('projectPage.labels.activityName'), t('pollActivity.csvHeaders.prompt'),
        t('common.timeSubmitted'), t('common.response'), t('common.participant')];

    const pollResponsesForCsv = pollAnswersData.map((answer) => {
        const participant = participants.find((participant) => participant.focusGroupUserId === answer.createdByUserId);
        return [
            activity.title.replace(/"/g, '""'),
            activity.prompt.replace(/"/g, '""'),
            answer.createdDate,
            answer.value.replace(/"/g, '""'),
            participant.name.replace(/"/g, '""'),
        ];
    });
    // find answer options which were not used by participants, so we can add them to the end of the csv
    const unansweredOptions = pollOptionsData.filter((option) =>
        !pollAnswersData.some((answer) => answer.value === option.textValue)
    ).map((option) => [
        activity.title.replace(/"/g, '""'),
        activity.prompt.replace(/"/g, '""'),
        '',
        option.textValue.replace(/"/g, '""'),
        ''
    ]);

    return (
        <CSVLink
            data={ hideAnswersWithoutVote ? pollResponsesForCsv : pollResponsesForCsv.concat(unansweredOptions) }
            headers={csvHeaders}
            filename={activity.title + '.csv'}
            style={{ textDecoration: 'none' }}
        >
            <ExportButton startIcon={<FileDownloadOutlined />}>
                {t('focusGroups.activities.exportToCsv')}
            </ExportButton>
        </CSVLink>
    );
};

PollActivityResultsExportButton.propTypes = {
    pollAnswersData: PropTypes.array.isRequired,
    pollOptionsData: PropTypes.array.isRequired,
    participants: PropTypes.array.isRequired,
    activity: PropTypes.object.isRequired,
    hideAnswersWithoutVote: PropTypes.bool.isRequired
};


export default PollActivityResultsExportButton;
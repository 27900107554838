import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NewProductChip = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    return <Chip
        color='info'
        variant='filled'
        label={t('common.text.new')}
        size='small'
        sx={{
            backgroundColor: theme.palette.success.pastel,
            color: theme.palette.success.dark,
            margin: 1.5,
            fontWeight: 'bold',
            boxShadow: 'rgba(0,0,0,0.5) 1px 2px 6px'
        }}
    />;
};

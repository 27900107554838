import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import ActivityIcon from '../shared/activityIcon/ActivityIcon';
import { useCardStyles } from './UseCardStyles';

const ProductCard = (props) => {
    const { productType, name, description, background, iconColor, onClick } = props;
    const classes = useCardStyles({ color: iconColor });

    return (
        <Card className={classes.card}>
            <CardActionArea
                className={classes.cardActionArea}
                onClick={() => {
                    onClick(props);
                }}>

                <Avatar className={classes.iconAvatar}>
                    <ActivityIcon productType={productType} />
                </Avatar>

                <CardMedia
                    image={background}
                    title={name}
                    className={classes.media} />

                <CardContent style={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" component="h2">
                        {name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        { description }
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default React.memo(ProductCard);

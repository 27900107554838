import React from 'react';

import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// Hooks
import { Breadcrumbs as MuiBreadcrumbs, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';

// When used in page header alongside tabs etc.
const useContainedStyles = makeStyles(theme => ({
    root: {
        padding: 16,
        paddingLeft: 8,
        '& .MuiTypography-root': {
            fontSize: '14px',
            color: 'white'
        }
    }
}));

function Breadcrumbs (props) {
    const theme = useTheme();
    const navigate = useNavigate();

    const classes = useContainedStyles();

    const renderBreadcrumbs = () => {
        if (props.breadcrumbs) {
            return props.breadcrumbs.map(crumb => {
                if (crumb.link) {
                    return (
                        <Link key={`crumb-${crumb.title}`} href="#" onClick={() => navigate(crumb.link)}>
                            <Typography style={theme.typography.breadcrumb} color="inherit">{ crumb.title }</Typography>
                        </Link>
                    );
                } else {
                    return <Typography key={`crumb-${crumb.title}`} style={theme.typography.breadcrumbLeaf}>{ crumb.title }</Typography>;
                }
            });
        }
    };

    return (
        <div className={classes.root}>
            <MuiBreadcrumbs style={{ lineHeight: 1 }}>
                <Link key={'crumb-home'} href="#" onClick={() => navigate('/')}>
                    <Typography style={theme.typography.breadcrumb}>Home</Typography>
                </Link>
                { renderBreadcrumbs() }
            </MuiBreadcrumbs>
        </div>
    );
}

export default Breadcrumbs;

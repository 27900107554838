import React from 'react';
import PropTypes from 'prop-types';
import useActivity from 'api/useActivity';
import PortalIFrame from 'components/shared/PortalIFrame';
import ProductType from 'enums/ProductType';
import { createReportsRelativeURL } from 'utils/activityUrlHelper';
import StimulusBasedFocusGroupReportsPanel from './StimulusBasedFocusGroupReportsPanel';

const FocusGroupReportsPanel = ({ projectId, activityId }) => {
    const { data: activity } = useActivity(activityId);
    if (!activityId || !activity) {
        return null;
    } else if (activity?.content?.isStimulusBasedSession) {
        return <StimulusBasedFocusGroupReportsPanel projectId={projectId} activityId={activityId} />;
    } else {
        return <PortalIFrame
            containerProps={{ disableGutters: true }}
            id={'activityReportsIframe'}
            relativeUrl={createReportsRelativeURL(ProductType[activity?.details?.activityType], projectId, activityId)} />;
    }
};

FocusGroupReportsPanel.propTypes = {
    projectId: PropTypes.string,
    activityId: PropTypes.string
};

export default FocusGroupReportsPanel;

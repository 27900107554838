import { useQuery } from 'react-query';
import portalApi from 'utils/portalApi';

const getRecentCompanies = async () => {
    const { data } = await portalApi.get('api/companies/latestSelections');
    return data;
};

const useRecentCompanies = () => {
    const { data, refetch, isLoading } = useQuery(
        'recentCompanies',
        () => getRecentCompanies()
    );

    return { data, refetch, isLoading };
};

export default useRecentCompanies;

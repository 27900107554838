import React from 'react';
import PropTypes from 'prop-types';
import PortalIFrame from 'components/shared/PortalIFrame';
import { useNavigate, useOutletContext } from 'react-router-dom';

const ProjectIFrame = ({id, relativeUrlStub}) => {
    const { projectId } = useOutletContext();
    const navigate = useNavigate();

    return <PortalIFrame
        key={id}
        id={id}
        relativeUrl={relativeUrlStub.replace(':projectId', projectId)}
        onDashboardRedirect={() => navigate('../activities')}
    />;
};

ProjectIFrame.propTypes = {
    id: PropTypes.string,
    relativeUrlStub: PropTypes.string
};

export const ProjectUsersIframe = () => <ProjectIFrame id='projectUsersIframe' relativeUrlStub={'/xx/:projectId/Respondent'} />;
export const ProjectSettingsIframe = () => <ProjectIFrame id='projectSettingsIframe' relativeUrlStub={'/xx/:projectId/Project/Edit'} />;

import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@mui/material/Avatar';
import ActivityIcon from 'components/shared/activityIcon/ActivityIcon';
import ProductType, { AVSessionUmbrella } from 'enums/ProductType';
import { Badge, Tooltip, useTheme } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { useTranslation } from 'react-i18next';

const StyledBadge = withStyles((theme) => ({
    badge: {
        position: 'absolute',
        top: 10,
        right: 0,
        width: '50%',
        height: '50%',
        borderRadius: '50%',
        padding: '0.8em',
        backgroundColor: theme.palette.background.paper,
        border: `thin solid ${theme.palette.divider}`,
        color: theme.palette.text.secondary
    }
}))(Badge);

const ActivityAvatar = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const productType = ProductType[props.productType];
    const statusStyling = theme.activityAvatar[props.status] ?? { background: 'black', color: 'white', border: 'none' };

    const backgroundColor = props.backgroundColor ?? statusStyling.background;
    const foregroundColor = props.foregroundColor ?? statusStyling.color;

    const showWebcamBadge = AVSessionUmbrella.includes(productType);

    return (
        <Tooltip title={t(`productTypes.text.${props.productType}`)} arrow>
            <StyledBadge badgeContent={<VideocamOutlinedIcon style={{ fontSize: '1.2em' }}/>} invisible={!showWebcamBadge}>
                <Avatar style={{ ...statusStyling, backgroundColor, display: 'inline-flex', width: '42px', height: '42px' }}>
                    <ActivityIcon productType={productType} foregroundColor={foregroundColor} style={{ width: '100%', height: 'auto' }} />
                </Avatar>
            </StyledBadge>
        </Tooltip>
    );
};

ActivityAvatar.propTypes = {
    productType: PropTypes.instanceOf(ProductType),
    status: PropTypes.string,
    backgroundColor: PropTypes.string,
    foregroundColor: PropTypes.string
};

export default ActivityAvatar;

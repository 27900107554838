import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ActivityRecordingProvider, { ActivityRecordingContext } from './ActivityRecordingProvider';
import { Fade, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReactionsPane from './ReactionsPane';
import ActiveEventsView from './ActiveEventsView';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import VideoPlayer from './VideoPlayer';
import VideoControls from './VideoControls';
import HighlightsPane from './SessionHighlights/HighlightsPane';
import useActivityRecordingInfo from './useActivityRecordingInfo';
import RecordingSelector from './RecordingSelector';
import { useParams } from 'react-router-dom';

// TODO: Review once merged into Portal App UI changes, to see if these values can be inferred from
// the theme definition.
export const TOOLBAR_HEIGHT = 80;
export const REACTIONS_PANE_WIDTH = 300;

const useStyles = makeStyles(theme => ({
    loadingRecordingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 200,
        backgroundColor: '#486581',
        backgroundImage: 'linear-gradient(to bottom right, #486581, #334E68)',
        color: 'white',
        '& .MuiCircularProgress-svg': {
            color: 'white'
        },
        '& .MuiTypography-root': {
            color: 'white'
        }
    },
    recordingSelectorContainer: {
        position: 'absolute',
        top: 8,
        left: '50%',
        zIndex: 100,
        backgroundColor: '#486581',
        color: 'white',
        transform: 'translateX(-50%)',
        borderRadius: 4,
        padding: '2px 4px',
        opacity: 0.6,
        '&:hover': {
            opacity: 1
        }
    },
    playerRoot: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxHeight: '100vh',
        overflow: 'hidden',
        backgroundColor: '#DDDDDD'
    },
    playerToolbar: {
        backgroundColor: 'white',
        minHeight: TOOLBAR_HEIGHT,
        height: TOOLBAR_HEIGHT,
        maxHeight: TOOLBAR_HEIGHT
    }
}));

const ActivityRecordingViewer = ({ showLoadingIndicator }) => {
    const classes = useStyles();
    const {
        activityId,
        recordingId,
        videoRecordingUrl,
        setVideoIsPlaying,
        highlightsPaneIsOpen, setHighlightsPaneIsOpen,
        recordings,
        switchRecordingState
    } = useContext(ActivityRecordingContext);

    if (highlightsPaneIsOpen) {
        setVideoIsPlaying(false);
    }

    return (
        <React.Fragment>
            { recordings && (showLoadingIndicator ?? true) &&
                <Fade in={Boolean(switchRecordingState)}>
                    <div className={classes.loadingRecordingContainer}>
                        <LoadingPlaceholder>
                            <Typography variant="overline">Loading Recording</Typography>
                        </LoadingPlaceholder>
                    </div>
                </Fade>
            }

            { recordings.length > 1 &&
                <div className={classes.recordingSelectorContainer}>
                    <RecordingSelector />
                </div>
            }

            <div className={classes.playerRoot}>
                <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', overflow: 'auto' }}>
                    <div style={{ margin: 'auto' }}>
                        <VideoPlayer />
                    </div>

                    <ReactionsPane />
                </div>
                <div className={classes.playerToolbar}>
                    <VideoControls />
                </div>
            </div>

            <ActiveEventsView />

            <HighlightsPane
                activityId={activityId}
                recordingId={recordingId}
                videoRecordingUrl={videoRecordingUrl}
                isOpen={highlightsPaneIsOpen}
                onCloseBtnClick={() => setHighlightsPaneIsOpen(false)}
            />
        </React.Fragment>
    );
};

export const ActivityRecordingViewerPage = () => {
    const {
        activity_guid: activityId
    } = useParams();
    const { data: recordingInfo, isLoading } = useActivityRecordingInfo(activityId);

    if (isLoading) {
        return <LoadingPlaceholder />;
    }

    return (
        <ActivityRecordingProvider
            initialState={{
                activityId: activityId,
                sessionStartDate: recordingInfo.sessionStartDate,
                recordings: recordingInfo.recordings,
                eventLog: recordingInfo.eventLog
            }}
        >
            <ActivityRecordingViewer />
        </ActivityRecordingProvider>
    );
};

ActivityRecordingViewerPage.propTypes = {
    /** Optionally enable/disable loading indicator (defaults to true) */
    showLoadingIndicator: PropTypes.bool
};

export default ActivityRecordingViewer;

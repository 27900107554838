import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getActivities = async (activityId) => {
    const { data } = await vlqrApi.get(`reports/${activityId}/activities`)
        .catch((error) => {
            handleError(error);
        });
    return data;
};

const useActivitiesForFocusGroup = (focusGroupId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['activitiesForFocusGroup', focusGroupId],
        () => getActivities(focusGroupId)
    );

    return { data, refetch, isLoading, isError };
};

export default useActivitiesForFocusGroup;